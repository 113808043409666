import { useState, useEffect } from 'react';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import API from '../../API';

export default function useDriverDetails(match) {
	const history = useHistory();

	const [user, setUser] = useState();
	const [changes, setChanges] = useState({});

	const [saving, setSaving] = useState(false);
	const [resetting, setResetting] = useState(false);
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState('');

	const [timeline, setTimeline] = useState();

	const { id } = match.params;
	useEffect(() => {
		const fetch = async () => {
			try {
				setChanges();

				const result = await API.users.get(id);
				setTimeline(await API.users.history(id, 30));

				setUser({
					...result,
					created_by: await API.users.get(result.created_by),
					removed_by: result.removed_by ? await API.users.get(result.removed_by) : {},
				});
			} catch (e) {
				if (e === 3) history.replace('/404');
				message.error(`${e}`);
				console.trace(e);
			}
		};

		fetch();
	}, [match]);

	const checkChanges = () => {
		const diff = { ...changes };

		Object.entries(diff).forEach(([key, value]) => {
			if (value === user[key]) delete diff[key];
		});

		return Object.keys(diff).length === 0;
	};

	const saveChanges = async () => {
		try {
			setSaving(true);
			await API.users.patch(id, changes);
			setSaving(false);

			message.success('Zapisano zmiany');
			history.replace(`${id}`);
		} catch (e) {
			message.error(`${e}`);
			setSaving(false);
			console.trace(e);
		}
	};

	const resetPassword = async (close) => {
		try {
			setResetting(true);
			const { password } = await API.users.patch(id, { password: null });
			setPassword(password);
			close();
			setResetting(false);
		} catch (e) {
			message.error(`${e}`);
			setResetting(false);
			console.trace(e);
		}
	};

	const deleteUser = async (close) => {
		try {
			setLoading(true);

			await API.users.delete(id);
			close();
			setLoading(false);

			message.success('Usunięto użytkownika');
			history.replace(`${id}`);
		} catch (e) {
			setLoading(false);
			message.error(`${e}`);
			console.trace(e);
		}
	};

	const restoreUser = async () => {
		try {
			setLoading(true);

			await API.users.patch(id, { removed: null });

			setLoading(false);
			message.success('Przywrócono użytkownika');
			history.replace(`${id}`);
		} catch (e) {
			message.error(`${e}`);
			setLoading(false);
			console.trace(e);
		}
	};

	return [
		{
			user,
			removed: user?.removed !== null,
			saving,
			resetting,
			loading,
			password,
			timeline,
			saveDisabled: checkChanges(),
			deleteDisabled: id === sessionStorage.getItem('id'),
		},
		{
			userNameChanged: (e) => setChanges({ ...changes, name: e.target.value }),
			userSurnameChanged: (e) => setChanges({ ...changes, surname: e.target.value }),
			userLevelChanged: (e) => setChanges({ ...changes, level: e.value }),
			typeChanged: (e) => setChanges({ ...changes, type: e.target.value }),
			saveChanges,
			resetPassword,
			deleteUser,
			restoreUser,
		},
	];
}
