import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, Select, Space, Table } from 'antd';
import {
	PlusCircleOutlined,
	SearchOutlined,
	EyeOutlined,
	EyeInvisibleOutlined,
	CloseCircleOutlined,
} from '@ant-design/icons';
import { Page } from '../../components';
import { useDrivers } from '../../reducers';
import { Formatting } from '../../utils';

import '../../styles/pages/drivers/drivers.scss';

const { Option } = Select;

const Drivers = () => {
	const history = useHistory();
	const [
		{ data, visible, filters, loading },
		{ toggleVisible, removeUser, restoreUser, searchChanged, levelChanged, clearFilters },
	] = useDrivers();

	const columns = [
		{
			title: 'Imię i nazwisko',
			dataIndex: 'name',
			key: 'name',
			render: (_, row) => `${row.name} ${row.surname}`,
		},
		{
			title: visible ? 'Usunięty - przez' : 'Stan konta (do rozliczenia)',
			dataIndex: visible ? 'removed' : 'balance',
			key: 'balance',
			render: (v, row) => {
				let removedBy = '-';

				if (v) {
					removedBy = (
						<span>
							{Formatting.epochToDate(v)} -{' '}
							<a href="" onClick={(e) => history.push(`/panel/drivers/${row.removed_by?.id}`)}>
								{row.removed_by?.name}
							</a>
						</span>
					);
				}

				return visible ? removedBy : `${Formatting.money(v)} ${Formatting.money(row.unsettled)}`;
			},
		},
		{
			title: 'Dodany - przez',
			dataIndex: 'created',
			key: 'created',
			render: (v, row) => {
				return (
					<>
						<span>{Formatting.epochToDate(v)} - </span>
						<a href="" onClick={(e) => history.push(`/panel/drivers/${row.created_by.id}`)}>
							{row.created_by.name}
						</a>
					</>
				);
			},
		},
		{
			title: 'Ostatnio zalogowany',
			dataIndex: 'logged',
			key: 'logged',
			render: (v) => (v === null ? '-' : Formatting.epochToDate(v)),
		},
		{
			title: 'Typ',
			dataIndex: 'level',
			key: 'level',
			render: (level) => {
				switch (level) {
					case 0:
						return 'Pracownik';

					case 1:
						return 'Administrator';

					case 2:
						return 'Superadministrator';
				}
			},
		},
		{
			title: 'Akcje',
			key: 'action',
			render: (_, row) => (
				<Space size={5}>
					<a onClick={() => history.push(`/panel/drivers/${row.id}`)}>Szczegóły</a>
					{row.id == sessionStorage.getItem('id') ? '' : <span>|</span>}
					{row.removed_by ? (
						<a
							style={{ color: loading === row.id ? '#8c8c8c' : '#52C41A' }}
							onClick={() => (loading === row.id ? undefined : restoreUser(row.id))}
						>
							Przywróć
						</a>
					) : (
						<a
							style={{ color: loading === row.id ? '#8c8c8c' : '#f5222d' }}
							onClick={() => (loading === row.id ? undefined : removeUser(row.id))}
						>
							{row.id == sessionStorage.getItem('id') ? '' : 'Usuń'}
						</a>
					)}
				</Space>
			),
		},
	];

	const actions = [
		<Button
			key={0}
			type="primary"
			size="large"
			icon={<PlusCircleOutlined />}
			onClick={() => history.push('/panel/new-driver')}
		>
			Dodaj nowego pracownika
		</Button>,
		<Button
			key={1}
			type="primary"
			size="large"
			icon={visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
			onClick={toggleVisible}
		>
			{visible ? 'Ukryj' : 'Pokaż'} usuniętych pracowników
		</Button>,
	];

	return (
		<Page title="Pracownicy" actions={actions}>
			<div className="drivers flex flex-dir flex-1">
				<div className="inputs flex">
					<Input
						placeholder="Wyszukaj"
						size="large"
						suffix={<SearchOutlined style={{ color: '#8C8C8C' }} />}
						value={filters.search}
						onChange={searchChanged}
					/>
					<Select placeholder="Typ konta" size="large" value={filters.level} onSelect={levelChanged}>
						<Option value={0}>Pracownik</Option>
						<Option value={1}>Administrator</Option>
						<Option value={2}>Superadministrator</Option>
					</Select>
					<Button type="primary" size="large" icon={<CloseCircleOutlined />} onClick={clearFilters}>
						Wyczyść
					</Button>
				</div>
				<Table
					columns={columns}
					dataSource={data}
					loading={data === undefined}
					pagination={false}
					scroll={{ x: 'max-content' }}
				/>
			</div>
		</Page>
	);
};

export default Drivers;
