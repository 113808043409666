import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';
import API from '../../../../../API';

export default function () {
	const history = useHistory();

	const [clients, setClients] = useState();
	const [contractors, setContractors] = useState();
	const [loading, setLoading] = useState();
	const [own, setOwn] = useState({});

	const client = useMemo(() => clients?.filter((c) => c.name === own.client)[0], [clients, own.client]);

	const fetch = async () => {
		try {
			setClients(await API.clients.getAll());
			setContractors(await API.orders.owns.contractors.getAll());
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const addOwn = async () => {
		try {
			setLoading(1);

			own.date
				.hour(own.time?.hour ?? 0)
				.minute(own.time?.minute ?? 0)
				.second(0);

			const { address, to, date } = own;
			const o = await API.orders.owns.put({ client_id: client.id, address, to, date: date.unix() * 1000 });

			setLoading();
			message.success('Dodano zamówienie własne');
			history.replace(`/panel/orders/${o.id}/1`);
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const disabled = useMemo(() => {
		if (!client) return true;
		if (!own.address || !own.address.trim()) return true;
		if (!own.to || !own.to.trim()) return true;
		if (!own.date) return true;

		return false;
	}, [client, own.from, own.address, own.to, own.date]);

	return [
		{
			clients,
			contractors,
			own,
			loading,
			disabled,
		},
		{
			clientChanged: (client) => setOwn((own) => ({ ...own, client })),
			addressChanged: (e) => setOwn((own) => ({ ...own, address: e.target.value })),
			toChanged: (to) => setOwn((own) => ({ ...own, to })),
			dateChanged: (date) => setOwn((own) => ({ ...own, date })),
			timeChanged: (time) => setOwn((own) => ({ ...own, time })),
			addOwn,
		},
	];
}
