import React, { useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, DatePicker, Input, Select, Table } from 'antd';
import {
	CloseCircleOutlined,
	CreditCardOutlined,
	DollarCircleOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
	FileDoneOutlined,
	PlusCircleOutlined,
	PrinterOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { cyan, green, grey, purple, red } from '@ant-design/colors';
import { Page } from '../../components';
import { Formatting } from '../../utils';
import { useExpenses } from '../../reducers';
import { ExpensesPrint } from '../../prints';

import '../../styles/pages/expenses/expenses.scss';
import { useReactToPrint } from 'react-to-print';

const { Option } = Select;

const Expenses = ({ match }) => {
	const history = useHistory();

	const [
		{ user, data, filters, visible, advances, admin, loading, month },
		{ searchChanged, carChanged, settledChanged, clearFilters, toggleVisible, toggleAdvances, settle, setMonth },
	] = useExpenses(match);

	const added = visible
		? {
				title: 'Dodane - przez',
				dataIndex: 'created',
				key: 'created',
				render: (v, row) => (
					<span>
						{Formatting.epochToDate(v)} -{' '}
						{row.user_id === null ? (
							'Firmowy'
						) : (
							<Link to={`/panel/drivers/${row.created_by?.id}`}>{row.created_by?.name}</Link>
						)}
					</span>
				),
		  }
		: {
				title: 'Dodane',
				dataIndex: 'created',
				key: 'created',
				render: (v) => Formatting.epochToDate(v),
		  };

	const settled = admin
		? {
				title: 'Rozliczone - przez',
				dataIndex: 'settled',
				key: 'settled',
				render: (settled, row) => {
					if (row.user_id === null) {
						return visible ? '-' : 'Firmowy';
					}

					return settled ? (
						<span>
							{Formatting.epochToDate(settled)} -{' '}
							<Link to={`/panel/drivers/${row.settled_by.id}`}>{row.settled_by.name}</Link>
						</span>
					) : (
						'-'
					);
				},
		  }
		: {
				title: 'Rozliczone',
				dataIndex: 'settled',
				key: 'settled',
				render: (settled, row) => {
					if (row.user_id === null) {
						return visible ? '-' : 'Firmowy';
					}

					return settled ? Formatting.epochToDate(settled) : '-';
				},
		  };

	const getColor = (type) => {
		switch (type) {
			// Expenses
			case 0:
				return red[5];

			// Settlements
			case 1:
				return purple[5];

			// Advances
			case 2:
				return green[5];
		}
	};

	const columns = [
		{
			title: 'Typ',
			dataIndex: 'type',
			key: 'type',
			render: (type, row) => {
				switch (row._type) {
					// Expenses
					case 0:
						return `${type.name} - ${row.name}`;

					// Settlements
					case 1:
						return 'Pobranie gotówki';

					// Advances
					case 2:
						return `Zaliczka - ${row.address}`;

					// Owns
					case 3:
						return `Zamówienie własne - ${row.to}`;
				}
			},
		},

		{
			title: 'Kwota',
			dataIndex: 'value',
			key: 'value',
			render: (v, row) => {
				if (row._type === 3) {
					return <span style={{ color: cyan[5] }}>{Formatting.insertSpaces(row.weight ?? '')} kg</span>;
				}

				return (
					<span style={{ color: getColor(row._type) }}>
						{row._type === 0 ? '-' : ''}
						{Formatting.money(v)}
					</span>
				);
			},
		},

		{
			title: 'Podatek',
			dataIndex: 'value_type',
			key: 'value_type',
			render: (type, row) => {
				if (row._type == 2 || row._type == 3) return '-';

				return (
					<>
						{type ? 'Brutto' : 'Netto'}
						{row.tax ? ` - ${row.tax}%` : ''}
					</>
				);
			},
		},

		{
			title: 'Numer FV',
			dataIndex: 'invoice',
			key: 'invoice',
			render: (invoice) => invoice ?? '-',
		},

		{
			title: 'Nr rejestracyjny',
			dataIndex: 'car',
			key: 'car',
			render: (car) => car ?? '-',
		},

		{
			title: 'Przebieg',
			dataIndex: 'mileage',
			key: 'mileage',
			render: (mileage) => (mileage ? Formatting.insertSpaces(mileage) : '-'),
		},

		added,

		settled,

		{
			title: 'Akcje',
			dataIndex: 'settled',
			key: 'actions',
			render: (settled, row) =>
				settled === null ? (
					<a style={{ color: loading === row.id ? grey[5] : green[5] }} onClick={() => settle(row.id)}>
						Rozlicz
					</a>
				) : (
					'-'
				),
		},
	].filter((c) => visible || c.key !== 'actions');

	const printRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	const actions = [
		<Button
			key={0}
			type="primary"
			size="large"
			icon={<PlusCircleOutlined />}
			onClick={() => history.push('/panel/new-expense')}
		>
			Dodaj wydatek
		</Button>,
		admin && (
			<Button
				key={1}
				type="primary"
				size="large"
				icon={<PlusCircleOutlined />}
				onClick={() => history.push('/panel/new-expense?company=1')}
			>
				Dodaj wydatek firmowy
			</Button>
		),
		<Button
			key={2}
			type="primary"
			size="large"
			icon={advances ? <CreditCardOutlined /> : <FileDoneOutlined />}
			onClick={toggleAdvances}
		>
			{advances ? 'Pokaż tylko wydatki' : 'Pokaż też zaliczki'}
		</Button>,
		admin && (
			<Button
				key={3}
				type="primary"
				size="large"
				icon={visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
				onClick={toggleVisible}
			>
				{visible ? 'Ukryj' : 'Pokaż'} wydatki innych pracowników
			</Button>
		),
		admin && (
			<Button
				key={4}
				type="primary"
				size="large"
				icon={<DollarCircleOutlined />}
				onClick={() => history.push('/panel/expenses/get-money')}
			>
				Pobierz gotówkę od pracownika
			</Button>
		),
		admin && (
			<Button key={5} type="primary" size="large" icon={<PrinterOutlined />} onClick={handlePrint}>
				Wydrukuj listę wydatków
			</Button>
		),
	];

	const bottomTitle = admin ? undefined : 'Saldo:';
	const number = admin ? undefined : Formatting.money(user?.balance);

	return (
		<Page title="Wydatki" actions={actions} bottomTitle={bottomTitle} number={number}>
			<div className="expenses flex flex-dir">
				<div className="inputs flex">
					<Input
						placeholder="Wyszukaj"
						size="large"
						suffix={<SearchOutlined style={{ color: '#8C8C8C' }} />}
						value={filters.search}
						onChange={searchChanged}
					/>
					<Input
						placeholder="Nr rejestracyjny"
						size="large"
						suffix={<SearchOutlined style={{ color: '#8C8C8C' }} />}
						value={filters.car}
						onChange={carChanged}
					/>
					<Select size="large" placeholder="Status" value={filters.settled} onChange={settledChanged}>
						<Option value={0}>Rozliczone</Option>
						<Option value={1}>Nierozliczone</Option>
					</Select>
					<Button type="primary" size="large" icon={<CloseCircleOutlined />} onClick={clearFilters}>
						Wyczyść
					</Button>
					<DatePicker.MonthPicker size="large" value={month} onChange={setMonth} allowClear={false} />
				</div>
				<Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 'max-content' }} />
			</div>
			<ExpensesPrint ref={printRef} expenses={data} month={month} />
		</Page>
	);
};

export default Expenses;
