import { useState } from 'react';
import API from '../../API';

export default function useStats() {
	const [drivers, setDrivers] = useState();
	const [clients, setClients] = useState();
	const [contractors, setContractors] = useState();
	const [summaries, setSummaries] = useState({});
	const [owns, setOwns] = useState({});
	const [orders, setOrders] = useState({});

	const getDrivers = async () => {
		if (drivers) return drivers;

		const d = await API.users.getAll();
		setDrivers(d);
		return d;
	};

	const getClients = async () => {
		if (clients) return clients;

		const c = await API.clients.getAll();
		setClients(c);
		return c;
	};

	const getContractors = async () => {
		if (contractors) return contractors;

		const c = await API.orders.owns.contractors.getAll();
		setContractors(c);
		return c;
	};

	const getSummary = async (from, to) => {
		const cached = summaries[`${from}-${to}`];
		if (cached) return cached;

		const result = await API.stats.summary(from, to);
		setSummaries((summaries) => ({ ...summaries, [`${from}-${to}`]: result }));

		return result;
	};

	const getOwns = async (after, before, client_id, to) => {
		const id = `${after}-${before}-${client_id}-${to}`;
		const cached = owns[id];
		if (cached) return cached;

		const r = await API.orders.owns.getAll({ after, before, client_id, to });
		setOwns((owns) => ({ ...owns, [id]: r }));

		return r;
	};

	const getOrders = async (after, before, client_id) => {
		const id = `${after}-${before}-${client_id}`;
		const cached = orders[id];
		if (cached) return cached;

		const r = await API.orders.getAll({ after, before, client_id });
		setOrders((orders) => ({ ...orders, [id]: r }));

		return r;
	};

	return [
		{ drivers, clients, contractors },
		{ getClients, getContractors, getOwns, getOrders },
	];
}
