import { useEffect, useMemo, useState } from 'react';
import { message } from 'antd';
import API from '../../../../../API';
import moment from 'moment';

export default function useOtherDetails(match) {
	const { id } = match.params;

	const [other, setOther] = useState();
	const [changes, setChanges] = useState({});
	const [loading, setLoading] = useState();

	const fetch = async () => {
		try {
			const other = await API.orders.incomes.get(id);
			other.time = moment(other.date);
			other.date = moment(other.date);

			if (other.date.hours() === 0) other.time = null;

			setOther(other);
			setChanges({});
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const save = async () => {
		try {
			setLoading('saving');

			const data = { ...changes };

			if (changes.date || changes.time !== undefined) {
				let date = moment(other.date).clone();

				if (changes.date) {
					date = changes.date.clone();
					date.hours(moment(other.date).hour());
					date.minutes(moment(other.date).minute());
					date.seconds(0);
				}

				if (changes.time) {
					date.hours(moment(changes.time).hour());
					date.minutes(moment(changes.time).minute());
					date.seconds(0);
				}

				if (changes.time === null) {
					date.hours(0);
					date.minutes(0);
					date.seconds(0);
				}

				data.date = date.unix() * 1000;
				delete data.time;
			}

			await API.orders.incomes.patch(id, data);
			await fetch();

			setLoading();
			message.success('Zapisano zmiany');
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const saveDisabled = useMemo(() => {
		const entries = Object.entries(changes);
		if (!entries.length) return true;

		for (const [k, v] of entries) {
			if (other[k] !== v) return false;
		}

		return true;
	}, [changes]);

	return [
		{ other, changes, saveDisabled, loading },
		{
			weightChanged: (e) => setWeight(e.target.value?.replace(/\,/g, '.')),
			nameChanged: (e) => setChanges((changes) => ({ ...changes, name: e.target.value })),
			dateChanged: (date) => setChanges((changes) => ({ ...changes, date })),
			timeChanged: (time) => setChanges((changes) => ({ ...changes, time })),
			valueChanged: (e) => setChanges((changes) => ({ ...changes, value: e.target.value })),
			valueTypeChanged: (value_type) => setChanges((changes) => ({ ...changes, value_type })),
			invoiceChanged: (e) => setChanges((changes) => ({ ...changes, invoice: e.target.value })),
			save,
		},
	];
}
