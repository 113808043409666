import React, { useContext, useEffect } from 'react';
import { DatePicker, Button, Space, Select, Skeleton } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import StatsContext from '../context';

const { Option } = Select;

const Orders = ({}) => {
	const [{ clients }, { getClients }] = useContext(StatsContext);

	useEffect(() => {
		getClients();
	}, []);

	return clients ? (
		<div className="inputs">
			<Space size={25}>
				<DatePicker placeholder="Data od" size="large" />
				<DatePicker placeholder="Data do" size="large" />
				<Select size="large" placeholder="Klient">
					{clients.map((client) => (
						<Option key={client.id}>{client.name}</Option>
					))}
				</Select>
				<Button type="primary" size="large" icon={<CloseCircleOutlined />}>
					Wyczyść
				</Button>
			</Space>
		</div>
	) : (
		<Skeleton active />
	);
};

export default Orders;
