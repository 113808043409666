import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs } from 'antd';
import { Page } from '../../components';
import { Query } from '../../utils';
import useStats from './useStats';
import StatsContext from './context';
import Summary from './pages/summary';
import Expenses from './pages/expenses';
import Incomes from './pages/incomes';
import Orders from './pages/orders';
import Owns from './pages/owns';

import '../../styles/pages/stats/stats.scss';

const { TabPane } = Tabs;

const Stats = ({ location }) => {
	const history = useHistory();

	const { tab } = Query.decode(location.search);

	return (
		<StatsContext.Provider value={useStats()}>
			<Page title="Statystyki">
				<div className="stats">
					<Tabs activeKey={tab ?? '0'} onChange={(tab) => history.replace(`?tab=${tab}`)}>
						<TabPane tab="Przychody" key="0">
							<Incomes />
						</TabPane>
						{/* <TabPane tab="Wydatki" key="1">
							<Expenses />
						</TabPane>
						<TabPane tab="Przychody" key="2">
							<Incomes />
						</TabPane>
						<TabPane tab="Zamówienia" key="3">
							<Orders />
						</TabPane> */}
						<TabPane tab="Utylizacje" key="4">
							<Owns />
						</TabPane>
					</Tabs>
				</div>
			</Page>
		</StatsContext.Provider>
	);
};

export default Stats;
