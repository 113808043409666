import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, DatePicker, message, Select, Skeleton, Table } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Formatting } from '../../../utils';
import StatsContext from '../context';
import styled from 'styled-components';
import moment from 'moment';

const { Option } = Select;

const Inputs = styled.div`
	> * {
		&:nth-child(1) {
			min-width: 200px;
		}

		&:nth-child(2) {
			min-width: 200px;
		}

		&:nth-child(3) {
			min-width: 200px;
		}

		&:nth-child(4) {
			min-width: 200px;
		}

		&:nth-child(5) {
			min-width: min-content;
		}
	}
`;

const Owns = () => {
	const [{ clients, contractors }, { getClients, getContractors, getOwns }] = useContext(StatsContext);

	const [after, setAfter] = useState(moment().startOf('month'));
	const [before, setBefore] = useState(moment().endOf('month'));
	const [client, setClient] = useState();
	const [to, setTo] = useState();
	const [owns, setOwns] = useState();

	const fetch = async () => {
		try {
			setOwns();

			const owns = await getOwns(after.unix() * 1000, before.unix() * 1000, client, to);
			setOwns(owns.map((o) => ({ ...o, key: o.id })));
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		getClients();
		getContractors();
		fetch();
	}, [after, before, client, to]);

	const clearFilters = () => {
		setAfter(moment().startOf('month'));
		setBefore(moment().endOf('month'));
		setClient();
		setTo();
	};

	const columns = [
		{
			title: 'Klient',
			dataIndex: 'client',
			key: 'client',
			render: (client) => client && <Link to={`/panel/clients/${client.id}`}>{client.name}</Link>,
		},
		{
			title: 'Kontrahent',
			dataIndex: 'to',
			key: 'to',
			render: (to) => to,
		},
		{
			title: 'Adres',
			dataIndex: 'address',
			key: 'address',
		},
		{
			title: 'Waga',
			dataIndex: 'weight',
			key: 'weight',
			render: (weight) => `${Formatting.insertSpaces(weight)} kg`,
		},
		{
			title: 'Data',
			dataIndex: 'date',
			key: 'date',
			render: (date) => Formatting.epochToDate(date, moment(date).hours() === 0),
		},
		{
			title: 'Akcje',
			dataIndex: 'id',
			key: 'id',
			render: (id) => <Link to={`/panel/orders/${id}/1`}>Szczegóły</Link>,
		},
	];

	const summary = useMemo(() => {
		if (!owns) return {};

		return owns.reduce(
			(acc, e) => ({
				weight: acc.weight + e.weight,
			}),
			{ weight: 0 }
		);
	}, [owns]);

	return clients && contractors ? (
		<>
			<Inputs className="inputs">
				<DatePicker placeholder="Data od" size="large" value={after} onChange={setAfter} />
				<DatePicker placeholder="Data do" size="large" value={before} onChange={setBefore} />
				<Select size="large" placeholder="Klient" value={client} onChange={setClient}>
					{clients.map((client) => (
						<Option key={client.id} value={client.id}>
							{client.name}
						</Option>
					))}
				</Select>
				<Select size="large" placeholder="kontrahent" value={to} onChange={setTo}>
					{contractors.map((contractor) => (
						<Option key={contractor} value={contractor}>
							{contractor}
						</Option>
					))}
				</Select>
				<Button type="primary" size="large" icon={<CloseCircleOutlined />} onClick={clearFilters}>
					Wyczyść
				</Button>
			</Inputs>
			<Table columns={columns} dataSource={owns} loading={!owns} pagination={false} scroll={{ x: 'max-content' }} />
			<div>Sumaryczna waga: {Formatting.insertSpaces(summary.weight ?? 0)}kg</div>
		</>
	) : (
		<Skeleton active />
	);
};

export default Owns;
