import React from 'react';
import { DeliveryDetails, OwnDetails, OtherDetails } from './orders/_orders';

import '../../styles/pages/orders/orderDetails.scss';

const OrderDetails = ({ match }) => {
	// TODO(Hexagonale): Load order here and pass it down

	const { type = '0' } = match.params;

	const views = [<DeliveryDetails match={match} />, <OwnDetails match={match} />, <OtherDetails match={match} />];

	return views[type];
};

export default OrderDetails;
