import API from '../API';

export default {
	getStats: async () => {
		const stats = await API.stats.panel();

		if (history?.location?.state?.stats) {
			history.location.state.stats = stats;
		}

		return stats;
	},
};
