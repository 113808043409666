import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Input, Select, Space, Table } from 'antd';
import {
	CloseCircleOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
	PlusCircleOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { Page } from '../../components';
import { useContainers } from '../../reducers';

import '../../styles/pages/containers/containers.scss';

const { Option } = Select;

const Containers = () => {
	const history = useHistory();

	const [
		{ sizes, data, visible, filters, loading, admin },
		{ toggleVisible, clearFilters, searchChanged, sizeChanged, stateChanged, deleteContainer, restoreContainer },
	] = useContainers();

	const getContainerStateName = (state, row) => {
		switch (state) {
			case 0:
				return 'Wolny';
			case 1:
				if (row?.order?.claiming_date && new Date(row.order.claiming_date) < new Date()) {
					if (row.order.related_id > row.order.id) return 'Do wymiany';
					return 'Do odbioru';
				}
				return 'Podstawiony';
			case 4:
				return 'W transporcie';
			case 5:
				return 'Wypożyczony';
			case 6:
				return 'Usunięty';
			default:
				return 'Nieznany';
		}
	};

	const columns = [
		{
			title: 'Numer',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Rozmiar',
			dataIndex: 'size',
			key: 'size',
			render: (s) => s.name,
		},
		{
			title: 'Typ',
			dataIndex: 'type',
			key: 'type',
			render: (type) => type ?? '-',
		},
		{
			title: 'Stan',
			dataIndex: 'state',
			key: 'state',
			render: (state, row) => getContainerStateName(state.state, row),
		},
		{
			title: 'Klient',
			dataIndex: 'order',
			key: 'client',
			render: (order) =>
				order?.client?.name ? <Link to={`/panel/clients/${order.client.id}`}>{order.client.name}</Link> : '-',
		},
		{
			title: !visible && 'Aktualna lokalizacja',
			dataIndex: !visible && 'order',
			key: 'location',
			render: (order) => !visible && (order?.street ? <Link to={`/panel/orders/${order}`}>{order.address}</Link> : '-'),
		},
		{
			title: 'Akcje',
			key: 'action',
			render: (_, row) => (
				<Space size={5}>
					<Link to={`/panel/containers/${row.id}`}>Szczegóły</Link>
					<span>|</span>
					{row.state === 6 ? (
						<a
							style={{ color: loading === row.id ? '#8c8c8c' : '#52C41A' }}
							onClick={() => (loading === row.id ? undefined : restoreContainer(row.id))}
						>
							Przywróć
						</a>
					) : (
						<a
							style={{ color: loading === row.id ? '#8c8c8c' : '#f5222d' }}
							onClick={() => (loading === row.id ? undefined : deleteContainer(row.id))}
						>
							Usuń
						</a>
					)}
				</Space>
			),
		},
	].filter((c) => admin || c.key !== 'action');

	const actions = [
		admin && (
			<Button
				key={0}
				type="primary"
				size="large"
				icon={<PlusCircleOutlined />}
				onClick={() => history.push('/panel/new-container')}
			>
				Dodaj nowy kontener
			</Button>
		),
		<Button
			key={1}
			type="primary"
			size="large"
			icon={visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
			onClick={toggleVisible}
		>
			{visible ? 'Ukryj' : 'Pokaż'} usunięte kontenery
		</Button>,
	];

	return (
		<Page title="Kontenery" actions={actions} number={data?.length}>
			<div className="containers flex flex-dir flex-1">
				<div className="inputs flex">
					<Input
						placeholder="Wyszukaj"
						size="large"
						suffix={<SearchOutlined style={{ color: '#8C8C8C' }} />}
						value={filters.search}
						onChange={searchChanged}
					/>
					<Select placeholder="Rozmiar" size="large" value={filters.size} onChange={sizeChanged}>
						{sizes?.map(({ id, name }) => (
							<Option key={id} value={id}>
								{name}
							</Option>
						))}
					</Select>
					<Select placeholder="Stan kontenera" size="large" value={filters.state} onSelect={stateChanged}>
						{Array(7)
							.fill(0)
							.map((e, i) => (
								<Option key={i} value={i}>
									{getContainerStateName(i)}
								</Option>
							))}
					</Select>
					<Button type="primary" size="large" icon={<CloseCircleOutlined />} onClick={clearFilters}>
						Wyczyść
					</Button>
				</div>

				<Table columns={columns} dataSource={data} pagination={false} scroll={{ x: 'max-content' }} />
			</div>
		</Page>
	);
};

export default Containers;
