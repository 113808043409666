import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Container from './components/container';
import PasswordChange from './pages/passwordChange';
import NotFound from './pages/notFound';
import Login from './pages/login';
import Panel from './pages/panel';
import 'babel-polyfill';

import 'antd/dist/antd.css';
import './styles/app.scss';

const App = () => {
	return (
		<Container className="container flex flex-jc flex-ac">
			<React.Suspense fallback={<h1>Ładowanie...</h1>}>
				<Router>
					<Switch>
						<Route exact path="/" component={Login} />
						<Route exact path="/passchange" component={PasswordChange} />
						<Route exact path="/logout" exact component={Login} />
						{/* <Route path="/panel" component={React.lazy(() => import('./pages/panel'))} /> */}
						<Route path="/panel" component={Panel} />
						<Route component={NotFound} />
					</Switch>
				</Router>
			</React.Suspense>
		</Container>
	);
};

export default App;
