import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { Query } from '../../utils';
import API from '../../API';
import moment from 'moment';

const useTasks = () => {
	const history = useHistory();

	const [offset, setOffset] = useState(parseInt(Query.decode(history.location.search)?.offset ?? '0') ?? 0);
	const [tasks, setTasks] = useState();

	const fetch = async () => {
		try {
			const start = moment().add(offset, 'days').startOf('day').unix() * 1000;
			const end = moment().add(offset, 'days').endOf('day').unix() * 1000;

			const orders = await API.orders.getAll({ after: start, before: end });
			const owns = await API.orders.owns.getAll({ after: start, before: end });

			const tasks = [];

			for (const order of orders) {
				// Split order into two tasks (delivery and claiming)

				// If it's replacement
				if (order.related_id) {
					// Old to replace task
					if (order.related_id > order.id) {
						// Check if it also was a replacement
						if (order._replacement) {
							// It was a replacement.

							tasks.push({
								id: `${order.id}-1`,
								type: 1,
								date: order.delivery_date,
								taken: order.delivery_taken,
								taken_by: order.delivery_by,
								done: order.delivery_done,
								order,
							});
						} else {
							// It was a delivery.

							tasks.push({
								id: `${order.id}-0`,
								type: 0,
								date: order.delivery_date,
								taken: order.delivery_taken,
								taken_by: order.delivery_by,
								done: order.delivery_done,
								order,
							});
						}

						// Add it's claiming (which is replacement itself)
						const existing = tasks.filter((t) => t.order?.id === order.related_id)[0];

						if (!existing) tasks.push({ old: order });
						else existing.old = order;
					}

					// New replacement task
					if (order.related_id < order.id) {
						// Add it's delivery (which is replacement itself)
						const existing = tasks.filter((t) => t.old?.id === order.related_id)[0];
						if (!existing) {
							tasks.push({
								id: `${order.id}-1`,
								type: 1,
								date: order.delivery_date,
								taken: order.delivery_taken,
								taken_by: order.delivery_by,
								done: order.delivery_done,
								order,
							});
						} else {
							existing.id = `${order.id}-1`;
							existing.type = 1;
							existing.date = order.delivery_date;
							existing.taken = order.delivery_taken;
							existing.taken_by = order.delivery_by;
							existing.done = order.delivery_done;
							existing.order = order;
						}

						// If it's delivered add it's claiming
						if (order.delivery_done && order.claiming_date) {
							tasks.push({
								id: `${order.id}-2`,
								type: 2,
								date: order.claiming_date,
								taken: order.claiming_taken,
								taken_by: order.claiming_by,
								done: order.claiming_done,
								order,
							});
						}
					}
				} else {
					// Add delivery
					tasks.push({
						id: `${order.id}-0`,
						type: 0,
						date: order.delivery_date,
						taken: order.delivery_taken,
						taken_by: order.delivery_by,
						done: order.delivery_done,
						order,
					});

					// If delivered and has claiming date (and not replacement) add claiming
					if (order.delivery_done && order.claiming_date) {
						tasks.push({
							id: `${order.id}-2`,
							type: 2,
							date: order.claiming_date,
							taken: order.claiming_taken,
							taken_by: order.claiming_by,
							done: order.claiming_done,
							order,
						});
					}
				}
			}

			for (const own of owns) {
				tasks.push({
					id: `${own.id}-4`,
					type: 4,
					date: own.date,
					done: own.closed,
					own,
				});
			}

			const getTaskSort = (t) => {
				if (t.done) return 10;

				if (moment(t.date).hours() !== 0) {
					if (t.type === 1) return 1;
					if (t.type === 0) return 2;
					if (t.type === 3) return 3;
				}

				if (t.type === 1) return 4;
				if (t.type === 0) return 5;
				if (t.type === 3) return 6;
			};

			setTasks(
				tasks
					.filter((t) => new Date(t.date).getTime() >= start && new Date(t.date).getTime() < end)
					.map((e) => ({ ...e, sort: getTaskSort(e) }))
					.sort((a, b) => a.sort - b.sort)
			);
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		const interval = setInterval(fetch, 1000 * 30);
		fetch();

		history.replace(`?offset=${offset}`);

		return () => clearInterval(interval);
	}, [offset]);

	return [{ tasks, offset }, { setOffset }];
};

export default useTasks;
