import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import API from '../../API';

export default function () {
	const history = useHistory();

	const [user, setUser] = useState({ level: 0, type: 'Kierowca' });
	const [loading, setLoading] = useState(false);
	const disabled = !user.username || !user.name || !user.surname || !user.type;

	const generatePassword = () => {
		const array = new Uint32Array(8);

		let password = '';

		do {
			window.crypto.getRandomValues(array);
			password += btoa(array).replace(/[\=\-\+\/]/g, '');
		} while (password.length < 10);

		return password.substr(0, 10);
	};

	useEffect(() => {
		if (history.location.pathname !== '/panel/new-driver') return;

		setUser({ ...user, password: generatePassword() });
	}, [history.location.pathname]);

	const createUser = async () => {
		if (disabled) return setError('Podaj wszystkie dane');

		try {
			setLoading(true);

			const u = await API.users.create(user);

			setLoading(false);
			message.success('Stworzono użytkownika');
			history.replace(`drivers/${u.id}`);
		} catch (e) {
			setLoading(false);
			message.error(`${e}`);

			console.trace(e);
		}
	};

	return [
		{
			user,
			disabled,
			loading,
		},
		{
			usernameChanged: (e) => setUser({ ...user, username: e.target.value }),
			nameChanged: (e) => setUser({ ...user, name: e.target.value }),
			typeChanged: (e) => setUser({ ...user, type: e.target.value }),
			surnameChanged: (e) => setUser({ ...user, surname: e.target.value }),
			adminChecked: (e) => setUser({ ...user, level: e.target.checked ? 1 : 0 }),
			superadminChecked: (e) => setUser({ ...user, level: e.target.checked ? 2 : 1 }),
			createUser,
		},
	];
}
