import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AutoComplete, Button, Input, Select, Skeleton, Space } from 'antd';
import {
	AuditOutlined,
	CheckCircleOutlined,
	DeleteOutlined,
	EditOutlined,
	InfoCircleOutlined,
	RedoOutlined,
} from '@ant-design/icons';
import { DateModal, Page, PageSection, WarningModal } from '../../../../components';
import { Formatting } from '../../../../utils';
import ClientInfo from './components/client_info';
import useDeliveryDetails from './reducers/use_delivery_details';
import moment from 'moment';

import './styles/delivery_details.scss';

const DeliveryDetails = ({ match }) => {
	const history = useHistory();

	const [
		{
			order,
			sizes,
			loading,
			dateModal,
			warningModal,
			price,
			priceType,
			paymentType,
			wasteType,
			address,
			sizeId,
			level,
		},
		{
			priceChanged,
			invoiceChanged,
			priceTypeChanged,
			paymentTypeChanged,
			changePrice,
			changeInvoice,
			changePaymentType,
			setDateModal,
			changeDeliveryDate,
			changeClaimingDate,
			setWarningModal,
			deleteOrder,
			typeChanged,
			changeType,
			addressChanged,
			changeAddress,
			setAsPaid,
			setSizeId,
			changeSize,
		},
	] = useDeliveryDetails(match);

	const getStateName = (state) => {
		if (order.claiming_done) return 'Zakończono';

		if (!state) return;

		switch (state) {
			case 1:
				if (order.claiming_date && new Date(order.claiming_date) < new Date()) {
					if (order.related_id > order.id) return 'Do wymiany';
					return 'Do odbioru';
				}
				return 'Podstawiony';

			case 4:
				return 'W transporcie';

			default:
				return 'Nieznany';
		}
	};

	const type = useMemo(() => {
		if (!order) return;

		if (order.related_id) {
			if (order.related_id > order.id) return 1;
			if (order.related_id < order.id) {
				if (new Date(order.claiming_date) < new Date()) return 2;
			}
		}
		if (order.claiming_done) return;
		if (order.claiming_taken) return 2;
		if (order.delivery_done) {
			if (new Date(order.claiming_date) < new Date()) return 2;
			return;
		}
		if (order.delivery_taken) return 0;

		return 0;
	}, [order]);

	const actions = [
		order && !order.delivery_done && level >= 2 && (
			<Button key={0} size="large" type="danger" icon={<DeleteOutlined />} onClick={() => setWarningModal(1)}>
				Usuń zamówienie
			</Button>
		),
	];

	const wasteTypes = ['Mieszany', 'Gruz', 'Drewno', 'Styropian', 'Papa'];

	const taskDetails = useMemo(() => {
		if (!order) return '';

		if (type === 1 && order.related_id > order.id) {
			return `/panel/tasks/${order.related_id}/${type}`;
		}

		return `/panel/tasks/${order.id}/${type}`;
	}, [order, type]);

	return (
		<Page title="Szczegóły zamówienia" actions={actions}>
			{order && sizes ? (
				<PageSection title="Klient">
					<div className="delivery-details">
						<ClientInfo client={order.client} name />

						<PageSection title="Informacje">
							<Space size={25}>
								<div className="flex flex-dir">
									<label>BDO</label>
									<span>{order.bdo ? 'Wytwarzający' : 'BDO'}</span>
								</div>

								<div className="flex flex-dir">
									<label>Rodzaj odpadów</label>
									{level > 0 && !order.claiming_done ? (
										<div className="flex waste-type">
											<AutoComplete
												size="large"
												options={wasteTypes.map((type) => ({ value: type }))}
												filterOption={(inputValue, option) =>
													option.value.toUpperCase().includes(inputValue.toUpperCase())
												}
												value={wasteType ?? order.waste_type}
												onSelect={typeChanged}
												onChange={typeChanged}
											/>
											<Button
												type="primary"
												size="large"
												icon={<CheckCircleOutlined />}
												onClick={changeType}
												loading={loading === 'type'}
												disabled={!wasteType || wasteType === order.waste_type}
											>
												Zmień
											</Button>
										</div>
									) : (
										<span>{order.waste_type}</span>
									)}
								</div>
							</Space>

							<Space size={25}>
								<div className="flex flex-dir">
									<label>Adres</label>
									{level > 0 && !order.claiming_done ? (
										<div className="flex address">
											<Input size="large" value={address ?? order.address} onChange={addressChanged} />
											<Button
												type="primary"
												size="large"
												icon={<CheckCircleOutlined />}
												onClick={changeAddress}
												loading={loading === 'address'}
												disabled={!address || address === order.address}
											>
												Zmień
											</Button>
										</div>
									) : (
										<span>{order.address}</span>
									)}
								</div>
							</Space>

							<Space size={25}>
								<div className="flex flex-dir">
									<label>Numer kontaktowy</label>
									<span>{order.contact ?? '-'}</span>
								</div>
							</Space>

							<Space size={25}>
								<div className="flex flex-dir">
									<label>Uwagi</label>
									<span>{order.comments ?? '-'}</span>
								</div>
							</Space>
						</PageSection>

						<PageSection title="Zapotrzebowanie">
							<Space size={25}>
								<div className="flex flex-dir">
									<label>Termin podstawienia</label>
									<span className="flex date">
										{Formatting.epochToDate(order.delivery_date, moment(order.delivery_date).hours() === 0)}
										{!order.delivery_taken && (
											<Button
												type="primary"
												size="large"
												icon={<EditOutlined />}
												onClick={() =>
													setDateModal({
														callback: changeDeliveryDate,
														deadline: 1,
													})
												}
											>
												Zmień
											</Button>
										)}
									</span>
								</div>
								<div className="flex flex-dir">
									<label>Termin odbioru</label>
									<span className="flex date">
										{order.on_place ? (
											'-'
										) : (
											<>
												{order.claiming_date === null
													? 'Bez daty odbioru'
													: Formatting.epochToDate(order.claiming_date, moment(order.claiming_date).hours() === 0)}
												{!order.claiming_taken && (
													<Button
														type="primary"
														size="large"
														icon={<EditOutlined />}
														onClick={() =>
															setDateModal({
																callback: changeClaimingDate,
																deadline: order.related_id && order.related_id > order.id,
															})
														}
													>
														Zmień
													</Button>
												)}
											</>
										)}
									</span>
								</div>
							</Space>

							<Space size={25}>
								<div className="flex flex-dir">
									<label>Rozmiar</label>
									{sizeId ? (
										<Space size={25}>
											<Select size="large" value={sizeId} onChange={setSizeId}>
												{sizes.map((size) => (
													<Select.Option key={size.id} value={size.id}>
														{size.name}
													</Select.Option>
												))}
											</Select>
											<Button
												type="primary"
												size="large"
												icon={<CheckCircleOutlined />}
												onClick={changeSize}
												loading={loading === 'sizeId'}
											>
												Zapisz
											</Button>
										</Space>
									) : (
										<span>
											{order.size.name}
											{!order.delivery_taken && (
												<Button
													type="primary"
													size="large"
													icon={<EditOutlined />}
													onClick={() => setSizeId(order.size.id)}
												>
													Zmień
												</Button>
											)}
										</span>
									)}
								</div>
								<div className="flex flex-dir">
									<label>Załadunek na miejscu</label>
									<span>{order.on_place ? 'Tak' : 'Nie'}</span>
								</div>
							</Space>

							<Space size={25}>
								<div className="flex flex-dir">
									<label>Przydzielony kontener</label>
									<span>{order.container?.name ?? 'Brak'}</span>
								</div>
								<div className="flex flex-dir">
									<label>Stan</label>
									<span>{getStateName(order.container?.state?.state) ?? 'Do podstawienia'}</span>
								</div>
							</Space>

							<Space size={25}>
								{!isNaN(type) && (
									<div className="flex flex-dir">
										<div>
											<Button
												type="primary"
												size="large"
												icon={<InfoCircleOutlined />}
												onClick={() => history.push(taskDetails)}
											>
												Szczegóły zadania
											</Button>
										</div>
									</div>
								)}
								<div className="flex flex-dir">
									{order.delivery_done && !order.claiming_taken && (order.related_id ?? -1) < order.id && (
										<div>
											<Button
												type="primary"
												size="large"
												icon={<RedoOutlined />}
												onClick={() => history.push(`/panel/new-order?type=0&related_id=${order.id}`)}
											>
												Oznacz do wymiany
											</Button>
										</div>
									)}
								</div>
							</Space>
						</PageSection>

						{order.delivery_taken && (
							<PageSection title="Historia">
								<Space size={25}>
									<div className="flex flex-dir">
										<label>Zgłoszone do podstawienia</label>
										<span>{Formatting.epochToDate(order.delivery_taken)}</span>
									</div>
									<div className="flex flex-dir">
										<label>Zgłoszone do podstawienia przez</label>
										<span>
											{order.delivery_by.name} {order.delivery_by.surname}
										</span>
									</div>
								</Space>

								{order.delivery_done && (
									<Space size={25}>
										<div className="flex flex-dir">
											<label>Podstawione</label>
											<span>{Formatting.epochToDate(order.delivery_done)}</span>
										</div>
										{order.sign && (
											<div className="flex flex-dir flex-ac">
												<label>&nbsp;</label>
												<Button
													type="primary"
													size="large"
													icon={<EditOutlined />}
													onClick={() => history.push(`/panel/orders/${order.id}/sign`, { sign: order.sign })}
												>
													Zobacz podpis WZ
												</Button>
											</div>
										)}
									</Space>
								)}

								{order.claiming_taken && (
									<Space size={25}>
										<div className="flex flex-dir">
											<label>Zgłoszone do odebrania</label>
											<span>{Formatting.epochToDate(order.claiming_taken)}</span>
										</div>
										<div className="flex flex-dir">
											<label>Zgłoszone do odebrania przez</label>
											<span>
												{order.claiming_by.name} {order.claiming_by.surname}
											</span>
										</div>
									</Space>
								)}

								{order.claiming_done && (
									<Space size={25}>
										<div className="flex flex-dir">
											<label>Odebrane</label>
											<span>{Formatting.epochToDate(order.claiming_done)}</span>
										</div>
									</Space>
								)}
							</PageSection>
						)}

						{level > 1 && (
							<PageSection title="Finanse">
								<Space size={25}>
									<div>
										<label>
											Cena (<span className="price-type">{order.price_type ? 'Brutto' : 'Netto'}</span>)
										</label>
										<div className="flex">
											<Input size="large" type="text" value={price ?? order.price} onChange={priceChanged} />
											<span className="pln flex flex-dir flex-jc flex-ac">PLN</span>
										</div>
									</div>
									<div className="flex flex-dir flex-ac">
										<label>&nbsp;</label>
										<div className="price-edit">
											<Select size="large" value={priceType ?? order.price_type} onChange={priceTypeChanged}>
												<Select.Option key="0" value={0}>
													Netto
												</Select.Option>
												<Select.Option key="1" value={1}>
													Brutto
												</Select.Option>
											</Select>
											<Button
												type="primary"
												size="large"
												icon={<CheckCircleOutlined />}
												onClick={changePrice}
												disabled={
													(!`${(price ?? '').trim()}` || price == order.price) &&
													(isNaN(priceType) || priceType === order.price_type)
												}
												loading={loading === 'price'}
											>
												Zmień cenę
											</Button>
										</div>
									</div>
								</Space>

								{order.payment_type === 1 && (
									<Space size={25}>
										<div className="flex flex-dir">
											<label>Zaliczka</label>
											<span>{order.advance ? Formatting.money(order.advance) : '-'}</span>
										</div>
										<div className="flex flex-dir">
											<label>Typ zaliczki</label>
											<span>{order.advance_type ?? '-'}</span>
										</div>
									</Space>
								)}

								<Space size={25}>
									<div className="flex flex-dir">
										<label>Typ płatności</label>
										<div className="flex payment-type">
											<Select size="large" value={paymentType ?? order.payment_type} onChange={paymentTypeChanged}>
												<Select.Option key="0" value={0}>
													Przelew
												</Select.Option>
												<Select.Option key="1" value={1}>
													Gotówka
												</Select.Option>
											</Select>
											<Button
												type="primary"
												size="large"
												icon={<CheckCircleOutlined />}
												onClick={changePaymentType}
												disabled={isNaN(paymentType)}
												loading={loading === 'payment_type'}
											>
												Zmień
											</Button>
										</div>
									</div>
									<div className="flex flex-dir">
										{order.payment ? (
											<>
												<label>Zapłacone</label>
												<span>{Formatting.epochToDate(order.payment)}</span>
											</>
										) : (
											<>
												<label>&nbsp;</label>
												<div className="flex flex-ac">
													<Button
														type="primary"
														size="large"
														icon={<CheckCircleOutlined />}
														onClick={setAsPaid}
														loading={loading === 'paid'}
													>
														Oznacz jako zapłacone
													</Button>
												</div>
											</>
										)}
									</div>
								</Space>

								<Space size={25}>
									<div className="flex flex-dir">
										<label>Faktura</label>
										<Input size="large" type="text" value={order.invoice} onChange={invoiceChanged} />
									</div>
									<div className="flex flex-dir flex-ac">
										<label>&nbsp;</label>
										<div>
											<Button
												type="primary"
												size="large"
												icon={<AuditOutlined />}
												onClick={changeInvoice}
												loading={loading === 'invoice'}
											>
												Wystaw fakturę
											</Button>
										</div>
									</div>
								</Space>
							</PageSection>
						)}
					</div>
					<DateModal
						visible={dateModal}
						onCancel={() => setDateModal()}
						onOk={dateModal?.callback}
						loading={loading === 'date'}
						deadline={dateModal?.deadline}
					/>
					<WarningModal
						visible={warningModal}
						onCancel={() => setWarningModal()}
						onOk={() => deleteOrder()}
						loading={loading === 'delete'}
					>
						<h2>Czy na pewno chcesz usunąć to zamówienie?</h2>
					</WarningModal>
				</PageSection>
			) : (
				<Skeleton active />
			)}
		</Page>
	);
};

export default DeliveryDetails;
