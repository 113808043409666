import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Query } from '../../utils';
import { message } from 'antd';
import API from '../../API';

export default function useContainers() {
	const history = useHistory();

	const [data, setData] = useState();
	const [sizes, setSizes] = useState();
	const [filters, setFilters] = useState({});
	const [loading, setLoading] = useState();

	const visible = Query.decode(history.location.search).visible === '1';
	const admin = window.sessionStorage.getItem('level') > 0;

	const fetch = async () => {
		try {
			const sizes = await API.containers.sizes.getAll();
			setSizes(sizes);

			const data = (await API.containers.getAll(1))
				.map((container, i) => ({
					...container,
					key: i,
					size: sizes.filter((e) => e.id === container.size)[0],
				}))
				.sort((a, b) => new Date(b.changed) - new Date(a.changed));

			setData(data);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const deleteContainer = async (id) => {
		try {
			setLoading(id);

			await API.containers.delete(id);
			await fetch();

			setLoading();
			message.success('Usunięto kontener');
		} catch (e) {
			message.error(`${e}`);
			setLoading();
			console.trace(e);
		}
	};

	const restoreContainer = async (id) => {
		try {
			setLoading(id);

			await API.containers.patch(id, { state: 0 });
			await fetch();

			setLoading();
			message.success('Przywrócono kontener');
		} catch (e) {
			message.error(`${e}`);
			setLoading();
			console.trace(e);
		}
	};

	const filtered = useMemo(() => {
		if (!data) return;

		return data.filter((c) => {
			if (!visible && c.state.state === 6) return false;

			if (filters.search) {
				if (!`${c.name}`.toLowerCase().includes(filters.search.toLowerCase())) {
					if (!`${c.type}`.toLowerCase().includes(filters.search.toLowerCase())) {
						if (!`${c.order?.client?.name}`.toLowerCase().includes(filters.search.toLowerCase())) {
							return false;
						}
					}
				}
			}

			if (filters.size != undefined && c.size.id !== filters.size) return false;
			if (filters.state != undefined && c.state.state !== filters.state) return false;

			return true;
		});
	}, [data, filters, visible]);

	return [
		{
			sizes,
			data: filtered,
			visible,
			filters,
			loading,
			admin,
		},
		{
			toggleVisible: () => history.replace(`?visible=${visible ? 0 : 1}`),
			deleteContainer,
			restoreContainer,
			searchChanged: (e) => setFilters({ ...filters, search: e.target.value }),
			sizeChanged: (v) => setFilters({ ...filters, size: v }),
			stateChanged: (v) => setFilters({ ...filters, state: v }),
			clearFilters: () => setFilters({}),
		},
	];
}
