import React, { useContext, useEffect } from 'react';
import { DatePicker, Button, Space, Select, Skeleton } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import StatsContext from '../context';

const { Option } = Select;

const Expenses = ({}) => {
	const [{ drivers }, { getDrivers }] = useContext(StatsContext);

	useEffect(() => {
		getDrivers();
	}, []);

	return drivers ? (
		<div className="inputs">
			<Space size={25}>
				<DatePicker placeholder="Data od" size="large" />
				<DatePicker placeholder="Data do" size="large" />
				<Select size="large" placeholder="Kierowca">
					{drivers.map((driver) => (
						<Option key={driver.id}>
							{driver.name} {driver.surname}
						</Option>
					))}
				</Select>
				<Button type="primary" size="large" icon={<CloseCircleOutlined />}>
					Wyczyść
				</Button>
			</Space>
		</div>
	) : (
		<Skeleton active />
	);
};

export default Expenses;
