import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Select, Skeleton, Space } from 'antd';
import { CheckCircleOutlined, EditOutlined, InfoCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { Page, PageSection } from '../../components';
import { useTaskDetails } from '../../reducers';
import { Formatting } from '../../utils';
import moment from 'moment';

import '../../styles/pages/tasks/taskDetails.scss';

const { Option } = Select;

const TaskDetails = ({ match }) => {
	const history = useHistory();
	const [
		{ order, admin, loading, drivers, assignee },
		{ takeTask, assigneeChanged, assignTask, unassignTask },
	] = useTaskDetails(match);

	const { id, type } = match.params;

	const taskType = useMemo(() => {
		switch (type) {
			case '0':
				return 'Podstawienie';
			case '1':
				return `Wymiana (Kontner nr ${order?.related?.container?.name})`;
			case '2':
				return 'Odbiór';
		}
	}, [type, order?.related?.container?.name]);

	const getActions = () => {
		if (!order || order.done) return [];

		if (!order.taken) {
			return [
				<Button
					key="0"
					type="primary"
					size="large"
					icon={<CheckCircleOutlined />}
					loading={loading === 'taking'}
					onClick={() => {
						switch (type) {
							case '0':
							case '1':
								history.push(`${type}/${order.size.id}`);
								break;

							case '2':
								takeTask();
								break;
						}
					}}
				>
					Zgłoś się do realizacji
				</Button>,
			];
		}

		if (order.taken_by?.id == window.sessionStorage.getItem('id')) {
			return [
				<Button
					key="0"
					type="primary"
					size="large"
					icon={<CheckCircleOutlined />}
					onClick={() => history.push(`/panel/task-closing/${id}/${type}`)}
				>
					Zrealizuj zadanie
				</Button>,
				<Button
					key="1"
					type="danger"
					size="large"
					icon={<UndoOutlined />}
					onClick={unassignTask}
					loading={loading === 'unassign'}
				>
					Wycofaj z realizacji
				</Button>,
			];
		}

		return [];
	};

	return (
		<Page title="Terminarz / Szczegóły zadania" actions={getActions()}>
			<Skeleton active loading={!order} />
			{order && (
				<div className="taskDetails flex">
					<div className="flex flex-dir flex-1">
						<PageSection title="Informacje" width={854}>
							<table>
								<tbody>
									<tr>
										<td>
											<p>Adres</p>
											<p>
												<span className="user-data">{order.address}</span>
											</p>
										</td>
										<td>
											<p>Rodzaj</p>
											<p>
												<span className="user-data">{taskType}</span>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>Rozmiar kontenera</p>
											<p>
												<span className="user-data">{order.size.name}</span>
											</p>
										</td>
										<td>
											<p>Numer kontenera</p>
											<p>
												<span className="user-data">{order.container?.name ?? '-'}</span>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>Rodzaj kontenera</p>
											<p>
												<span className="user-data">{order.container?.type ?? '-'}</span>
											</p>
										</td>
										<td>
											<p>Rodzaj odpadów</p>
											<p>
												<span className="user-data">{order.waste_type}</span>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>
												Cena (<span className="price-type">{order.price_type ? 'Brutto' : 'Netto'}</span>)
											</p>
											<p>
												<span className="user-data">{Formatting.money(order.price)}</span>
											</p>
										</td>
										<td>
											<p>Rodzaj płatności</p>
											<p>
												<span className="user-data">{order.payment_type == 0 ? 'Przelew' : 'Gotówka'}</span>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>Termin</p>
											<div className="date">
												<p>
													<span className="user-data">
														{order.date === null
															? 'Bezterminowo'
															: Formatting.epochToDate(order.date, moment(order.date).hours() === 0)}
													</span>
												</p>
											</div>
										</td>
										<td className="button">
											<Button
												type="primary"
												size="large"
												icon={<InfoCircleOutlined />}
												onClick={() => history.push(`/panel/orders/${id}`)}
											>
												Szczegóły zamówienia
											</Button>
										</td>
									</tr>
								</tbody>
							</table>
							{order.taken && (
								<div className="table">
									<table>
										<tbody>
											<tr>
												<td>Zgłoszone do realizacji:</td>
												<td>
													<span className="creation-data">{Formatting.epochToDate(order.taken)}</span>
												</td>
											</tr>

											<tr>
												<td>Zrealizowane:</td>
												<td>
													<span className="creation-data">{order.done ? Formatting.epochToDate(order.done) : '-'}</span>
												</td>
											</tr>

											<tr>
												<td>Przez:</td>
												<td>
													{admin ? (
														<Link to={`/panel/drivers/${order.taken_by.id}`}>
															{order.taken_by.name} {order.taken_by.surname}
														</Link>
													) : (
														<span>{order.taken_by.name}</span>
													)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							)}
						</PageSection>

						{order.done && (
							<PageSection title="Inne">
								<table>
									<tbody>
										<tr>
											<td>
												<p>Zaliczka</p>
												<p>
													<span className="user-data">{order.advance ? Formatting.money(order.advance) : '-'}</span>
												</p>
											</td>
											<td>
												<Button
													type="primary"
													size="large"
													icon={<EditOutlined />}
													disabled={!order.sign}
													onClick={() => history.push(`/panel/tasks/${id}/sign`, { sign: order.sign })}
												>
													Zobacz podpis WZ
												</Button>
											</td>
										</tr>
									</tbody>
								</table>
							</PageSection>
						)}

						<PageSection title="Klient" width={876}>
							<table>
								<tbody>
									<tr>
										<td>
											<p>Klient</p>
											<p>
												<span className="user-data">{order.client.name}</span>
											</p>
										</td>
										<td>
											<p>BDO</p>
											<p>
												<span className="user-data">{order.bdo === 0 ? 'BDO' : 'Wytwarzający'}</span>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>Typ</p>
											<p>
												<span className="user-data">{order.client.type === 0 ? 'Firma' : 'Osoba prywatna'}</span>
											</p>
										</td>
										<td>
											<p>Numer BDO</p>
											<p>
												<span className="user-data">{order.client.bdo ?? '-'}</span>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>Numer telefonu</p>
											<p>
												<span className="user-data">{order.client.phone}</span>
											</p>
										</td>
										<td>
											<p>Email</p>
											<p>
												<span className="user-data">{order.client.email}</span>
											</p>
										</td>
									</tr>
									<tr>
										<td>
											<p>Numer NIP</p>
											<p>
												<span className="user-data">{order.client.nip}</span>
											</p>
										</td>
										<td className="button">
											{admin && (
												<Button
													type="primary"
													size="large"
													icon={<InfoCircleOutlined />}
													onClick={() => history.push(`/panel/clients/${order.client.id}`)}
												>
													Szczegóły klienta
												</Button>
											)}
										</td>
									</tr>
								</tbody>
							</table>
						</PageSection>

						{admin && !order.taken && (
							<PageSection title="Przypisz zadanie kierowcy" width={876}>
								<div className="drivers flex flex-dir">
									<label>Kierowca</label>
									<Space size={25}>
										<Select size="large" value={order.taken_by?.id ?? assignee} onChange={assigneeChanged}>
											{drivers &&
												drivers.map((driver) => (
													<Option key={driver.id} value={driver.id}>
														{driver.name} {driver.surname}
													</Option>
												))}
										</Select>
										<Button
											type="primary"
											size="large"
											icon={<CheckCircleOutlined />}
											onClick={assignTask}
											loading={loading === 'assing'}
											disabled={!assignee}
										>
											Przypisz
										</Button>
									</Space>
								</div>
							</PageSection>
						)}
					</div>
				</div>
			)}
		</Page>
	);
};

export default TaskDetails;
