import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Select } from 'antd';
import { Query } from '../../utils';
import { NewDelivery, NewOwn, NewOther } from './orders/_orders';

const NewOrder = () => {
	const history = useHistory();

	const query = useMemo(() => Query.decode(history.location.search), [history.location.search]);
	const { type = '0' } = query;

	const select = (
		<Select size="large" value={type} onChange={(type) => history.replace(`?${Query.encode({ ...query, type })}`)}>
			<Select.Option value="0">Podstawienie</Select.Option>
			<Select.Option value="1">Własne</Select.Option>
			<Select.Option value="2">Inne</Select.Option>
		</Select>
	);

	const views = [<NewDelivery select={select} />, <NewOwn select={select} />, <NewOther select={select} />];

	return views[type];
};

export default NewOrder;
