import React, { useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Page } from '../../components';

import '../../styles/pages/tasks/sign.scss';

const SavedSign = ({ location }) => {
	let signCanvas;

	useEffect(() => {
		signCanvas.fromData(JSON.parse(location.state.sign));
		signCanvas.off();
	}, []);

	return (
		<Page title="Terminarz / Realizacja zadania">
			<div className="sign-wrapper">
				<SignatureCanvas
					ref={(ref) => (signCanvas = ref)}
					penColor="black"
					canvasProps={{
						className: 'sigCanvas',
					}}
				/>
			</div>
		</Page>
	);
};

export default SavedSign;
