import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DatePicker, Button, Skeleton, message, Space } from 'antd';
import { CloseCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import { Formatting, Query } from '../../../utils';
import { StatsPrint } from '../../../prints';
import styled from 'styled-components';
import moment from 'moment';
import API from '../../../API';
import { useReactToPrint } from 'react-to-print';

const Inputs = styled.div`
	> * {
		&:nth-child(1) {
			min-width: 200px;
		}

		&:nth-child(2) {
			min-width: 200px;
		}

		&:last-child {
			min-width: min-content;
		}
	}
`;

const Content = styled.div`
	overflow: auto;

	> div {
		&:not(:last-child) {
			margin-bottom: 25px;
		}

		> h2,
		h3,
		h4 {
			margin: 0;
		}

		> div:not(:last-child) {
			margin-bottom: 15px;
		}

		table {
			width: 100%;
		}
	}
`;

const Incomes = ({}) => {
	const [after, setAfter] = useState(moment().startOf('month'));
	const [before, setBefore] = useState(moment().endOf('month'));
	const [expenseTypes, setExpenseTypes] = useState();
	const [stats, setStats] = useState();

	const fetch = async () => {
		try {
			setStats();

			API.expenses.expenseTypes.getAll().then(setExpenseTypes);

			const start = after.startOf('day').unix() * 1000;
			const end = before.endOf('day').unix() * 1000;

			const stats = await API.stats.incomes({ after: start, before: end });

			let incomesSum = 0;
			for (const income of stats.incomes) {
				incomesSum += income.value;
			}

			// type_id: { net: 0, gross: 0, expenses: [] }
			const expensesSum = {};
			const expensesAll = { net: 0, gross: 0 };
			for (const expense of stats.expenses) {
				if (!expensesSum[expense.type_id]) {
					expensesSum[expense.type_id] = { net: 0, gross: 0, expenses: [] };
				}

				if (expense.value_type) {
					const net = expense.value * (1 - expense.tax / 100);

					expensesSum[expense.type_id].net += net;
					expensesSum[expense.type_id].gross += expense.value;

					expensesAll.net += net;
					expensesAll.gross += expense.value;
				} else {
					const gross = expense.value * (1 + expense.tax / 100);

					expensesSum[expense.type_id].net += expense.value;
					expensesSum[expense.type_id].gross += gross;

					expensesAll.net += expense.value;
					expensesAll.gross += gross;
				}

				expensesSum[expense.type_id].expenses.push(expense);
			}

			stats.incomes.sum = incomesSum;
			stats.expenses.sum = expensesSum;
			stats.expenses.net = expensesAll.net;
			stats.expenses.gross = expensesAll.gross;

			setStats(stats);
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		if (before < after) {
			return;
		}

		fetch();
	}, [after, before]);

	const clearFilters = () => {
		setAfter(moment().startOf('month'));
		setBefore(moment().endOf('month'));
	};

	const printRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	return expenseTypes && stats ? (
		<>
			{before < after && <p style={{ color: red[5] }}>Data do jest wcześniej od daty od, dane nie są odświeżone!</p>}
			<Inputs className="inputs">
				<DatePicker placeholder="Data od" size="large" value={after} onChange={setAfter} />
				<DatePicker placeholder="Data do" size="large" value={before} onChange={setBefore} />
				<Button type="primary" size="large" icon={<CloseCircleOutlined />} onClick={clearFilters}>
					Wyczyść
				</Button>
				<Button size="large" type="primary" icon={<PrinterOutlined />} onClick={() => handlePrint()}>
					Drukuj
				</Button>
			</Inputs>
			<Content>
				<StatsPrint after={after} before={before} expenseTypes={expenseTypes} stats={stats} />
				<StatsPrint ref={printRef} after={after} before={before} expenseTypes={expenseTypes} stats={stats} print />
				{/* <div>
					<h2>Przychód z zamówień:</h2>
					<Space size={20}>
						<h3>Gotówka: {Formatting.money(stats.orders.cash)}</h3>
						<h3>Przelew: {Formatting.money(stats.orders.transfer)}</h3>
						<h3>Suma: {Formatting.money(stats.orders.cash + stats.orders.transfer)}</h3>
					</Space>
					<Space size={20}>
						<h3>Netto: {Formatting.money(stats.orders.net)}</h3>
						<h3>Brutto: {Formatting.money(stats.orders.gross)}</h3>
					</Space>
				</div>
				<div>
					<h2>Przychód z zamówień innych: {Formatting.money(stats.incomes.sum)}</h2>
				</div>
				<div>
					<h2>Wydatki:</h2>
					{Object.entries(stats.expenses.sum).map(([k, v]) => {
						const typeName = expenseTypes.filter((e) => e.id == k)[0]?.name;

						return (
							<div>
								<h3>{typeName}</h3>
								<table>
									<tbody>
										<tr key={k} size={20}>
											<th>Netto: {Formatting.money(v.net)}</th>
											<th>Brutto: {Formatting.money(v.gross)}</th>
											<th>Podatek: {Formatting.money(v.gross - v.net)}</th>
										</tr>
										{v.expenses.map((expense) => (
											<tr>
												<td>{expense.name ?? '-'}</td>
												<td>{Formatting.money(expense.value)}</td>
												<td>{Formatting.epochToDate(expense.created)}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						);
					})}
				</div>
				<div>
					<h2>Podsumowanie:</h2>
					<h3>Łączny przychód: {Formatting.money(stats.orders.cash + stats.orders.transfer + stats.incomes.sum)}</h3>
					<Space size={20}>
						<h3>Wydatki netto: {Formatting.money(stats.expenses.net)}</h3>
						<h3>Wydatki brutto: {Formatting.money(stats.expenses.gross)}</h3>
						<h3>Podatek: {Formatting.money(stats.expenses.gross - stats.expenses.net)}</h3>
					</Space>
				</div> */}
			</Content>
		</>
	) : (
		<Skeleton active />
	);
};

export default Incomes;
