import React, { useState, useMemo } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SearchSelector = ({ elements, value, id, onChange, buildElement, width, allowCustom, ignoreCaseAndTrim }) => {
	const [search, setSearch] = useState();

	const getElementId = (element) => {
		if (!id) {
			return element;
		}

		if (!element) {
			return element;
		}

		return element[id];
	};

	const selectedElement = useMemo(() => {
		const foundElement = elements?.find((element) => {
			if (ignoreCaseAndTrim) {
				return getElementId(element)?.trim()?.toLowerCase() == value?.trim()?.toLowerCase();
			}

			return getElementId(element) == value;
		});

		if (foundElement) {
			return foundElement;
		}

		if (!allowCustom || !search) {
			return null;
		}

		return search;
	}, [elements, value]);

	const options = useMemo(() => {
		const options = elements?.map((element) => {
			const id = getElementId(element);

			return (
				<Option key={id} value={id}>
					{buildElement(element)}
				</Option>
			);
		});

		if (allowCustom && search) {
			const child = `${search} (nowy)`;

			options?.push(
				<Option key={search} value={search}>
					{child}
				</Option>
			);
		}

		return options;
	}, [elements, search, allowCustom]);

	const onSearch = (value) => {
		const foundElement = elements?.find((element) => buildElement(element) == value);
		if (foundElement) {
			setSearch();
		} else {
			setSearch(value);
		}
	};

	return (
		<Select
			showSearch
			onSearch={onSearch}
			style={{ width: width }}
			value={selectedElement && buildElement(selectedElement)}
			onChange={(id) => onChange(id)}
			optionFilterProp="children"
			filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
			filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
		>
			{options}
		</Select>
	);
};

export default SearchSelector;
