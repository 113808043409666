import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import API from '../../API';

export default function () {
	const history = useHistory();

	const [settlement, setSettlement] = useState({});
	const [drivers, setDrivers] = useState();
	const [loading, setLoading] = useState(false);
	const disabled = isNaN(settlement.user_id) || !settlement.value?.trim();

	const fetch = async () => {
		try {
			setDrivers((await API.users.getAll()).filter((d) => !d.removed));
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const createSettlement = async () => {
		if (disabled) return;

		try {
			setLoading(true);

			settlement.value.replace(/\,/g, '.');
			await API.expenses.settlements.create(settlement);

			setLoading();
			message.success('Pobrano gotówkę');
			history.replace(`/panel/expenses`);
		} catch (e) {
			message.error(`${e}`);
			console.trace(e);
			setLoading();
		}
	};

	const driver = useMemo(() => drivers?.filter((d) => d.id === settlement.user_id)[0], [settlement.user_id]);

	return [
		{
			drivers,
			disabled,
			loading,
			driver,
		},
		{
			driverChanged: (value) =>
				setSettlement({
					...settlement,
					user_id: drivers.filter((d) => value === `${d.name} ${d.surname}`)[0]?.id,
				}),
			valueChanged: (e) => setSettlement({ ...settlement, value: e.target.value }),
			createSettlement,
		},
	];
}
