import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import API from '../../API';

export default function () {
	const [id, setId] = useState();
	const [type, setType] = useState();

	const [order, setOrder] = useState();
	const [orders, setOrders] = useState();
	const [paid, setPaid] = useState();
	const [ordersPaid, setOrdersPaid] = useState([]);

	const [paymentDone, setPaymentDone] = useState();
	const [advance, setAdvance] = useState();

	const [wz, setWz] = useState();
	const [signName, setSignName] = useState();
	const [signSurname, setSignSurname] = useState();
	const [signDocument, setSignDocument] = useState();
	const [sign, setSign] = useState();

	const fetch = async () => {
		try {
			const order = await API.orders.get(id);
			if (order.related_id !== null && order.id > order.related_id) {
				order.related = await API.orders.get(order.related_id);
			}
			setOrder(order);

			const orders = await API.orders.getAll({ client_id: order.client.id });

			setOrders(
				orders
					.filter((o) => {
						// Same order
						if (o.id === order.id) return false;

						// Replacement
						if (type === 1 && order.related_id === o.id) return false;

						// Paid or not cash
						if (o.payment || o.payment_type !== 1) return false;

						return true;
					})
					.map((o) => ({ ...o, key: o.id }))
			);
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		id && type && fetch();
	}, [id, type]);

	const finishTask = async () => {
		try {
			const data = {};

			if (advance) {
				data.advance = advance;
			}

			if (wz) {
				data.sign_name = signName;
				data.sign_surname = signSurname;
				data.sign_document = signDocument;
				data.sign = JSON.stringify(sign);
			}

			if (paid !== undefined) {
				data.payment = paid ? 1 : 0;
			}

			if (type === '0') {
				if (!paymentDone) delete data.payment;
				else data.payment = 1;

				await API.orders.patch(id, { delivery_done: 1, ...data });

				if (order.on_place) {
					data.payment = paid ? 1 : 0;
					await API.orders.patch(id, { claiming_done: 1, ...data });
				}
			} else if (type === '1') {
				const order = await API.orders.get(id);

				const d = { delivery_done: 1, ...data };

				if (!paymentDone) delete d.payment;
				else d.payment = 1;

				await API.orders.patch(id, d);

				const c = { claiming_done: 1, ...data };
				delete c.advance;
				await API.orders.patch(order.related_id, c);
			} else if (type === '2') {
				await API.orders.patch(id, { claiming_done: 1, ...data });
			}

			if (ordersPaid.length) {
				for (const id of ordersPaid) {
					await API.orders.patch(id, { payment: 1 });
				}
			}

			history.go(-1);
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	const clear = () => {
		setAdvance();
	};

	const finishDisabled = useMemo(() => {
		if (wz) {
			return !signName || !signSurname || !signDocument || !sign;
		}
	}, [wz, signName, signSurname, signDocument, sign]);

	return [
		{
			id,
			type,
			orders,
			order,
			ordersPaid,
			paid,
			paymentDone,
			advance,
			wz,
			signName,
			signSurname,
			signDocument,
			sign,
			finishDisabled,
		},
		{
			setId,
			setType,

			setPaid,
			setOrderAsPaid: (id) => setOrdersPaid((paid) => [...paid, id]),
			setOrderAsUnpaid: (id) => setOrdersPaid((paid) => [...paid.filter((i) => i !== id)]),
			paymentDoneChanged: (e) => setPaymentDone(e.target.checked),
			advanceChanged: (e) => setAdvance(e.target.value),
			clear,

			setWz,
			signNameChanged: (e) => setSignName(e.target.value),
			signSurnameChanged: (e) => setSignSurname(e.target.value),
			signDocumentChanged: (e) => setSignDocument(e.target.value),
			setSign,

			finishTask,
		},
	];
}
