export default {
	encode: (data, separator = '&') =>
		data
			? Object.entries(data)
					.filter(([k, v]) => v !== undefined)
					.map(([k, v]) =>
						(Array.isArray(v) ? v : [v]).map((e) => `${encodeURIComponent(k)}=${encodeURIComponent(e)}`).join(separator)
					)
					.join(separator)
			: '',
	decode: (query, separator = '&') =>
		query
			? query
					.replace(/^\?/, '')
					.split(separator)
					.reduce((acc, e) => {
						const [k, v] = e.split('=');

						if (acc[k] !== undefined) {
							if (Array.isArray(acc[k])) acc[k].push(v);
							else acc[k] = [acc[k], v];
						} else acc[k] = v;

						return acc;
					}, {})
			: {},
};
