import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
	flex: ${({ width }) => width};
	height: 100%;
	overflow-y: auto;

	> ul {
		padding-top: 5px;
		height: 90%;
		overflow-y: auto;
	}
`;

const P = styled.p`
	font-size: 24px;
	font-weight: 500;
	color: #096dd9;
`;

const HeaderWrapper = styled.div`
	border-bottom: 1px solid #bfbfbf;
	margin-bottom: 45px;
	align-items: flex-end;

	> .ant-select {
		width: 300px;
		margin-bottom: 3px;
	}
`;

const PageSection = ({ title, width, children, select }) => {
	return (
		<Section width={width} className="page-section">
			{title && (
				<HeaderWrapper className="flex flex-jsb">
					<P>{title}</P>
					{select}
				</HeaderWrapper>
			)}
			{children}
		</Section>
	);
};

export default PageSection;
