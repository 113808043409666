import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { Query } from '../../utils';
import API from '../../API';
import moment from 'moment';

export default function useExpenses(match) {
	const history = useHistory();
	const path = history.location.pathname;
	const query = Query.decode(history.location.search);
	const visible = query.visible === '1';
	const advances = query.advances === '1';
	const admin = window.sessionStorage.getItem('level') > 0;
	const { id } = match.params;

	const [user, setUser] = useState();
	const [data, setData] = useState();
	const [filters, setFilters] = useState({});
	const [loading, setLoading] = useState();
	const [month, setMonth] = useState(moment());

	const fetch = async () => {
		try {
			const filters = {
				after: Math.min(month.startOf('month').unix() * 1000, Date.now()),
				before: Math.max(month.endOf('month').unix() * 1000, 0),
				company: admin ? 1 : undefined,
			};

			if (admin) {
				const userId = window.sessionStorage.getItem('id');
				if (!userId) {
					throw 'Błąd';
				}

				setUser(await API.users.get(userId));
			}

			if (!visible) filters.user_id = window.sessionStorage.getItem('id');

			const users = admin && (await API.users.getAll());
			const data = (await API.expenses.getAll(filters)).map((expense) => ({
				...expense,
				key: `0-${expense.id}`,
				settled_by: admin && expense.settled && users.filter((u) => expense.settled_by === u.id)[0],
				created_by: admin && users.filter((u) => expense.user_id === u.id)[0],
				_type: 0,
			}));

			const settlements = (await API.expenses.settlements.getAll(filters)).map((settlement) => ({
				...settlement,
				key: `1-${settlement.id}`,
				created_by: admin && users.filter((u) => settlement.user_id === u.id)[0],
				_type: 1,
			}));

			const advs = advances
				? (await API.orders.getAll({ ...filters, advances: 1 }))
						.filter((order) => order.advance)
						.map((order) => ({
							...order,
							key: `2-${order.id}`,
							value: order.advance,
							created: order.delivery_done,
							created_by: admin && users.filter((u) => order.delivery_by?.id === u.id)[0],
							_type: 2,
						}))
				: [];

			setData([...data, ...settlements, ...advs]);
		} catch (e) {
			message.error(`${e}`);
			console.trace(e);
		}
	};

	useEffect(() => {
		if (id !== undefined) {
			setFilters({ id: id });
		}
	}, [id]);

	useEffect(() => {
		fetch();
	}, [path, visible, advances, month]);

	const getValue = (element) => {
		switch (element._type) {
			// Expenses
			case 0:
				return -element.value;

			// Settlements
			case 1:
				return -element.value;

			// Advances
			case 2:
				return element.value;
		}
	};

	const filtered = useMemo(() => {
		if (!data) return;

		return data.filter((exp) => {
			if (!isNaN(filters.id)) {
				return parseInt(filters.id) === exp.id;
			}

			if (filters.search) {
				if (`${exp.name ?? 'Tankowanie'}`.toLowerCase().includes(filters.search.toLowerCase()));
				else if (`${exp.invoice}`.toLowerCase().includes(filters.search.toLowerCase()));
				else return false;
			}

			if (filters.car) {
				if (!`${exp.car}`.toLowerCase().includes(filters.car.toLowerCase())) return false;
			}

			if (filters.settled !== undefined) {
				if (filters.settled === 1 && exp.settled) return false;
				if (filters.settled === 0 && !exp.settled) return false;
			}

			return true;
		});
	}, [data, filters]);

	const settle = async (id) => {
		if (loading) return;

		try {
			setLoading(id);

			await API.expenses.settle(id);
			await fetch();

			setLoading();
			message.success('Rozliczono wydatek');
		} catch (e) {
			setLoading();
			message.error(`${e}`);
		}
	};

	return [
		{ user, data: filtered, filters, visible, advances, admin, loading, month },
		{
			toggleVisible: () => history.replace(`?visible=${visible ? 0 : 1}&advances=${advances ? 1 : 0}`),
			toggleAdvances: () => history.replace(`?visible=${visible ? 1 : 0}&advances=${advances ? 0 : 1}`),
			searchChanged: (e) => setFilters({ ...filters, search: e.target.value }),
			carChanged: (e) => setFilters({ ...filters, car: e.target.value }),
			settledChanged: (value) => setFilters({ ...filters, settled: value }),
			clearFilters: () => {
				if (!isNaN(filters.id)) {
					history.replace('/panel/expenses');
				}

				setFilters({});
			},
			settle,
			setMonth,
		},
	];
}
