import React from 'react';
import { Button, Modal, Space } from 'antd';
import { WarningFilled } from '@ant-design/icons';

import '../../styles/modals/modal.scss';

const WarningModal = ({ visible, onCancel, onOk, okColor, children, loading, height, disabled }) => {
	return (
		<Modal
			title=""
			visible={visible}
			// onCancel={onCancel}
			width={700}
			height={height ? height : 489}
			closable={false}
			footer={
				<Space size={50}>
					<Button type="secondary" size="large" onClick={onCancel}>
						Nie
					</Button>
					<Button
						type="primary"
						size="large"
						onClick={onOk}
						loading={loading}
						style={{ backgroundColor: okColor ?? '#f5222d' }}
						disabled={disabled}
					>
						Tak
					</Button>
				</Space>
			}
		>
			<WarningFilled style={{ color: '#FAAD14' }} />
			{children}
		</Modal>
	);
};

export default WarningModal;
