import React, { useMemo } from 'react';
import { Formatting } from '../utils';
import styled from 'styled-components';
import moment from 'moment';

const Print = styled.div``;

const PrintTable = styled.table`
	width: 100%;
	page-break-inside: auto;

	tr {
		page-break-inside: avoid;
		page-break-after: auto;

		&:nth-child(2n) {
			background: #eee;
		}

		> td,
		> th {
			padding: 4px 8px;
			text-align: center;
		}

		> th {
			font-size: 14px;

			> div {
				display: flex;
				flex-direction: column;

				> h5 {
					font-weight: 600;
					margin-bottom: 4px;
				}

				> span {
					font-weight: 400;
					font-size: 8px;
				}
			}
		}

		> td {
			font-size: 10px;

			> div {
				display: flex;
				flex-direction: column;

				> h6 {
					font-weight: 600;
					font-size: 12px;
					margin-bottom: 2px;
				}
			}
		}
	}
`;

const PrintTitle = styled.h2`
	width: 100%;
	margin-top: 16px;
	margin-bottom: 8px;
	text-align: center;
	font-size: 18px;
`;

const PrintSubtitle = styled.h3`
	width: 100%;
	margin-top: 16px;
	margin-bottom: 32px;
	text-align: center;
	font-size: 14px;
`;

const DayTitle = styled(PrintSubtitle)`
	margin-top: 48px;
`;

const Summary = styled.h4`
	text-align: center;
	margin: ${({ first }) => (first ? '24px' : '0')} 0 0 0;
	font-weight: 400;
`;

const SummaryTitle = styled.h3`
	text-align: center;
	margin: 32px 0 0 0;
	font-weight: 500;
`;

const NoBreak = styled.div`
	break-inside: avoid;
`;

const PageBreak = styled.div`
	&:not(:last-of-type) {
		page-break-after: always;
	}
`;

const FinancesPrint = ({ unclosed, from, to, orders }) => {
	if (!orders) return <div></div>;

	const days = orders.reduce((acc, order) => {
		const deliveryDate = order.delivery_date;
		if (new Date(deliveryDate) >= from && new Date(deliveryDate) <= to) {
			const deliveryDateString = moment(deliveryDate).format('DD.MM.YYYY');

			if (!acc[deliveryDateString]) {
				acc[deliveryDateString] = {
					orders: [],
					claimings: [],
				};
			}

			acc[deliveryDateString].orders.push({
				...order,
				_date: deliveryDate,
			});
		}

		// Skip if it was replacement or it was on_place as we do not want to show this in the claimings.
		if (order.wasReplaced || order.on_place) {
			return acc;
		}

		const claimingDate = order.claiming_date;
		if (new Date(claimingDate) >= from && new Date(claimingDate) <= to) {
			const claimingDateString = moment(claimingDate).format('DD.MM.YYYY');

			if (!acc[claimingDateString]) {
				acc[claimingDateString] = {
					orders: [],
					claimings: [],
				};
			}

			acc[claimingDateString].claimings.push({
				...order,
				_date: claimingDate,
			});
		}

		return acc;
	}, {});

	const sum = useMemo(() => {
		return orders.reduce(
			(acc, order) => {
				if (order.claiming_done) {
					acc[0] += order.price;

					if (order.payment_type) acc[1] += order.price;
				}

				acc[2] += order.price;
				if (order.payment_type) acc[3] += order.price;

				return acc;
			},
			[0, 0, 0, 0] // closed, closed cash, all, all cash
		);
	}, [orders]);

	const dayNames = useMemo(() => {
		const dayNames = Object.keys(days);
		dayNames.sort();

		return dayNames;
	}, [days]);

	let ordersCounter = 0;

	return (
		<Print className="print">
			<PrintTitle>
				Wydruk {unclosed ? 'wszystkich' : 'zakończonych'} zamówień między {Formatting.epochToDate(from, true)} a{' '}
				{Formatting.epochToDate(to, true)}
			</PrintTitle>
			<PrintSubtitle>Stan z dnia {Formatting.epochToDate(Date.now())}</PrintSubtitle>
			{dayNames.map((dayName, i) => {
				const dayEntry = days[dayName];
				const ordersSum = dayEntry.orders.reduce(
					(acc, order) => {
						if (order.claiming_done) {
							acc[0] += order.price;

							if (order.payment_type) acc[1] += order.price;
						}

						acc[2] += order.price;
						if (order.payment_type) acc[3] += order.price;

						return acc;
					},
					[0, 0, 0, 0] // closed, closed cash, all, all cash
				);

				const ordersSoFar = ordersCounter;
				ordersCounter += dayEntry.orders.length;

				return (
					<React.Fragment key={dayName}>
						<DayTitle>Podstawienia {dayName}:</DayTitle>
						<PrintTable>
							<tbody>
								<tr>
									<th>
										<div>
											<h5>LP</h5>
											<span>w grupie</span>
										</div>
									</th>
									<th>
										<div>
											<h5>Klient</h5>
											<span>Nazwa</span>
											<span>NIP</span>
											<span>Email</span>
										</div>
									</th>
									<th>
										<div>
											<h5>Cena</h5>
											<span>Brutto / Netto</span>
											<span>Typ płatności</span>
										</div>
									</th>
									<th>
										<div>
											<h5>Adres</h5>
											<span>BDO</span>
											<span>Rodzaj odpadów</span>
										</div>
									</th>
									<th>Faktura</th>
									<th>Data</th>
								</tr>
								{dayEntry.orders.map((order, j) => (
									<tr key={`order-${order.id}`}>
										<td>
											<div>
												<h6>{ordersSoFar + j + 1}</h6>
												<span>{j + 1}</span>
											</div>
										</td>
										<td>
											<div>
												<span>{order.client.name}</span>
												<span>{order.client.nip}</span>
												<span>{order.client.email}</span>
											</div>
										</td>
										<td>
											<div>
												<h6>{Formatting.money(order.price)}</h6>
												<span>{order.price_type ? 'Brutto' : 'Netto'}</span>
												<span>{order.payment_type ? 'Gotówka' : 'Przelew'}</span>
											</div>
										</td>
										<td>
											<div>
												<h6>{order.address}</h6>
												<span>{order.bdo ? 'Wytwarzający' : 'BDO'}</span>
												<span>{order.waste_type}</span>
											</div>
										</td>
										<td>{order.invoice ?? '-'}</td>
										<td>{Formatting.epochToDate(order._date, true)}</td>
									</tr>
								))}
							</tbody>
						</PrintTable>
						<Summary first>
							Przychód: {Formatting.money(ordersSum[0])} ({Formatting.money(ordersSum[1])} gotówka)
						</Summary>
						<Summary>
							{unclosed &&
								`Razem z otwartymi: ${Formatting.money(ordersSum[2])} (${Formatting.money(ordersSum[3])} gotówka)`}
						</Summary>
						{dayEntry.claimings && (
							<NoBreak>
								<DayTitle>Odbiory {dayName}:</DayTitle>
								<PrintTable>
									<tbody>
										<tr>
											<th>
												<div>
													<h5>Klient</h5>
													<span>Nazwa</span>
													<span>NIP</span>
													<span>Email</span>
												</div>
											</th>
											<th>
												<div>
													<h5>Cena</h5>
													<span>Brutto / Netto</span>
													<span>Typ płatności</span>
												</div>
											</th>
											<th>
												<div>
													<h5>Adres</h5>
													<span>BDO</span>
													<span>Rodzaj odpadów</span>
												</div>
											</th>
											<th>Data</th>
										</tr>
										{dayEntry.claimings.map((order, j) => (
											<tr key={`claiming-${order.id}`}>
												<td>
													<div>
														<span>{order.client.name}</span>
														<span>{order.client.nip}</span>
														<span>{order.client.email}</span>
													</div>
												</td>
												<td>
													<div>
														<h6>{Formatting.money(order.price)}</h6>
														<span>{order.price_type ? 'Brutto' : 'Netto'}</span>
														<span>{order.payment_type ? 'Gotówka' : 'Przelew'}</span>
													</div>
												</td>
												<td>
													<div>
														<h6>{order.address}</h6>
														<span>{order.bdo ? 'Wytwarzający' : 'BDO'}</span>
														<span>{order.waste_type}</span>
													</div>
												</td>
												<td>{Formatting.epochToDate(order._date, true)}</td>
											</tr>
										))}
									</tbody>
								</PrintTable>
							</NoBreak>
						)}
						<PageBreak />
					</React.Fragment>
				);
			})}
			<SummaryTitle>Sumarycznie:</SummaryTitle>
			<Summary>
				Przychód: {Formatting.money(sum[0])} ({Formatting.money(sum[1])} gotówka)
			</Summary>
			<Summary>
				{unclosed && `Razem z otwartymi: ${Formatting.money(sum[2])} (${Formatting.money(sum[3])} gotówka)`}
			</Summary>
		</Print>
	);
};

export default class extends React.PureComponent {
	render() {
		return <FinancesPrint {...this.props} />;
	}
}
