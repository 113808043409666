import React, { useState } from 'react';
import { Checkbox, DatePicker, Space } from 'antd';
import { InfoModal } from '../../components';
import moment from 'moment';

const DateModal = ({ visible, onCancel, onOk, loading, deadline }) => {
	const [date, setDate] = useState();
	const [time, setTime] = useState();

	const approve = async () => {
		const d = date?.clone();

		if (d) {
			if (!time) {
				d.hours(0);
				d.minutes(0);
			} else {
				d.hours(time.get('hour'));
				d.minutes(time.get('minute'));
			}

			d.seconds(0);
		}

		const success = await onOk(d ?? null);

		if (success) {
			setDate();
			setTime();
		}
	};

	return (
		<InfoModal
			visible={visible}
			closable={false}
			cancelText="Anuluj"
			okText="Edytuj"
			onCancel={onCancel}
			onOk={approve}
			disabled={date === undefined}
			loading={loading}
		>
			<h1>Edytuj datę?</h1>
			<div className="input">
				{date !== null && (
					<>
						Data:
						<Space size={20}>
							<DatePicker size="large" placeholder="Wybierz datę" value={date} onChange={setDate} />
							<DatePicker.TimePicker
								size="large"
								format="HH:mm"
								placeholder="Wybierz godzinę"
								value={time}
								onChange={setTime}
							/>
						</Space>
					</>
				)}
				{!deadline && (
					<Checkbox checked={date === null} onChange={(e) => setDate(e.target.checked ? null : moment())}>
						<label onClick={() => setDate(date === null ? moment() : null)}>Bez terminu odbioru</label>
					</Checkbox>
				)}
			</div>
		</InfoModal>
	);
};

export default DateModal;
