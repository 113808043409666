import React, { useState } from 'react';
import { Button, Input, Skeleton, Space } from 'antd';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { DateModal, Page, PageSection } from '../../../../components';
import ClientInfo from '../delivery/components/client_info';
import useOwnDetails from './reducers/useOwnDetails';
import styled from 'styled-components';

const Closing = styled.div`
	> .ant-space {
		&:not(:last-child) {
			margin-bottom: 25px;
		}

		> div {
			flex: 1;

			> div {
				> label {
					margin-bottom: 4px;
				}

				.pln {
					margin-left: 4px;
				}
			}
		}
	}
`;

const DateEdit = styled.div`
	margin-bottom: 45px;
`;

const OwnDetails = ({ match }) => {
	const [{ own, weight, dateModal, loading }, { weightChanged, setDateModal, editDate, close }] = useOwnDetails(match);

	return (
		<>
			<Page title="Szczegóły zamówienia własnego">
				{!own ? (
					<Skeleton active />
				) : (
					<PageSection title="Informacje">
						<ClientInfo name own={own} />
						<DateEdit>
							<Button type="primary" size="large" icon={<EditOutlined />} onClick={() => setDateModal(true)}>
								Edytuj datę
							</Button>
						</DateEdit>
						{!own.closed && (
							<PageSection title="Zamknięcie">
								<Closing>
									<Space className="flex" size={25}>
										<div className="flex flex-dir">
											<label>Waga</label>
											<Input size="large" value={weight} onChange={weightChanged} />
										</div>
										<div className="flex flex-dir"></div>
									</Space>
									<Space className="flex" size={25}>
										<div>
											<Button
												type="primary"
												size="large"
												icon={<CheckCircleOutlined />}
												loading={loading === 'closing'}
												onClick={close}
												disabled={isNaN(weight)}
											>
												Zamknij
											</Button>
										</div>
										<div className="flex flex-dir"></div>
									</Space>
								</Closing>
							</PageSection>
						)}
					</PageSection>
				)}
			</Page>
			<DateModal
				visible={dateModal}
				onCancel={() => setDateModal()}
				onOk={editDate}
				loading={loading === 'date'}
				deadline={true}
			/>
		</>
	);
};

export default OwnDetails;
