import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import API from '../API';

export default function usePasswordChange() {
	const history = useHistory();

	const [password, setPassword] = useState('');
	const [passwordRepeat, setPasswordRepeat] = useState('');
	const [loading, setLoading] = useState(false);

	const [error, setError] = useState('');
	const [inputType, setInputType] = useState('password');

	const changePassword = async (e) => {
		e.preventDefault();

		const oldPassword = history.location.state;

		if (password.length <= 6) return setError('Hasło musi mieć co najmniej 6 znaków');
		else if (password !== passwordRepeat) return setError('Hasła nie są takie same');
		else if (password === oldPassword) return setError('Hasło musi być inne niż poprzednie');

		try {
			setLoading(true);
			await API.users.patch(window.sessionStorage.getItem('id'), { password, oldPassword });
			setLoading(false);

			history.push('/panel');
		} catch (e) {
			setLoading(false);

			console.trace(e);
			setError(`${e}`);
		}
	};

	return [
		{ password, passwordRepeat, inputType, loading, error },
		{
			passwordChanged: (e) => setPassword(e.target.value),
			passwordRepeatChanged: (e) => setPasswordRepeat(e.target.value),
			newPassword: changePassword,
			touchStart: (e) => {
				e.preventDefault();
				e.stopPropagation();

				setInputType('text');
			},
			touchMove: (e) => {
				e.preventDefault();
				e.stopPropagation();

				setInputType('password');
			},
			touchEnd: (e) => {
				e.preventDefault();
				e.stopPropagation();

				setInputType('password');
			},
		},
	];
}
