import React from 'react';
import { Modal } from 'antd';
import { InfoCircleFilled, PlusCircleFilled } from '@ant-design/icons';

import '../../styles/modals/modal.scss';

const InfoModal = ({
	visible,
	onCancel,
	onOk,
	children,
	width,
	height,
	cancelText,
	okText,
	icon,
	disabled,
	loading,
}) => {
	return (
		<Modal
			title=""
			visible={visible}
			onCancel={onCancel}
			closable={false}
			onOk={onOk}
			width={width ? width : 1000}
			height={height ? height : 489}
			cancelText={cancelText ? cancelText : 'Nie'}
			okText={okText ? okText : 'Tak'}
			cancelButtonProps={{ size: 'large' }}
			okButtonProps={{ size: 'large', type: 'primary', disabled, loading }}
		>
			{icon == 'plus' ? (
				<PlusCircleFilled style={{ color: '#1890FF' }} />
			) : (
				<InfoCircleFilled style={{ color: '#1890FF' }} />
			)}
			{children}
		</Modal>
	);
};

export default InfoModal;
