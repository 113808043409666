import React, { useMemo } from 'react';
import { Button, DatePicker } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';

const Wrapper = styled.div`
	flex: 1;
	height: 1px;

	display: flex;
	flex-direction: column;

	overflow: hidden;

	#heading {
		display: flex;
		align-items: center;

		width: 100%;

		background: $white;
		box-shadow: 0 7px 10px -4px rgba(0, 0, 0, 0.3);

		margin-bottom: 8px;

		> button {
			width: 56px;
			height: 56px;

			&:first-child {
				margin-right: 25px;
			}
		}

		> .date {
			min-height: 100px;
			min-width: 350px;
			min-height: 150px;
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			> h4 {
				color: $gray-9;
				font-weight: 500;
				font-size: 24px;
				margin-bottom: 0;
			}

			> span {
				color: $gray-8;
				font-size: 18px;
			}

			> .ant-picker {
				width: 300px;
				max-width: 100%;
				margin: 10px 0 20px 0;
			}
		}
	}

	.calendar-wrapper {
		flex: 1;
		overflow-y: auto;
		display: flex;
		height: 1px;
		width: 100%;
	}
`;

const Head = ({ offset, setOffset }) => {
	const start = useMemo(() => moment().add(offset, 'days').startOf('day'), [offset]);

	const dayName = start.format('dddd');
	const monthName = start.format('MMMM');
	const dayNum = start.date();

	const onChange = (date) => setOffset(date.startOf('day').diff(moment().startOf('day'), 'days'));

	return (
		<div id="heading">
			<Button shape="circle" size="large" icon={<LeftOutlined />} onClick={() => setOffset((offset) => --offset)} />

			<div className="date">
				<h4>{dayName.charAt(0).toUpperCase() + dayName.slice(1)}</h4>
				<span>
					{dayNum} {monthName}
				</span>
				<DatePicker size="large" value={start} onChange={onChange} allowClear={false} />
			</div>

			<Button shape="circle" size="large" icon={<RightOutlined />} onClick={() => setOffset((offset) => ++offset)} />
		</div>
	);
};

const Calendar = ({ children, offset, onChange }) => {
	return (
		<Wrapper>
			<Head offset={offset} setOffset={onChange} />
			<div className="calendar-wrapper">{children}</div>
		</Wrapper>
	);
};

export default Calendar;
