import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { isBetween, Query } from '../../utils';
import { CardPrint } from '../../prints';
import API from '../../API';
import moment from 'moment';
import Formatting from '../../utils/formatting';

export default function useDriverCard(match) {
	const history = useHistory();

	const [offset, setOffset] = useState(parseInt(Query.decode(history.location.search)?.offset ?? '0') ?? 0);
	const [user, setUser] = useState();
	const [events, setEvents] = useState([]);
	const [card, setCard] = useState({});
	const [loading, setLoading] = useState();
	const [printDates, setPrintDates] = useState({ from: moment().subtract(7, 'days'), to: moment() });
	const [prints, setPrints] = useState();
	const [stats, setStats] = useState();

	const { id } = match.params;

	useEffect(() => {
		fetch();
	}, [id, offset]);

	const fetchCard = async (start, end) => {
		const cards = await API.users.cards.getAll({ after: start, before: end, user_id: id });

		const orders = await API.orders.getAll({ after: start, before: end });
		const expenses = await API.expenses.getAll({ user_id: id, after: start, before: end });
		const settlements = await API.expenses.settlements.getAll({ user_id: id, after: start, before: end });

		const events = [];

		const tasks = [];

		for (const order of orders) {
			// Split order into two tasks (delivery and claiming)

			// If it's replacement
			if (order.related_id) {
				// Old to replace task
				if (order.related_id > order.id) {
					// Check if it also was a replacement
					if (order._replacement) {
						// It was a replacement.

						tasks.push({
							id: `${order.id}-1`,
							type: -1,
							date: order.delivery_date,
							taken: order.delivery_taken,
							taken_by: order.delivery_by,
							done: order.delivery_done,
							order,
						});
					} else {
						// It was a delivery.

						tasks.push({
							id: `${order.id}-0`,
							type: 0,
							date: order.delivery_date,
							taken: order.delivery_taken,
							taken_by: order.delivery_by,
							done: order.delivery_done,
							order,
						});
					}

					// Add it's claiming (which is replacement itself)
					const existing = tasks.filter((t) => t.order?.id === order.related_id)[0];

					if (!existing) tasks.push({ old: order });
					else existing.old = order;
				}

				// New replacement task
				if (order.related_id < order.id) {
					// Add it's delivery (which is replacement itself)
					const existing = tasks.filter((t) => t.old?.id === order.related_id)[0];
					if (!existing) {
						tasks.push({
							id: `${order.id}-1`,
							type: -1,
							date: order.delivery_date,
							taken: order.delivery_taken,
							taken_by: order.delivery_by,
							done: order.delivery_done,
							order,
						});
					} else {
						existing.id = `${order.id}-1`;
						existing.type = -1;
						existing.date = order.delivery_date;
						existing.taken = order.delivery_taken;
						existing.taken_by = order.delivery_by;
						existing.done = order.delivery_done;
						existing.order = order;
					}

					// If it's delivered add it's claiming
					if (order.delivery_done && order.claiming_date) {
						tasks.push({
							id: `${order.id}-2`,
							type: 1,
							date: order.claiming_date,
							taken: order.claiming_taken,
							taken_by: order.claiming_by,
							done: order.claiming_done,
							order,
						});
					}
				}
			} else {
				// Add delivery
				tasks.push({
					id: `${order.id}-0`,
					type: 0,
					date: order.delivery_date,
					taken: order.delivery_taken,
					taken_by: order.delivery_by,
					done: order.delivery_done,
					order,
				});

				// If delivered and has claiming date (and not replacement) add claiming
				if (order.delivery_done && order.claiming_date) {
					tasks.push({
						id: `${order.id}-2`,
						type: 1,
						date: order.claiming_date,
						taken: order.claiming_taken,
						taken_by: order.claiming_by,
						done: order.claiming_done,
						order,
					});
				}
			}
		}

		for (const t of tasks) {
			if (t.taken_by == null) {
				continue;
			}

			if (t.taken_by.id == id) {
				if (isBetween(t.date, start, end)) {
					events.push({
						_type: t.type,
						...t.order,
					});
				}
			}
		}

		for (const e of expenses) {
			events.push({
				_type: 2,
				...e,
			});
		}

		for (const s of settlements) {
			events.push({
				_type: 3,
				...s,
			});
		}

		let payments = 0;
		for (const event of events) {
			if (event.payment_type !== 1) {
				continue;
			}

			// Replacement
			if (event._type === -1) {
				if (event.payment === event.delivery_done) {
					payments += event.price;
				}

				continue;
			}

			// Delivery
			if (event._type === 0) {
				if (event.payment === event.delivery_done) {
					payments += event.price;
				}

				continue;
			}

			// Claiming
			if (event._type === 1) {
				if (event.payment === event.claiming_done) {
					payments += event.price;
				}

				continue;
			}
		}

		return {
			events,
			card: cards[0],
			advances: events.filter((e) => e._type === 0 && e.advance).reduce((acc, event) => acc + event.advance, 0),
			payments,
			expenses: events.filter((e) => e._type === 2).reduce((acc, event) => acc + event.value, 0),
			settlements: events.filter((e) => e._type === 3).reduce((acc, event) => acc + event.value, 0),
		};
	};

	const fetch = async () => {
		try {
			history.replace(`?offset=${offset}`);

			const start = moment().add(offset, 'days').startOf('day').unix() * 1000;
			const end = moment().add(offset, 'days').endOf('day').unix() * 1000;

			const user = await API.users.get(id);

			const { events, card, advances, payments, expenses, settlements } = await fetchCard(start, end);

			setUser(user);
			setEvents(events);
			setCard(card ?? {});
			setStats({ advances, payments, expenses, settlements });
		} catch (e) {
			message.error(`${e}`);
			console.trace(e);
		}
	};

	const setMileageEnd = (e) =>
		setCard((card) => {
			if (card.trip) {
				card.mileageStart = e.target.value - card.trip;
			}

			card.mileageEnd = e.target.value;

			return { ...card };
		});

	const setTrip = (e) =>
		setCard((card) => {
			if (card.mileageEnd) {
				card.mileageStart = card.mileageEnd - e.target.value;
			}

			card.trip = e.target.value;

			return { ...card };
		});

	const closingDisabled = useMemo(() => {
		if (isNaN(card.mileageStart)) return true;
		if (isNaN(card.mileageEnd)) return true;
		if (isNaN(card.trip)) return true;
		if (!card.time) return true;
		if (!card.plate) return true;

		return false;
	}, [card]);

	const closeCard = async () => {
		if (loading) return;

		try {
			setLoading('card');

			const date = moment().add(offset, 'days').startOf('day').unix() * 1000;

			await API.users.cards.put({
				mileage_start: card.mileageStart,
				mileage_end: card.mileageEnd,
				time: card.time,
				plate: card.plate,
				date,
			});
			await fetch();

			setLoading();
		} catch (e) {
			setLoading();
			message.error(`${e}`);
		}
	};

	const generatePrints = async () => {
		if (loading) return;

		try {
			setLoading('prints');

			const cards = [];

			for (let date = printDates.from.clone(); date <= printDates.to.clone(); date.add(1, 'days')) {
				const start = date.clone().startOf('day').unix() * 1000;
				const end = date.clone().endOf('day').unix() * 1000;
				const { events, card, advances, payments, expenses, settlements } = await fetchCard(start, end);

				if (events.length === 0) continue;

				cards.push(
					<>
						<h3>{Formatting.epochToDate(start, true)}</h3>
						<CardPrint
							key={start}
							user={user}
							events={events}
							card={card ?? {}}
							summary={{ advances, payments, expenses, settlements }}
							date={start}
						/>
					</>
				);
			}

			setPrints(cards);
			setLoading();
		} catch (e) {
			setLoading();
			message.error(`${e}`);
		}
	};

	return [
		{
			offset,
			user,
			events,
			card,
			loading,
			closingDisabled,
			printDates,
			prints,
			...stats,
		},
		{
			setOffset,
			setMileageEnd,
			setTrip,
			setTime: (e) => setCard((card) => ({ ...card, time: e.target.value })),
			setPlate: (e) => setCard((card) => ({ ...card, plate: e.target.value })),
			closeCard,
			setPrintDateFrom: (date) => setPrintDates((dates) => ({ ...dates, from: date })),
			setPrintDateTo: (date) => setPrintDates((dates) => ({ ...dates, to: date })),
			generatePrints,
			removePrints: () => setPrints(),
		},
	];
}
