import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';
import { Query } from '../utils';
import API from '../API';
import moment from 'moment';

export default function () {
	const history = useHistory();

	const [clients, setClients] = useState();
	const [orders, setOrders] = useState();
	const [from, setFrom] = useState(moment().startOf('month'));
	const [to, setTo] = useState(moment().endOf('month'));
	const [client, setClient] = useState();
	const [paid, setPaid] = useState();
	const [type, setType] = useState();

	const { unclosed = '0' } = Query.decode(history.location.search);

	const fetch = async () => {
		try {
			setOrders();

			const data = (
				await API.orders.getAll({
					after: Math.max(from.hours(0).minutes(0).seconds(0).unix() * 1000, 0),
					before: to.hours(23).minutes(59).seconds(59).unix() * 1000,
				})
			)
				.filter((o) => o.delivery_done)
				.map((order) => ({
					...order,
					key: order.id,
					wasReplaced: order.related_id != undefined && order.related_id > order.id,
				}));

			setClients(await API.clients.getAll());
			setOrders(data);
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		fetch();
	}, [from.unix(), to.unix()]);

	const clearFilters = () => {
		setFrom(moment().startOf('month'));
		setTo(moment().endOf('month'));
		setClient('');
		setPaid();
		setType();
	};

	const filtered = useMemo(() => {
		if (!orders) return orders;

		const o = orders.filter((o) => {
			if (unclosed !== '1' && !o.claiming_done) return false;

			if (client && !o.client.name.toLowerCase().includes(client.toLowerCase())) return false;

			if (paid !== undefined) {
				if (paid && !o.payment) return false;
				if (!paid && o.payment) return false;
			}

			if (type !== undefined && o.payment_type !== type) return false;

			if (new Date(o.delivery_done) >= from && new Date(o.delivery_done) <= to) return true;
			if (new Date(o.claiming_done) >= from && new Date(o.claiming_done) <= to) return true;

			return false;
		});

		if (unclosed === '1') {
			return o.sort((a, b) => new Date(a.delivery_taken) - new Date(b.delivery_taken));
		} else {
			return o.sort((a, b) => new Date(a.claiming_taken) - new Date(b.claiming_taken));
		}
	}, [orders, unclosed, client, paid, type]);

	return [
		{ clients, orders: filtered, unclosed: unclosed === '1', from, to, client, paid, type },
		{
			toggleUnclosed: () => history.replace(`?unclosed=${unclosed === '1' ? 0 : 1}`),
			fromChanged: setFrom,
			toChanged: setTo,
			clientChanged: setClient,
			paidChanged: setPaid,
			typeChanged: setType,
			clearFilters,
		},
	];
}
