import React from 'react';
import { AutoComplete, Button, Checkbox, DatePicker, Input, Select, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Page, PageSection } from '../../components';
import { useNewExpense } from '../../reducers';
import moment from 'moment';

import '../../styles/pages/expenses/newExpense.scss';

const { Option } = Select;

const NewExpense = () => {
	const [
		{ expense, isFuelExpense, disabled, loading, types, type, admin, superAdmin },
		{
			nameChanged,
			typeChanged,
			carChanged,
			mileageChanged,
			fuelLitersChanged,
			valueChanged,
			dateChanged,
			valueTypeChanged,
			taxChanged,
			invoiceChanged,
			companyChanged,
			createExpense,
		},
	] = useNewExpense();

	const actions = [
		<Button
			key={0}
			type="primary"
			size="large"
			icon={<PlusCircleOutlined />}
			disabled={disabled}
			loading={loading}
			onClick={createExpense}
		>
			Dodaj wydatek
		</Button>,
	];

	return (
		<Page title="Wydatki / Nowy" actions={actions}>
			{types && (
				<>
					<div className="new-expense flex">
						<PageSection title="Informacje" width={902}>
							<Space size={25}>
								<div>
									<label>Typ</label>
									<AutoComplete
										size="large"
										options={types.map((option) => ({ value: option.name }))}
										filterOption={(inputValue, option) =>
											option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
										}
										onSelect={typeChanged}
										onChange={typeChanged}
										value={type}
									/>
								</div>
								<div>
									<label>Nazwa</label>
									<Input type="text" size="large" value={expense.name} onChange={nameChanged} disabled={expense.type} />
								</div>
							</Space>

							<Space size={25}>
								<div>
									<label>Kwota</label>
									<div className="pln flex flex-ac">
										<Input type="number" size="large" value={expense.value} onChange={valueChanged} />
										<span>PLN</span>
									</div>
								</div>
								<div>
									<label>Numer FV</label>
									<Input type="text" size="large" value={expense.invoice} onChange={invoiceChanged} />
								</div>
							</Space>

							<Space size={25}>
								<div>
									<label>Data</label>
									<DatePicker
										size="large"
										value={expense.date}
										onChange={dateChanged}
										allowClear={false}
										disabledDate={(date) => {
											if (superAdmin) {
												return false;
											}

											const minDate = moment().startOf('day').subtract(1, 'day');
											if (date < minDate) {
												return true;
											}

											const maxDate = moment().endOf('day');
											if (date > maxDate) {
												return true;
											}

											return false;
										}}
									/>
								</div>
								<Space size={10} className="flex">
									<div>
										<label>Netto / Brutto</label>
										<Select size="large" value={expense.value_type} onChange={valueTypeChanged}>
											<Option value={0}>Netto</Option>
											<Option value={1}>Brutto</Option>
										</Select>
									</div>
									<div>
										<label>Stopa podatku</label>
										<Select size="large" value={expense.tax} onChange={taxChanged}>
											<Option value={0}>0%</Option>
											<Option value={8}>8%</Option>
											<Option value={23}>23%</Option>
										</Select>
									</div>
								</Space>
							</Space>

							<Space size={25}>
								<div>
									<label>Numer rejestracyjny pojazdu</label>
									<Input type="text" size="large" value={expense.car} onChange={carChanged} />
								</div>
								<Space size={10} className="flex">
									<div>
										<label>Przebieg</label>
										<Input type="number" size="large" value={expense.mileage} onChange={mileageChanged} />
									</div>
									{isFuelExpense && (
										<div>
											<label>Litry paliwa</label>
											<Input type="number" size="large" value={expense.fuel_liters} onChange={fuelLitersChanged} />
										</div>
									)}
								</Space>
							</Space>

							{admin && (
								<Space size={25}>
									<div>
										<label>&nbsp;</label>
										<Checkbox checked={expense.company} onChange={companyChanged}>
											Wydatek firmowy
										</Checkbox>
									</div>
									<div></div>
								</Space>
							)}
						</PageSection>
					</div>
				</>
			)}
		</Page>
	);
};

export default NewExpense;
