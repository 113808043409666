import React from 'react';
import { AutoComplete, Button, DatePicker, Input, Select, Skeleton, Space } from 'antd';
import { Page, PageSection } from '../../../../components';
import useNewOwn from './reducers/useNewOwn';

import '../delivery/styles/newOwn.scss';
import { PlusCircleOutlined } from '@ant-design/icons';

const NewOwn = ({ select }) => {
	const [
		{ clients, contractors, own, loading, disabled },
		{ clientChanged, addressChanged, toChanged, dateChanged, timeChanged, addOwn },
	] = useNewOwn();

	const actions = [
		<Button
			key="0"
			type="primary"
			size="large"
			icon={<PlusCircleOutlined />}
			onClick={addOwn}
			disabled={disabled}
			loading={loading}
		>
			Dodaj zamówienie
		</Button>,
	];

	return (
		<Page title="Zamówienia / Nowe" actions={actions}>
			{clients && contractors ? (
				<PageSection title="Informacje" select={select}>
					<div className="new-own flex flex-dir">
						<Space size={25}>
							<div className="flex flex-dir">
								<label>Klient</label>
								<AutoComplete
									size="large"
									placeholder="Klient"
									options={clients.map((d) => ({ value: d.name }))}
									filterOption={(inputValue, option) => {
										const client = clients.filter((client) => client.name === option.value)[0];
										if (!client) return false;

										if (`${client.name}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.phone}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.nip}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.bdo}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.email}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
									}}
									value={own.client}
									onSelect={clientChanged}
									onChange={clientChanged}
								/>
							</div>
							<div className="flex flex-dir">
								<label>Adres</label>
								<Input type="text" size="large" value={own.address} onChange={addressChanged} />
							</div>
						</Space>
						<Space size={25}>
							<div className="flex flex-dir">
								<label>Do</label>
								<AutoComplete
									size="large"
									options={contractors.map((c) => ({ value: c }))}
									filterOption={(inputValue, option) =>
										option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
									}
									value={own.to}
									onSelect={toChanged}
									onChange={toChanged}
								/>
							</div>
							<div className="flex flex-dir"></div>
						</Space>
						<Space size={25}>
							<div className="flex flex-dir">
								<DatePicker size="large" placeholder="Wybierz datę" value={own.date} onChange={dateChanged} />
							</div>
							<div className="flex flex-dir">
								<DatePicker.TimePicker
									size="large"
									format="HH:mm"
									placeholder="Wybierz godzinę"
									value={own.time}
									onChange={timeChanged}
								/>
							</div>
						</Space>
					</div>
				</PageSection>
			) : (
				<Skeleton active />
			)}
		</Page>
	);
};

export default NewOwn;
