import React, { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from 'antd';
import { CheckCircleOutlined, ClearOutlined } from '@ant-design/icons';
import { Page } from '../../components';
import SignContext from './signContext';

import '../../styles/pages/tasks/sign.scss';

const Sign = ({}) => {
	const history = useHistory();
	const canvas = useRef();

	const sign = history.location.state;

	const [_, { setSign }] = useContext(SignContext);

	useEffect(() => {
		if (!canvas?.current || !sign) return;

		canvas.current.fromData(sign);
	}, [sign, canvas?.current]);

	const saveSign = () => {
		if (!canvas?.current) return;

		setSign(canvas.current.toData());

		history.go(-1);
	};

	const clear = () => canvas.current.clear();

	const actions = [
		<Button key="0" type="primary" size="large" icon={<CheckCircleOutlined />} onClick={saveSign}>
			Zatwierdź podpis
		</Button>,
		<Button key="1" type="primary" size="large" icon={<ClearOutlined />} onClick={clear}>
			Wyczyść
		</Button>,
	];

	return (
		<Page title="Terminarz / Realizacja zadania" actions={actions}>
			<div className="sign-wrapper">
				<SignatureCanvas ref={canvas} penColor="black" canvasProps={{ className: 'sigCanvas' }} />
			</div>
		</Page>
	);
};

export default Sign;
