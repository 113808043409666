import React, { useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Button, Input, Select, Space, Timeline } from 'antd';
import {
	CheckCircleOutlined,
	CheckOutlined,
	ClockCircleOutlined,
	DeleteOutlined,
	DollarCircleOutlined,
	EyeOutlined,
	PlusCircleOutlined,
	PrinterOutlined,
	UndoOutlined,
} from '@ant-design/icons';
import { blue, green, grey, red } from '@ant-design/colors';
import { Page, PageSection, WarningModal } from '../../components';
import { useClientDetails } from '../../reducers';
import { ClientOrdersPrint } from '../../prints';
import { Formatting } from '../../utils';
import moment from 'moment';
import SearchSelector from '../../components/searchSelector';
import styled from 'styled-components';

import '../../styles/pages/clients/clientDetails.scss';

const { Option } = Select;
const { TextArea } = Input;

const OrdersOptionsWrapper = styled.div`
	flex: 1;
	margin-left: 30px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: end;
	column-gap: 20px;

	> p {
		margin: 0;
	}

	> .ant-select {
		flex: 1;
		max-width: 300px;
	}
`;

const ClientDetails = ({ match }) => {
	const history = useHistory();

	const [deleteModal, setDeleteModal] = useState(false);

	const [
		{ client, orders, address, addresses, timeline, unpaid, removed, saveDisabled, saving, loading },
		{
			onAddressChange,
			nameChanged,
			phoneChanged,
			typeChanged,
			emailChanged,
			nipChanged,
			bdoChanged,
			descriptionChanged,
			saveChanges,
			deleteClient,
			restoreClient,
			streetChanged,
			cityChanged,
			postalChanged,
			toggleUnpaid,
			payOrder,
		},
	] = useClientDetails(match);

	const actions = removed
		? [
				<Button key={0} type="primary" size="large" icon={<UndoOutlined />} loading={loading} onClick={restoreClient}>
					Przywróć klienta
				</Button>,
		  ]
		: [
				<Button
					key={0}
					type="primary"
					size="large"
					icon={<CheckOutlined />}
					onClick={saveChanges}
					loading={saving}
					disabled={saveDisabled}
				>
					Zapisz zmiany
				</Button>,
				<Button key={1} type="danger" size="large" icon={<DeleteOutlined />} onClick={() => setDeleteModal(true)}>
					Usuń klienta
				</Button>,
				<Button
					key={2}
					type="primary"
					size="large"
					icon={<PlusCircleOutlined />}
					onClick={() => history.push(`/panel/new-order?client=${client?.id}`)}
				>
					Dodaj zamówienie na klienta
				</Button>,
		  ];

	const getTimelineEvent = (event, i) => {
		const icon = event.claiming_done ? (
			<CheckCircleOutlined style={{ color: blue[5] }} />
		) : (
			<ClockCircleOutlined style={{ color: blue[5] }} />
		);

		const date = (
			<>
				{Formatting.epochToDate(event.delivery_date, moment(event.delivery_date).hours() === 0)}
				{event.claiming_date && ' - '}
				{event.claiming_date && Formatting.epochToDate(event.claiming_date, moment(event.claiming_date).hours() === 0)}
			</>
		);

		const title = `${event.address} - nr: ${event.container?.name ?? 'brak'}`;

		return (
			<Timeline.Item key={`${i}`} dot={icon}>
				<span>{date}</span> - {title}. {Formatting.money(event.price)} {event.price_type ? 'Brutto' : 'Netto'}.{' '}
				{!event.payment && (
					<a style={{ color: loading === 'paid' ? grey[5] : green[5] }} onClick={() => payOrder(event.id)}>
						Oznacz jako zapłacone
					</a>
				)}{' '}
				{<Link to={`/panel/orders/${event.id}`}>Szczegóły</Link>}
			</Timeline.Item>
		);
	};

	const [summary, advances] = useMemo(() => {
		if (!orders) return [];

		const prices = [0, 0, 0];
		const advances = [];

		for (const order of orders) {
			if (order.advance) {
				advances.push(order);
			}

			if (!order.payment) {
				prices[1] += order.price;

				if (order.advance) {
					prices[2] += order.advance;
				}
			}

			prices[0] += order.price;
		}

		const sum = Formatting.money(prices[0]);
		const unpaid = Formatting.money(prices[1] - prices[2]);

		return [
			<>
				{sum} <span className="red">({unpaid})</span>
			</>,
			advances,
		];
	}, [orders]);

	const printRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});
	const print = () => {
		handlePrint();
		// setWarning();
	};

	return (
		<>
			{client && orders && (
				<Page title={`Klienci / ${client?.name}`} actions={actions}>
					<div className="client-details">
						<div className="flex">
							<PageSection title="Informacje" width={902}>
								<Space size={25} className="inputs">
									<div>
										<label>Nazwa</label>
										<Input
											type="text"
											size="large"
											defaultValue={client.name}
											onChange={nameChanged}
											disabled={removed}
										/>
									</div>
									<div>
										<label>Typ</label>
										<Select
											size="large"
											labelInValue
											defaultValue={{ value: client.type }}
											onChange={typeChanged}
											disabled={removed}
										>
											<Option value={0}>Firma</Option>
											<Option value={1}>Osoba prywatna</Option>
										</Select>
									</div>
								</Space>
								<Space size={25} className="inputs">
									<div>
										<label>Numer telefonu</label>
										<Input
											type="text"
											size="large"
											defaultValue={client.phone}
											onChange={phoneChanged}
											disabled={removed}
										/>
									</div>
									<div>
										<label>Email</label>
										<Input
											type="text"
											size="large"
											defaultValue={client.email}
											onChange={emailChanged}
											disabled={removed}
										/>
									</div>
								</Space>
								<Space size={25} className="inputs">
									<div>
										<label>Numer NIP</label>
										<Input
											type="text"
											size="large"
											defaultValue={client.nip}
											onChange={nipChanged}
											disabled={removed}
										/>
									</div>
									<div>
										<label>Numer BDO</label>
										<Input
											type="text"
											size="large"
											defaultValue={client.bdo}
											onChange={bdoChanged}
											disabled={removed}
										/>
									</div>
								</Space>
								<Space size={25} className="inputs">
									<div>
										<label>Ulica</label>
										<Input
											type="text"
											size="large"
											defaultValue={client.street}
											onChange={streetChanged}
											disabled={removed}
										/>
									</div>
								</Space>
								<Space size={25} className="inputs">
									<div>
										<label>Miasto</label>
										<Input
											type="text"
											size="large"
											defaultValue={client.city}
											onChange={cityChanged}
											disabled={removed}
										/>
									</div>
									<div>
										<label>Kod pocztowy</label>
										<Input
											type="text"
											size="large"
											defaultValue={client.postal}
											onChange={postalChanged}
											disabled={removed}
										/>
									</div>
								</Space>
								<label>Opis</label>
								<TextArea rows={3} defaultValue={client.description} onChange={descriptionChanged} disabled={removed} />
								<Space size={25}>
									<table>
										<tbody>
											<tr>
												<td>
													Utworzony: <span className="user-data">{Formatting.epochToDate(client.created)}</span>{' '}
												</td>
											</tr>
											<tr>
												<td>
													Przez:
													<span className="user-data">
														<Link to={`/panel/drivers/${client.created_by.id}`}>
															{client.created_by.name} {client.created_by.surname}
														</Link>
													</span>
												</td>
											</tr>
										</tbody>
									</table>
									{removed && (
										<table>
											<tbody>
												<tr>
													<td>
														Usunięty: <span className="user-data">{Formatting.epochToDate(client.removed)}</span>{' '}
													</td>
												</tr>
												<tr>
													<td>
														Przez:
														<span className="user-data">
															<Link to={`/panel/drivers/${client.removed_by.id}`}>
																{client.removed_by.name} {client.removed_by.surname}
															</Link>
														</span>
													</td>
												</tr>
											</tbody>
										</table>
									)}
								</Space>
								<Space size={25} className="balance">
									<div className="flex flex-dir">
										<label>
											Łączna kwota zamówień <span className="red">(do opłacenia)</span>
										</label>
										<span>{summary}</span>
									</div>
								</Space>
							</PageSection>
							{advances?.length !== 0 && (
								<PageSection title="Zaliczki" width={878}>
									<Timeline>
										{advances?.map((order) => {
											const date = Formatting.epochToDate(
												order.delivery_done,
												moment(order.delivery_done).hours() === 0
											);

											return (
												<Timeline.Item key={order.id}>
													<span>{date}</span> - {order.address}. {Formatting.money(order.advance)}{' '}
													<Link to={`/panel/orders/${order.id}`}>Szczegóły</Link>
												</Timeline.Item>
											);
										})}
									</Timeline>
								</PageSection>
							)}
							<PageSection
								title="Zamówienia"
								width={878}
								select={
									<OrdersOptionsWrapper>
										<p>Adres:</p>
										<SearchSelector
											elements={[...addresses, null]}
											buildElement={(address) => address ?? 'Wszystkie'}
											value={address}
											onChange={onAddressChange}
											ignoreCaseAndTrim
										/>
										<Button type="primary" size="large" onClick={() => print()} icon={<PrinterOutlined />}>
											Drukuj wykaz
										</Button>
										<Button
											type="primary"
											size="large"
											onClick={toggleUnpaid}
											icon={unpaid === '0' ? <DollarCircleOutlined /> : <EyeOutlined />}
										>
											{unpaid === '0' ? 'Pokaż nieopłacone' : 'Pokaż wszystkie'}
										</Button>
									</OrdersOptionsWrapper>
								}
							>
								<Timeline>{timeline?.map(getTimelineEvent)}</Timeline>
							</PageSection>
						</div>
					</div>

					<ClientOrdersPrint
						ref={printRef}
						client={client}
						orders={orders.sort((a, b) => new Date(a.delivery_date) - new Date(b.delivery_date))}
					/>

					<WarningModal
						visible={deleteModal}
						onCancel={() => setDeleteModal(false)}
						onOk={() => deleteClient(() => setDeleteModal(false))}
						loading={loading}
					>
						<h1>Czy na pewno chcesz usunąć klienta?</h1>
						<h3>Będzie go można później przywrócić</h3>
					</WarningModal>
				</Page>
			)}
		</>
	);
};

export default ClientDetails;
