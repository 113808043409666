import { useEffect, useState } from 'react';
import { message } from 'antd';
import API from '../../../../../API';

export default function (match) {
	const { id } = match.params;

	const [own, setOwn] = useState();
	const [weight, setWeight] = useState();
	const [loading, setLoading] = useState();
	const [dateModal, setDateModal] = useState();

	const fetch = async () => {
		try {
			setOwn(await API.orders.owns.get(id));
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const close = async () => {
		try {
			setLoading('closing');

			await API.orders.owns.patch(id, { weight });
			await fetch();

			setLoading();
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const editDate = async (date) => {
		if (loading) return;

		try {
			setLoading('date');

			await API.orders.owns.patch(id, { date: date.unix() * 1000 });
			await fetch();

			message.success('Zmieniono datę');
			setLoading();
			setDateModal();
		} catch (e) {
			message.error(`${e}`);
			setLoading();
		}
	};

	return [
		{ own, weight, dateModal, loading },
		{
			weightChanged: (e) => setWeight(e.target.value?.replace(/\,/g, '.')),
			setDateModal,
			editDate,
			close,
		},
	];
}
