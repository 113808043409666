import React from 'react';
import { Link } from 'react-router-dom';
import { Button, DatePicker, Input, Select, Skeleton, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { Page, PageSection } from '../../../../components';
import useOtherDetails from './reducers/useOtherDetails';

import '../delivery/styles/newOwn.scss';

const OtherDetails = ({ match }) => {
	const [
		{ other, changes, saveDisabled, loading },
		{ nameChanged, dateChanged, timeChanged, valueChanged, valueTypeChanged, invoiceChanged, save },
	] = useOtherDetails(match);

	const actions = [
		<Button
			key="0"
			type="primary"
			size="large"
			icon={<CheckOutlined />}
			onClick={save}
			disabled={saveDisabled}
			loading={loading === 'saving'}
		>
			Zapisz zmiany
		</Button>,
	];

	return (
		<Page title="Szczegóły zamówienia" actions={actions}>
			{!other ? (
				<Skeleton active />
			) : (
				<PageSection title="Informacje">
					<div className="new-own flex flex-dir">
						<Space size={25}>
							<div className="flex flex-dir">
								<label>Klient</label>
								<span className="user-data">
									<Link to={`/panel/clients/${other.client?.id}`}>{other.client?.name}</Link>
								</span>
							</div>
						</Space>
						<Space size={25}>
							<div className="flex flex-dir">
								<label>Nazwa</label>
								<Input type="text" size="large" value={changes.name ?? other.name} onChange={nameChanged} />
							</div>
						</Space>
						<Space size={25}>
							<div className="flex flex-dir">
								<label>Data</label>
								<DatePicker
									size="large"
									placeholder="Wybierz datę"
									value={changes.date ?? other.date}
									onChange={dateChanged}
								/>
							</div>
							<div className="flex flex-dir">
								<label>&nbsp;</label>
								<DatePicker.TimePicker
									size="large"
									format="HH:mm"
									placeholder="Wybierz godzinę"
									value={changes.time === undefined ? other.time : changes.time}
									onChange={timeChanged}
								/>
							</div>
						</Space>
						<Space size={25}>
							<Space size={10} className="flex">
								<div className="flex flex-dir">
									<label>Cena</label>
									<div className="flex flex-ac">
										<Input size="large" value={changes.value ?? other.value} onChange={valueChanged} />
										<span className="pln">PLN</span>
									</div>
								</div>
								<div className="flex flex-dir">
									<label>Netto / Brutto</label>
									<Select size="large" value={changes.value_type ?? other.value_type} onChange={valueTypeChanged}>
										<Option value={0}>Netto</Option>
										<Option value={1}>Brutto</Option>
									</Select>
								</div>
							</Space>
							<div className="flex flex-dir">
								<label>Faktura</label>
								<Input
									size="large"
									value={changes.invoice ?? other.invoice}
									onSelect={invoiceChanged}
									onChange={invoiceChanged}
								/>
							</div>
						</Space>
					</div>
				</PageSection>
			)}
		</Page>
	);
};

export default OtherDetails;
