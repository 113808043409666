import React, { useState } from 'react';
import { Button, Input, Select, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { InfoModal, Page, PageSection } from '../../components';
import { useNewContainer } from '../../reducers';

import '../../styles/pages/containers/newContainer.scss';

const { Option } = Select;

const NewContainer = () => {
	const [infoModal, setInfoModal] = useState(false);
	const [
		{ container, disabled, loading, sizes },
		{ nameChanged, sizeChanged, typeChanged, createContainer, addContainerSize },
	] = useNewContainer();
	const [size, setSize] = useState();

	const actions = [
		<Button
			key="1"
			type="primary"
			size="large"
			icon={<PlusCircleOutlined />}
			disabled={disabled}
			loading={loading}
			onClick={createContainer}
		>
			Dodaj kontener
		</Button>,
	];

	return (
		<Page title="Kontenery / Nowy" actions={actions}>
			<div className="new-container flex">
				<PageSection title="Informacje" width={878}>
					<div className="flex">
						<Space size={19}>
							<div className="flex flex-dir">
								<label>Numer</label>
								<Input type="text" size="large" value={container.name} onChange={nameChanged} />
							</div>
							<div className="flex flex-dir">
								<label>Rozmiar</label>
								<Select size="large" value={container.size} onChange={sizeChanged}>
									{sizes?.map((size) => (
										<Option key={size.name} value={size.id}>
											{size.name}
										</Option>
									))}
								</Select>
							</div>
						</Space>

						<Button
							className="flex flex-ac"
							type="primary"
							size="large"
							shape="circle"
							icon={<PlusCircleOutlined />}
							onClick={() => setInfoModal(true)}
						></Button>
					</div>
					<Space size={19}>
						<div className="flex flex-dir">
							<label>Rodzaj</label>
							<Input type="text" size="large" value={container.type} onChange={typeChanged} />
						</div>
						<div></div>
					</Space>
				</PageSection>
			</div>
			<InfoModal
				width={560}
				visible={infoModal}
				closable={false}
				cancelText="Anuluj"
				okText="Dodaj"
				onCancel={() => setInfoModal(false)}
				onOk={() => {
					addContainerSize(size);
					setInfoModal(false);
				}}
				icon="plus"
			>
				<h1>Podaj nowy rozmiar</h1>
				<Input type="text" size="large" placeholder="Nazwa" value={size} onChange={(e) => setSize(e.target.value)} />
			</InfoModal>
		</Page>
	);
};

export default NewContainer;
