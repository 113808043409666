import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Query } from '../../utils';
import API from '../../API';
import moment from 'moment';

export default function () {
	const history = useHistory();
	const closed = Query.decode(history.location.search).closed === '1';
	const admin = window.sessionStorage.getItem('level') > 0;

	const [orders, setOrders] = useState();
	const [filters, setFilters] = useState({});
	const [from, setFrom] = useState(moment().startOf('month'));
	const [to, setTo] = useState(moment().endOf('month'));

	const fetch = async () => {
		try {
			const filters = {
				after: Math.max(from.hours(0).minutes(0).seconds(0).unix() * 1000, 0),
				before: to.hours(23).minutes(59).seconds(59).unix() * 1000,
			};

			if (closed) filters.closed = '1';

			const data = (await API.orders.getAll(filters)).map((order) => ({
				...order,
				key: order.id,
			}));

			setOrders(data);
		} catch (e) {
			console.trace(e);
		}
	};

	useEffect(() => {
		fetch();
	}, [closed, from, to]);

	const filtered = useMemo(() => {
		if (!orders) return;

		if (!filters?.search) return orders;

		return orders.filter((o) => {
			if (filters.search) {
				if (`${o.client.name}`.toLowerCase().includes(filters.search.toLowerCase())) return true;
				if (`${o.client.phone}`.toLowerCase().includes(filters.search.toLowerCase())) return true;
				if (`${o.contact}`.toLowerCase().includes(filters.search.toLowerCase())) return true;
				if (`${o.address}`.toLowerCase().includes(filters.search.toLowerCase())) return true;
				if (`${o.waste_type}`.toLowerCase().includes(filters.search.toLowerCase())) return true;
			}

			return false;
		});
	}, [orders, filters]);

	return [
		{ orders: filtered, closed, filters, from, to, admin },
		{
			toggleClosed: () => history.replace(`?closed=${closed ? 0 : 1}`),
			searchChanged: (e) => setFilters({ ...filters, search: e.target.value }),
			clearFilters: () => setFilters({}),
			setFrom,
			setTo,
		},
	];
}
