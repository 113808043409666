import React, { createContext, useContext } from 'react';
import { Skeleton } from 'antd';
import { Calendar, Page, TaskCell } from '../../components';
import { useTasks } from '../../reducers';

import '../../styles/pages/tasks/tasks.scss';

const TasksContext = createContext();

const TasksBody = ({}) => {
	const [{ tasks, offset }, { setOffset }] = useContext(TasksContext);

	return (
		<div id="agenda">
			<Page title="Terminarz">
				{tasks ? (
					<div className="tasks flex flex-dir">
						<Calendar offset={offset} onChange={setOffset}>
							{tasks.length === 0 ? (
								<h2>Brak zadań na dziś</h2>
							) : (
								<div id="body">
									{tasks.map((task) => (
										<TaskCell key={task.id} i={tasks.indexOf(task)} task={task} />
									))}
								</div>
							)}
						</Calendar>
					</div>
				) : (
					<Skeleton active />
				)}
			</Page>
		</div>
	);
};

const Tasks = ({}) => {
	return (
		<TasksContext.Provider value={useTasks()}>
			<TasksBody />
		</TasksContext.Provider>
	);
};

export default Tasks;
