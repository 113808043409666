import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import API from '../../API';

export default function () {
	const history = useHistory();

	const [container, setContainer] = useState({});
	const [sizes, setSizes] = useState();
	const [loading, setLoading] = useState(false);
	const [adding, setAdding] = useState(false);

	const disabled = !container.name || !container.size;

	useEffect(() => {
		const fetch = async () => {
			const result = await API.containers.sizes.getAll();
			setSizes(result);
		};
		fetch();
	}, []);

	const createContainer = async () => {
		if (disabled) return setError('Podaj wszystkie dane');

		try {
			setLoading(true);
			const c = await API.containers.create(container);

			setLoading(false);
			message.success('Utworzono kontener');
			history.replace(`containers/${c.id}`);
		} catch (e) {
			setLoading(false);
			message.error(`${e}`);

			console.trace(e);
		}
	};

	const addContainerSize = async (size) => {
		try {
			setAdding(true);

			const result = await API.containers.sizes.create(size);

			setSizes([...sizes, result]);
			setContainer({ ...container, size: result.id });

			setAdding(false);
			message.success('Dodano rozmiar');
		} catch (e) {
			message.error(`${e}`);
			setAdding(false);
			console.trace(e);
		}
	};

	return [
		{
			container,
			disabled,
			loading,
			sizes,
			adding,
		},
		{
			nameChanged: (e) => setContainer({ ...container, name: e.target.value }),
			sizeChanged: (value) => setContainer({ ...container, size: value }),
			typeChanged: (e) => setContainer({ ...container, type: e.target.value }),
			createContainer,
			addContainerSize,
		},
	];
}
