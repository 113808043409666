import React from 'react';
import { Button, Input, Select, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Page, PageSection } from '../../components';
import { useNewClient } from '../../reducers';

import '../../styles/pages/clients/newClient.scss';

const { Option } = Select;

const NewClient = () => {
	const [
		{ client, disabled, loading, intent },
		{
			nameChanged,
			phoneChanged,
			typeChanged,
			emailChanged,
			nipChanged,
			bdoChanged,
			streetChanged,
			cityChanged,
			postalChanged,
			descriptionChanged,
			createClient,
		},
	] = useNewClient();

	const actions = [
		<Button
			key={0}
			type="primary"
			size="large"
			icon={<PlusCircleOutlined />}
			onClick={createClient}
			disabled={disabled}
			loading={loading}
		>
			Dodaj klienta{intent ? ' i wróć do zamowienia' : ''}
		</Button>,
	];

	return (
		<Page title={'Klienci / Nowy'} actions={actions}>
			<div className="new-client flex">
				<PageSection title="Informacje" width={902}>
					<Space size={25} className="inputs">
						<div>
							<label>Nazwa</label>
							<Input type="text" size="large" value={client.name} onChange={nameChanged} />
						</div>
						<div>
							<label>Typ</label>
							<Select size="large" labelInValue value={{ value: client.type }} onChange={typeChanged}>
								<Option key={0} value={0}>
									Firma
								</Option>
								<Option key={1} value={1}>
									Osoba prywatna
								</Option>
							</Select>
						</div>
					</Space>

					<Space size={25} className="inputs">
						<div>
							<label>Numer telefonu</label>
							<Input type="text" size="large" value={client.phone} onChange={phoneChanged} />
						</div>
						<div>
							<label>Email</label>
							<Input type="text" size="large" value={client.email} onChange={emailChanged} />
						</div>
					</Space>

					<Space size={25} className="inputs">
						<div>
							<label>Numer NIP</label>
							<Input type="text" size="large" value={client.nip} onChange={nipChanged} />
						</div>
						<div>
							<label>Numer BDO</label>
							<Input type="text" size="large" value={client.bdo} onChange={bdoChanged} />
						</div>
					</Space>
					<Space size={25} className="inputs">
						<div>
							<label>Ulica</label>
							<Input type="text" size="large" value={client.street} onChange={streetChanged} />
						</div>
					</Space>
					<Space size={25} className="inputs">
						<div>
							<label>Miasto</label>
							<Input type="text" size="large" value={client.city} onChange={cityChanged} />
						</div>
						<div>
							<label>Kod pocztowy</label>
							<Input type="text" size="large" value={client.postal} onChange={postalChanged} />
						</div>
					</Space>
					<label>Opis</label>
					<Input.TextArea rows={3} value={client.description} onChange={descriptionChanged} />
				</PageSection>
			</div>
		</Page>
	);
};

export default NewClient;
