import React from 'react';
import { Input, Space, Button } from 'antd';
import { EyeOutlined, LockOutlined } from '@ant-design/icons';
import usePasswordChange from '../reducers/usePasswordChange';

import '../styles/pages/login.scss';
import Blob from '../svg/blob.svg';
import Blob2 from '../svg/blob2.svg';
import StarBlob from '../svg/star-blob.svg';

const Login = () => {
	const [
		{ password, passwordRepeat, inputType, loading, error },
		{ passwordChanged, passwordRepeatChanged, newPassword, touchStart, touchMove, touchEnd },
	] = usePasswordChange();

	return (
		<>
			<div className="login-wrapper flex flex-ac">
				<Blob />
				<form onSubmit={newPassword} className="login flex flex-dir flex-ac">
					<h1>Witaj</h1>
					<h3>Wpisz nowe hasło</h3>

					<div className="h5 flex flex-jc">
						<h5>{error}</h5>
					</div>

					<Space size={25} direction={'vertical'}>
						<Input
							size="large"
							type={inputType}
							placeholder="Nowe hasło"
							prefix={<LockOutlined style={{ color: '#bfbfbf' }} />}
							suffix={
								<EyeOutlined
									onClick={(e) => e.preventDefault()}
									onMouseDown={touchStart}
									onMouseMove={touchMove}
									onMouseUp={touchEnd}
									onTouchStart={touchStart}
									onTouchMove={touchMove}
									onTouchEnd={touchEnd}
									style={{ color: '#8c8c8c' }}
								/>
							}
							value={password}
							onChange={passwordChanged}
						/>

						<Input
							size="large"
							type="password"
							placeholder="Powtórz nowe hasło"
							prefix={<LockOutlined style={{ color: '#bfbfbf' }} />}
							value={passwordRepeat}
							onChange={passwordRepeatChanged}
						/>
					</Space>

					<input type="submit" style={{ display: 'none' }} />

					<Button type="primary" size="large" loading={loading} onClick={newPassword}>
						Ustaw nowe hasło
					</Button>
				</form>
			</div>

			<StarBlob className="starblob" />
			<Blob2 className="blob2" />
		</>
	);
};

export default Login;
