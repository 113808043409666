import React, { useMemo } from 'react';
import { Formatting } from '../utils';
import styled from 'styled-components';
import moment from 'moment';

const Print = styled.div``;

const PrintTable = styled.table`
	width: 100%;
	page-break-inside: auto;

	tr {
		page-break-inside: avoid;
		page-break-after: auto;

		&:nth-child(2n) {
			background: #eee;
		}

		> td,
		> th {
			padding: 4px 8px;
			text-align: center;
		}

		> th {
			font-size: 14px;

			> div {
				display: flex;
				flex-direction: column;

				> h5 {
					font-weight: 600;
					margin-bottom: 4px;
				}

				> span {
					font-weight: 400;
					font-size: 8px;
				}
			}
		}

		> td {
			font-size: 10px;

			> div {
				display: flex;
				flex-direction: column;

				> h6 {
					font-weight: 600;
					font-size: 12px;
					margin-bottom: 2px;
				}
			}
		}
	}
`;

const PrintTitle = styled.h2`
	width: 100%;
	margin-top: 16px;
	margin-bottom: 8px;
	text-align: center;
	font-size: 18px;
`;

const PrintSubtitle = styled.h3`
	width: 100%;
	margin-top: 16px;
	margin-bottom: 32px;
	text-align: center;
	font-size: 14px;
`;

const DayTitle = styled(PrintSubtitle)`
	margin-top: 48px;
`;

const Summary = styled.h4`
	text-align: center;
	margin: 0;
	font-weight: 400;

	&:first-of-type {
		margin-top: 32px;
	}
`;

const ClientOrdersPrint = ({ client, orders }) => {
	if (!orders) return <div></div>;

	const days = orders.reduce((acc, order) => {
		const date = order.delivery_date;
		const dateString = moment(date).format('DD.MM.YYYY');

		if (!acc[dateString]) acc[dateString] = [];

		acc[dateString].push({
			...order,
			_date: date,
		});

		return acc;
	}, {});

	const sum = useMemo(() => {
		return orders.reduce(
			(acc, order) => {
				const index = order.payment ? 0 : 1;
				acc[index] += order.price;

				return acc;
			},
			[0, 0]
		);
	}, [orders]);

	let k = 0;

	return (
		<Print className="print">
			<PrintTitle>
				Wydruk zamówień dla klienta {client.name}
				{client.street && client.city && client.postal && ` - ${client.street}, ${client.city} ${client.postal}`}
			</PrintTitle>
			<PrintSubtitle>Stan z dnia {Formatting.epochToDate(Date.now())}</PrintSubtitle>
			{Object.entries(days).map(([day, orders], i) => (
				<>
					<DayTitle key={`${day}-0`}>{day}</DayTitle>
					<PrintTable key={`${day}-1`}>
						<tbody>
							<tr>
								<th>
									<div>
										<h5>LP</h5>
										<span>w grupie</span>
									</div>
								</th>
								<th>
									<div>
										<h5>Klient</h5>
										<span>Nazwa</span>
										<span>NIP</span>
										<span>Email</span>
									</div>
								</th>
								<th>
									<div>
										<h5>Cena</h5>
										<span>Brutto / Netto</span>
										<span>Typ płatności</span>
									</div>
								</th>
								<th>
									<div>
										<h5>Adres</h5>
										<span>BDO</span>
										<span>Rodzaj odpadów</span>
									</div>
								</th>
								<th>Faktura</th>
								<th>Data</th>
							</tr>
							{orders.map((order, j) => (
								<tr key={order.id}>
									<td>
										<div>
											<h6>{++k}</h6>
											<span>{j + 1}</span>
										</div>
									</td>
									<td>
										<div>
											<span>{order.client.name}</span>
											<span>{order.client.nip}</span>
											<span>{order.client.email}</span>
										</div>
									</td>
									<td>
										<div>
											<h6>{Formatting.money(order.price)}</h6>
											<span>{order.price_type ? 'Brutto' : 'Netto'}</span>
											<span>{order.payment_type ? 'Gotówka' : 'Przelew'}</span>
										</div>
									</td>
									<td>
										<div>
											<h6>{order.address}</h6>
											<span>{order.bdo ? 'Wytwarzający' : 'BDO'}</span>
											<span>{order.waste_type}</span>
										</div>
									</td>
									<td>{order.invoice ?? '-'}</td>
									<td>{Formatting.epochToDate(order._date, true)}</td>
								</tr>
							))}
						</tbody>
					</PrintTable>
				</>
			))}
			<Summary>Opłacone: {Formatting.money(sum[0])}</Summary>
			<Summary>Nieopłacone: {Formatting.money(sum[1])}</Summary>
			<Summary>Razem: {Formatting.money(sum[0] + sum[1])}</Summary>
		</Print>
	);
};

export default class extends React.PureComponent {
	render() {
		return <ClientOrdersPrint {...this.props} />;
	}
}
