import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import API from '../../API';
import { isEmail, Query } from '../../utils';

export default function useDriverDetails(match) {
	const history = useHistory();

	const [client, setClient] = useState();
	const [orders, setOrders] = useState();
	const [changes, setChanges] = useState({});

	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(false);

	const { id } = match.params;
	const { unpaid = '0', address: rawAddress } = Query.decode(history.location.search);

	const address = useMemo(() => {
		if (!rawAddress) {
			return null;
		}

		if (rawAddress == 'null') {
			return null;
		}

		return decodeURIComponent(rawAddress);
	});

	useEffect(() => {
		fetch();
	}, [match]);

	const fetch = async () => {
		try {
			setChanges();

			const result = await API.clients.get(id);

			setClient({
				...result,
				created_by: await API.users.get(result.created_by),
				removed_by: result.removed_by ? await API.users.get(result.removed_by) : {},
			});

			setOrders(await API.orders.getAll({ client_id: id }));
		} catch (e) {
			if (e === 3) history.replace('/404');
			message.error(`${e}`);
			console.trace(e);
		}
	};

	const checkChanges = () => {
		const diff = { ...changes };

		Object.entries(diff).forEach(([key, value]) => {
			if (value === client[key]) delete diff[key];
		});

		return Object.keys(diff).length === 0;
	};

	const saveChanges = async () => {
		if (changes.name === '') return message.error('Podaj nazwę');
		if (changes.phone === '') return message.error('Podaj telefon');
		if (changes.phone && !changes.phone.match(/^(\+[0-9]{2} ?)?([0-9] ?){9}$/))
			return message.error('Telefon jest niepoprawny');
		if (changes.email && !isEmail(changes.email)) return message.error('Email jest niepoprawny');
		// if (changes.nip && !isNip(changes.nip)) return message.error('NIP jest niepoprawny');
		if (changes.bdo?.length > 14) return message.error('BDO jest za długi');

		try {
			setSaving(true);
			changes.type && (changes.type = changes.type.value);
			await API.clients.patch(id, changes);
			setSaving(false);

			message.success('Zapisano zmiany');
			history.replace(`${id}`);
		} catch (e) {
			message.error(`${e}`);
			setSaving(false);
			console.trace(e);
		}
	};

	const deleteClient = async (close) => {
		try {
			setLoading(true);

			await API.clients.delete(id);
			close();
			setLoading(false);

			message.success('Usunięto klienta');
			history.replace(`${id}`);
		} catch (e) {
			setLoading(false);
			close();
			message.error(`${e}`);
			console.trace(e);
		}
	};

	const restoreClient = async () => {
		try {
			setLoading(true);

			await API.clients.patch(id, { removed: null });

			setLoading(false);
			message.success('Przywrócono klienta');
			history.replace(`${id}`);
		} catch (e) {
			message.error(`${e}`);
			setLoading(false);
			console.trace(e);
		}
	};

	const payOrder = async (id) => {
		if (loading) return;

		try {
			setLoading('paid');

			await API.orders.patch(id, { payment: 1 });
			await fetch();

			setLoading();
			message.success('Oznaczono jako zapłacone');
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const filtered = useMemo(() => {
		if (!orders) return;

		const o = orders.sort(
			(a, b) => new Date(b.claiming_done ?? b.delivery_date) - new Date(a.claiming_done ?? a.delivery_date)
		);

		const searchAddress = address?.trim().toLowerCase();
		return o.filter((order) => {
			if (unpaid !== '0' && order.payment) {
				// We want only unpaid orders.
				return false;
			}

			if (searchAddress) {
				const orderAddress = order.address.trim().toLowerCase();
				if (orderAddress !== searchAddress) {
					return false;
				}
			}

			return true;
		});
	}, [orders, unpaid, address]);

	const addresses = useMemo(() => {
		if (!orders) return [];

		const addresses = {};
		for (const order of orders) {
			const key = order.address.trim().toLowerCase();
			if (addresses[key]) {
				continue;
			}

			addresses[key] = order.address;
		}

		return [...Object.values(addresses)];
	}, [orders]);

	const onAddressChange = (address) => {
		const query = Query.encode({
			unpaid: unpaid,
			address: address,
		});

		history.replace(`?${query}`);
	};

	return [
		{
			client,
			orders,
			address,
			addresses,
			timeline: filtered,
			unpaid,
			removed: client?.removed !== null,
			saveDisabled: checkChanges(),
			saving,
			loading,
		},
		{
			onAddressChange,
			nameChanged: (e) => setChanges((changes) => ({ ...changes, name: e.target.value })),
			phoneChanged: (e) => setChanges((changes) => ({ ...changes, phone: e.target.value })),
			typeChanged: (value) => setChanges((changes) => ({ ...changes, type: value })),
			emailChanged: (e) => setChanges((changes) => ({ ...changes, email: e.target.value })),
			nipChanged: (e) => setChanges((changes) => ({ ...changes, nip: e.target.value })),
			bdoChanged: (e) => setChanges((changes) => ({ ...changes, bdo: e.target.value })),
			streetChanged: (e) => setChanges((changes) => ({ ...changes, street: e.target.value })),
			cityChanged: (e) => setChanges((changes) => ({ ...changes, city: e.target.value })),
			postalChanged: (e) => setChanges((changes) => ({ ...changes, postal: e.target.value })),
			descriptionChanged: (e) => setChanges((changes) => ({ ...changes, description: e.target.value })),
			saveChanges,
			deleteClient,
			restoreClient,
			toggleUnpaid: () => history.replace(`?unpaid=${unpaid === '0' ? '1' : '0'}`),
			payOrder,
		},
	];
}
