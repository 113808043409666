const Formatting = {
	insertSpaces: (v) => v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0'),
	money: (v, suffix = 'zł') => Formatting.insertSpaces(parseFloat(v).toFixed(2).replace(/\./g, ',')) + '\u00A0' + suffix,
	epochToDate: (epoch, noTime, chart) => {
		const d = new Date(epoch);

		const day = d.getDate().toString().padStart(2, '0');
		const month = (d.getMonth() + 1).toString().padStart(2, '0');
		const year = d.getFullYear();

		const hour = d.getHours().toString().padStart(2, '0');
		const minute = d.getMinutes().toString().padStart(2, '0');
		const second = d.getSeconds().toString().padStart(2, '0');

		return chart
			? `${year}-${month}-${day}` + (noTime === true ? '' : ` ${hour}:${minute}:${second}`)
			: `${day}.${month}.${year}` + (noTime === true ? '' : ` ${hour}:${minute}:${second}`);
	},
};

export default Formatting;
