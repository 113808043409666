import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons';
import '../../styles/components/page.scss';

const Page = ({ title, children, actions, bottomTitle, number, footer }) => {
	const history = useHistory();
	return (
		<div className="wrapper">
			<div className="page flex flex-dir">
				<div className="header flex flex-ac">
					<div className="back flex flex-jc flex-ac" onClick={() => history.go(-1)}>
						<ArrowLeftOutlined />
					</div>
					<h1>{title}</h1>
					<div className="home flex flex-jc flex-ac" onClick={() => history.push('/panel')}>
						<HomeOutlined />
					</div>
				</div>
				<div className="content flex flex-dir">
					{children}
					{(actions || bottomTitle || number || footer) && (
						<div className="footer flex flex-jsb flex-jc">
							{footer ?? (
								<>
									<div>{actions}</div>
									{(bottomTitle || number) && (
										<div>
											<span>{bottomTitle}</span>
											<span>{number}</span>
										</div>
									)}
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Page;
