import React from 'react';
import { Button, Input, Skeleton, Space, Table } from 'antd';
import { CheckOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { grey, red } from '@ant-design/colors';
import { Page, PageSection } from '../components';
import { useSettings } from '../reducers';

import '.././styles/pages/settings.scss';

const Settings = () => {
	const [
		{ sizes, newUsername, newPassword, repeatPassword, oldPassword, newSize, loading, admin, user },
		{
			setNewUsername,
			setNewPassword,
			setRepeatPassword,
			setOldPassword,
			setNewSize,
			changeLogin,
			changePassword,
			deleteSize,
			addSize,
		},
	] = useSettings();

	const columns = [
		{
			title: 'Rozmiar',
			dataIndex: 'name',
			key: 'name',
		},

		{
			title: 'Akcje',
			dataIndex: 'actions',
			key: 'actions',
			render: (text, row) => (
				<a style={{ color: loading === row.id ? grey[5] : red[5] }} onClick={() => deleteSize(row.id)}>
					Usuń
				</a>
			),
		},
	];

	return (
		<Page title="Ustawienia">
			{loading !== null ? (
				<div className="settings flex flex-1">
					<PageSection title="Informacje" width={878}>
						<Space size={25}>
							<div>
								<label>Login</label>
								<Input type="text" size="large" value={newUsername} onChange={setNewUsername} />
							</div>
							<div>
								<Button
									type="primary"
									size="large"
									icon={<CheckOutlined />}
									onClick={changeLogin}
									disabled={!newUsername || newUsername === user?.username}
									loading={loading === 'username'}
								>
									Zmień login
								</Button>
							</div>
						</Space>
						<PageSection title="Zmień hasło" width={878}>
							<Space size={25}>
								<div>
									<label>Nowe hasło</label>
									<Input type="password" size="large" value={newPassword} onChange={setNewPassword} />
								</div>
								<div>
									<label>Powtórz hasło</label>
									<Input type="password" size="large" value={repeatPassword} onChange={setRepeatPassword} />
								</div>
							</Space>
							<Space size={25}>
								<div>
									<label>Aktualne hasło</label>
									<Input type="password" size="large" value={oldPassword} onChange={setOldPassword} />
								</div>
								<div>
									<Button
										type="primary"
										size="large"
										icon={<CheckOutlined />}
										onClick={changePassword}
										disabled={!newPassword || !repeatPassword || !oldPassword}
										loading={loading === 'password'}
									>
										Zmień hasło
									</Button>
								</div>
							</Space>
						</PageSection>

						<PageSection title={admin && 'Rozmiary kontenerów'} width={852}>
							{admin && (
								<>
									<Table columns={columns} dataSource={sizes} pagination={false} />
									<Space size={25}>
										<div>
											<label>Nazwa rozmiaru</label>
											<Input type="text" size="large" value={newSize} onChange={setNewSize} />
										</div>
										<div>
											<Button
												type="primary"
												size="large"
												icon={<PlusCircleOutlined />}
												onClick={addSize}
												disabled={!newSize}
												loading={loading === 'size'}
											>
												Dodaj nowy rozmiar
											</Button>
										</div>
									</Space>
								</>
							)}
						</PageSection>
					</PageSection>
				</div>
			) : (
				<Skeleton active />
			)}
		</Page>
	);
};

export default Settings;
