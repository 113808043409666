import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { Query } from '../../../../../utils';
import API from '../../../../../API';
import moment from 'moment';

const newClient = {};

export default function useNewDelivery() {
	const history = useHistory();

	const { related_id, client: clientId } = Query.decode(history.location.search);

	const [clients, setClients] = useState();
	const [sizes, setSizes] = useState();
	const [client, setClient] = useState();
	const [addresses, setAddresses] = useState([]);
	const [order, setOrder] = useState({ date: moment(), claiming_date: moment(), on_place: 0 });
	const [loading, setLoading] = useState();

	const fetch = async (client) => {
		try {
			client ??= clientId;

			const clients = await API.clients.getAll();

			setClients(clients.filter((c) => !c.removed).sort((a, b) => new Date(b.created) - new Date(a.created)));
			setSizes(await API.containers.sizes.getAll());

			if (related_id) {
				const related = await API.orders.get(related_id);
				if (!related) throw 'Nie można załadować zamówienia do wymiany';

				const c = clients.filter((c) => c.id === related.client.id)[0];

				setOrder((order) => ({
					...order,
					related_id,
					client_id: related.client.id,
					client: c,
					bdo: related.bdo,
					waste_type: related.waste_type,
					address: related.address,
					size_id: related.size.id,
					claiming_date: related.claiming_date ? moment(related.claiming_date) : null,
					price: related.price,
					price_type: related.price_type,
					payment_type: related.payment_type,
				}));
				setClient(c?.name);
			}

			if (client) {
				const c = clients.filter((c) => c.id == client)[0];

				setOrder((order) => ({ ...order, client_id: client, client: c }));
				setClient(c?.name);

				delete newClient.client;
			}
		} catch (e) {
			console.error(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		fetch(newClient.client);
	}, []);

	useEffect(async () => {
		if (!order?.client_id) {
			return;
		}

		setAddresses(await API.addresses.getAll({ clientId: order.client_id }));
	}, [order?.client_id]);

	const autocompleteClient = (name) => {
		const client = clients.filter((client) => client.name == name)[0];
		if (!client) return;

		setOrder((order) => ({ ...order, client_id: client.id, client }));
	};

	const clientChanged = (name) => {
		const client = clients.filter((client) => client.name == name)[0];

		setOrder((order) => ({ ...order, client_id: client?.id, client }));
		setClient(name);
	};

	const createOrder = async () => {
		try {
			setLoading(true);

			const o = { ...order };
			const address = addresses.find((address) => address.name == o.address);
			if (!address) {
				await API.addresses.add({ name: o.address, clientId: o.client_id });
			}

			if (o.time) {
				o.date.hours(o.time.get('hour'));
				o.date.minutes(o.time.get('minute'));
			} else {
				o.date.hours(0);
				o.date.minutes(0);
			}
			o.date.seconds(0);
			o.date = o.date.unix() * 1000;

			if (o.claiming_date === null) {
				o.claiming = null;
			} else {
				o.claiming = o.claiming_date;

				if (o.claiming_time) {
					o.claiming.hours(o.claiming_time.get('hour'));
					o.claiming.minutes(o.claiming_time.get('minute'));
				} else {
					o.claiming.hours(0);
					o.claiming.minutes(0);
				}
				o.claiming.seconds(0);
				o.claiming = o.claiming_date.unix() * 1000;
			}

			delete o.client;
			delete o.time;
			delete o.claiming_date;
			delete o.claiming_time;

			const result = await API.orders.create(o);

			setLoading(false);
			history.replace(`orders/${result.id}`);
		} catch (e) {
			setLoading(false);

			message.error(`${e}`);
			console.trace(e);
		}
	};

	const disabled = useMemo(() => {
		if (!order.client_id) return true;
		if (isNaN(order.bdo)) return true;
		if (!order.waste_type) return true;
		if (!order.address) return true;
		if (isNaN(order.size_id)) return true;
		if (!order.date) return true;
		if (order.claiming_date === undefined) return true;
		if (isNaN(order.on_place)) return true;
		if (isNaN(order.price)) return true;
		if (isNaN(order.price_type)) return true;
		if (isNaN(order.payment_type)) return true;
	}, [order]);

	return [
		{ related_id, order, clients, client, addresses, newClient, sizes, loading, disabled },
		{
			autocompleteClient,
			clientChanged,
			bdoChanged: (bdo) => setOrder((order) => ({ ...order, bdo })),
			typeChanged: (waste_type) => setOrder((order) => ({ ...order, waste_type })),
			addressChanged: (address) => setOrder((order) => ({ ...order, address })),
			contactChanged: (e) => setOrder((order) => ({ ...order, contact: e.target.value })),
			dateChanged: (date) => setOrder((order) => ({ ...order, date })),
			timeChanged: (time) => setOrder((order) => ({ ...order, time })),
			claimingDateChanged: (claiming_date) => setOrder((order) => ({ ...order, claiming_date })),
			claimingTimeChanged: (claiming_time) => setOrder((order) => ({ ...order, claiming_time })),
			claimingChanged: (claiming) => setOrder((order) => ({ ...order, claiming_date: claiming ? moment() : null })),
			onPlaceChanged: (on_place) =>
				setOrder((order) => ({ ...order, on_place, claiming_date: on_place ? null : moment() })),
			sizeChanged: (size_id) => setOrder((order) => ({ ...order, size_id })),
			priceChanged: (e) => setOrder((order) => ({ ...order, price: e.target.value })),
			priceTypeChanged: (price_type) => setOrder((order) => ({ ...order, price_type })),
			paymentTypeChanged: (payment_type) => setOrder((order) => ({ ...order, payment_type })),
			commentsChanged: (e) => setOrder((order) => ({ ...order, comments: e.target.value })),
			createOrder,
		},
	];
}
