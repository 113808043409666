import React from 'react';
import StarBlob from '../svg/star-blob.svg';
import Blob2 from '../svg/blob2.svg';
import '../styles/pages/notFound.scss';

const NotFound = () => {
	return (
		<div className="flex flex-dir flex-jc flex-ac not-found">
			<h1>404 - Nie znaleziono strony</h1>
			<p className="lost">Zgubiłeś się?</p>
			<p>Nie przejmuj się zawsze możesz wrócić na stronę główną</p>
			<a href="/">Zabierz mnie spowrotem do strony głównej</a>

			<StarBlob className="starblob" />
			<Blob2 className="blob2" />
		</div>
	);
};

export default NotFound;
