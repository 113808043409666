import React from 'react';
import { AutoComplete, Button, Input, Skeleton, Space } from 'antd';
import { DollarCircleOutlined } from '@ant-design/icons';
import { useGetMoney } from '../../reducers';
import { Page, PageSection } from '../../components';
import { Formatting } from '../../utils';

import '../../styles/pages/expenses/getMoney.scss';

const GetMoney = () => {
	const [{ drivers, disabled, loading, driver }, { driverChanged, valueChanged, createSettlement }] = useGetMoney();

	const actions = [
		<Button
			key="0"
			type="primary"
			size="large"
			icon={<DollarCircleOutlined />}
			loading={loading}
			disabled={disabled}
			onClick={createSettlement}
		>
			Pobierz gotówkę
		</Button>,
	];
	return drivers ? (
		<Page title="Wydatki / Pobierz gotówkę od pracownika" actions={actions}>
			<div className="expenses__get-money flex">
				<PageSection title="Informacje" width={902}>
					<Space size={25}>
						<div className="flex flex-dir">
							<label>Pracownik</label>
							<AutoComplete
								size="large"
								options={drivers.map((d) => ({ value: `${d.name} ${d.surname}` }))}
								filterOption={(inputValue, option) =>
									option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
								}
								onSelect={driverChanged}
							/>
						</div>
						<div className="flex flex-dir">
							<label>Kwota</label>
							<div className="flex">
								<Input type="text" size="large" onChange={valueChanged} />
								<span className="flex flex-dir flex-jc flex-ac">PLN</span>
							</div>
						</div>
					</Space>
					{driver && (
						<Space size={25}>
							<div className="flex flex-dir">
								<label>Stan konta:</label>
								<span className="data">{Formatting.money(driver.balance)}</span>
							</div>
							<div></div>
						</Space>
					)}
				</PageSection>
			</div>
		</Page>
	) : (
		<Page>
			<Skeleton active />
		</Page>
	);
};

export default GetMoney;
