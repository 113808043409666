import React, { useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Button, DatePicker, Input, Skeleton } from 'antd';
import { blue, gold, green, grey, purple, red } from '@ant-design/colors';
import { CarryOutOutlined, PrinterOutlined } from '@ant-design/icons';
import { Calendar, Page } from '../../components';
import { useDriverCard } from '../../reducers';
import { Formatting } from '../../utils';
import { CardPrint } from '../../prints';
import styled from 'styled-components';
import moment from 'moment';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	> div {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;

		> h3 {
			margin: 16px 0 8px 0;
			font-size: 20px;
			text-align: center;
		}
	}
`;

const OrderEvent = styled.div`
	display: flex;
	width: 100%;
	padding: 12px;
	border-radius: 6px;
	background: ${({ color }) => color};
	color: white;
	margin-bottom: 4px;

	> div {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	h4 {
		font-size: 18px;
		margin: 0;
		margin-bottom: 4px;
		color: white;
	}

	h5 {
		font-size: 18px;
		font-weight: 400;
		margin: 0;
		color: white;
	}

	:hover {
		cursor: pointer;
	}
`;

const Closing = styled.div`
	> div {
		margin-bottom: 24px;
		display: flex;

		> div {
			flex: 1;

			&:not(:last-child) {
				margin-right: 20px;
			}

			&.calculated {
				flex: 0.75;
			}

			> span {
				color: ${() => blue[7]};
				font-size: 18px;
				margin-top: 5px;
				font-weight: 500;
			}
		}
	}
`;

const Closed = styled.div`
	> div {
		margin-bottom: 24px;
		display: flex;

		> div {
			flex: 1;

			&:not(:last-child) {
				margin-right: 20px;
			}

			> span {
				color: ${() => blue[7]};
				font-size: 20px;
				margin-top: 5px;
				font-weight: 500;
			}
		}
	}
`;

const Unclosed = styled.h5`
	font-size: 20px;
	font-weight: 400;
	color: ${() => grey[5]};
	text-align: center;
	margin: 16px 0 0 0;
`;

const CardsPrint = styled.div`
	> .print:not(:last-of-type) {
		page-break-after: always;
	}

	> h2,
	h3 {
		text-align: center;
	}

	h3 {
		margin: 18px 0;
	}
`;

const DriverCard = ({ match }) => {
	const history = useHistory();

	const [
		{
			offset,
			user,
			events,
			card,
			loading,
			closingDisabled,
			printDates,
			prints,
			advances,
			payments,
			expenses,
			settlements,
		},
		{
			setOffset,
			setMileageEnd,
			setTrip,
			setTime,
			setPlate,
			closeCard,
			setPrintDateFrom,
			setPrintDateTo,
			generatePrints,
			removePrints,
		},
	] = useDriverCard(match);

	const actions = [
		<DatePicker value={printDates.from} onChange={setPrintDateFrom} />,
		<DatePicker value={printDates.to} onChange={setPrintDateTo} />,
		<Button
			size="large"
			type="primary"
			icon={<PrinterOutlined />}
			loading={loading === 'prints'}
			onClick={prints ? () => handleCardsPrint() : generatePrints}
		>
			{prints ? 'Wygenerowano,  wydrukuj' : 'Drukuj od do'}
		</Button>,
		<Button size="large" type="primary" icon={<PrinterOutlined />} onClick={() => handlePrint()}>
			Drukuj jedną kartę
		</Button>,
	];

	const printRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	const cardsPrintRef = useRef();
	const handleCardsPrint = useReactToPrint({
		content: () => cardsPrintRef.current,
		onAfterPrint: () => removePrints(),
	});

	return (
		<>
			{user != undefined ? (
				<Page title={`Pracownicy / ${user.name} ${user.surname}`} actions={actions}>
					<Calendar offset={offset} onChange={setOffset}>
						<Wrapper>
							<div>
								<h3>Podstawienia</h3>
								{events
									.filter((e) => e._type === 0)
									.map((e) => (
										<OrderEvent
											key={e.id}
											color={e.on_place ? purple[5] : green[5]}
											onClick={() => history.push(`/panel/orders/${e.id}`)}
										>
											<div>
												<h4>
													{moment(e.delivery_taken).format('HH:mm')} - {moment(e.delivery_done).format('HH:mm')}
												</h4>
												<h5>{e.address}</h5>
											</div>
											<div>
												<span>
													Płatność:{' '}
													{e.payment_type
														? `Gotówka - ${e.payment === e.delivery_done ? 'pobrana' : 'niepobrana'}`
														: 'Przelew'}
												</span>
												<span>
													Kwota: {Formatting.money(e.price)} {e.price_type ? 'Brutto' : 'Netto'}
												</span>
												<span>Zaliczka: {e.advance ? Formatting.money(e.advance) : 'Brak'}</span>
											</div>
										</OrderEvent>
									))}
							</div>
							<div>
								<h3>Odbiory</h3>
								{events
									.filter((e) => e._type === 1)
									.map((e) => (
										<OrderEvent
											key={e.id}
											color={e.on_place ? purple[5] : red[5]}
											onClick={() => history.push(`/panel/orders/${e.id}`)}
										>
											<div>
												<h4>
													{moment(e.claiming_taken).format('HH:mm')} - {moment(e.claiming_done).format('HH:mm')}
												</h4>
												<h5>{e.address}</h5>
											</div>
											<div>
												<span>
													Płatność:{' '}
													{e.payment_type
														? `Gotówka - ${e.payment === e.claiming_done ? 'pobrana' : 'niepobrana'}`
														: 'Przelew'}
												</span>
												<span>
													Kwota: {Formatting.money(e.price)} {e.price_type ? 'Brutto' : 'Netto'}
												</span>
												<span>Zaliczka: {e.advance ? Formatting.money(e.advance) : 'Brak'}</span>
											</div>
										</OrderEvent>
									))}
							</div>
							<div>
								<h3>Wymiany</h3>
								{events
									.filter((e) => e._type === -1)
									.map((e) => (
										<OrderEvent key={e.id} color={gold[5]} onClick={() => history.push(`/panel/orders/${e.id}`)}>
											<div>
												<h4>
													{moment(e.delivery_taken).format('HH:mm')} - {moment(e.delivery_done).format('HH:mm')}
												</h4>
												<h5>{e.address}</h5>
											</div>
											<div>
												<span>
													Płatność:{' '}
													{e.payment_type
														? `Gotówka - ${e.payment === e.delivery_done ? 'pobrana' : 'niepobrana'}`
														: 'Przelew'}
												</span>
												<span>
													Kwota: {Formatting.money(e.price)} {e.price_type ? 'Brutto' : 'Netto'}
												</span>
												<span>Zaliczka: {e.advance ? Formatting.money(e.advance) : 'Brak'}</span>
											</div>
										</OrderEvent>
									))}
							</div>
							<div>
								<h3>Wydatki</h3>
								{events
									.filter((e) => e._type === 2)
									.map((e) => (
										<OrderEvent key={e.id} color={blue[5]}>
											<div>
												<h4>{moment(e.created).format('HH:mm')}</h4>
												<h5>
													{e.type.name} - {e.name}
												</h5>
											</div>
											<div>
												<span>
													Kwota: {Formatting.money(e.value)} {e.value_type ? 'Brutto' : 'Netto'}
												</span>
												<span>Podatek: {e.tax}%</span>
												<span>Przebieg: {e.mileage ? Formatting.insertSpaces(e.mileage) : '-'}</span>
											</div>
										</OrderEvent>
									))}
							</div>
							<div>
								<h3>Wydania gotówki</h3>
								{events
									.filter((e) => e._type === 3)
									.map((e) => (
										<OrderEvent key={e.id} color={purple[5]}>
											<div>
												<h4>{moment(e.created).format('HH:mm')}</h4>
												<h5>{Formatting.money(e.value)}</h5>
											</div>
											<div>
												<span>
													Odebrał: {e.created_by.name} {e.created_by.surname}
												</span>
											</div>
										</OrderEvent>
									))}
							</div>
							<div>
								<h3>Podsumowanie</h3>
								<OrderEvent color={grey[5]}>
									<div>
										<h4>Pobrane od klientów: {Formatting.money(advances + payments)}</h4>
										<h4>Wydane: {Formatting.money(expenses + settlements)}</h4>
									</div>
									<div>
										<span>Zaliczki: {Formatting.money(advances)}</span>
										<span>Zapłaty: {Formatting.money(payments)}</span>
										<span>Wydatki: {Formatting.money(expenses)}</span>
										<span>Pobrania gotówki: {Formatting.money(settlements)}</span>
									</div>
								</OrderEvent>
							</div>
							<div>
								<h3>Balans: {Formatting.money(advances + payments - expenses - settlements)}</h3>
							</div>
							{card.id ? (
								<Closed>
									<div>
										<div className="flex flex-dir">
											<label>Licznik - początek</label>
											<span>{Formatting.insertSpaces(card.mileage_start)}</span>
										</div>
										<div className="flex flex-dir">
											<label>Licznik - koniec</label>
											<span>{Formatting.insertSpaces(card.mileage_end)}</span>
										</div>
										<div className="flex flex-dir">
											<label>Suma Km</label>
											<span>{Formatting.insertSpaces(card.mileage_end - card.mileage_start)}</span>
										</div>
									</div>
									<div>
										<div className="flex flex-dir">
											<label>Czas jazdy</label>
											<span>{card.time}</span>
										</div>
										<div className="flex flex-dir">
											<label>Nr rejestracyjny</label>
											<span>{card.plate}</span>
										</div>
										<div className="flex flex-dir">
											<label>Zamknięte</label>
											<span>{Formatting.epochToDate(card.closed)}</span>
										</div>
									</div>
								</Closed>
							) : user?.id == sessionStorage.getItem('id') ? (
								<Closing>
									<div>
										<div className="flex flex-dir calculated">
											<label>Licznik - początek</label>
											<span>{card.mileageStart ?? '-'}</span>
										</div>
										<div className="flex flex-dir">
											<label>Licznik - koniec</label>
											<Input size="large" onChange={setMileageEnd} />
										</div>
										<div className="flex flex-dir">
											<label>Suma Km</label>
											<Input size="large" onChange={setTrip} />
										</div>
									</div>
									<div>
										<div className="flex flex-dir">
											<label>Czas jazdy</label>
											<Input size="large" onChange={setTime} />
										</div>
										<div className="flex flex-dir">
											<label>Nr rejestracyjny</label>
											<Input size="large" onChange={setPlate} />
										</div>
									</div>
									<Button
										key={0}
										type="primary"
										size="large"
										icon={<CarryOutOutlined />}
										disabled={closingDisabled}
										onClick={closeCard}
									>
										Zamknij kartę
									</Button>
								</Closing>
							) : (
								<Unclosed>Karta nie została jeszcze zamknięta.</Unclosed>
							)}
						</Wrapper>
					</Calendar>
				</Page>
			) : (
				<Page>
					<Skeleton active />
				</Page>
			)}

			<CardPrint
				ref={printRef}
				user={user}
				events={events}
				card={card}
				summary={{ advances, payments, expenses, settlements }}
				date={moment().add(offset, 'days')}
			/>

			<CardsPrint className="print" ref={cardsPrintRef}>
				<h2>Wydruk kart kierowcy z dnia {Formatting.epochToDate(moment(), true)}</h2>
				{prints}
			</CardsPrint>
		</>
	);
};

export default DriverCard;
