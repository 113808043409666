import React from 'react';

const Container = ({ children, className }) => {
	return (
		<div className={className}>
			{children}
			{/* <h3>Powered by RacooonSoft - v0.1 beta</h3> */}
		</div>
	);
};

export default Container;
