import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Input, Space, Table } from 'antd';
import {
	CloseCircleOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
	PlusCircleOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { Page } from '../../components';
import { useClients } from '../../reducers';
import { Formatting } from '../../utils';

import '../../styles/pages/clients/clients.scss';

const Clients = () => {
	const history = useHistory();
	const [
		{ data, visible, filters, loading },
		{ searchChanged, clearFilters, toggleVisible, deleteClient, restoreClient },
	] = useClients();

	const columns = [
		{
			title: 'Nazwa',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Telefon',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: 'NIP',
			dataIndex: 'nip',
			key: 'nip',
		},
		{
			title: 'BDO',
			dataIndex: 'bdo',
			key: 'bdo',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: visible ? 'Usunięty - przez' : 'Dodany - przez',
			dataIndex: 'created',
			key: 'created',
			render: (v, row) =>
				visible ? (
					<span>
						{row.removed_by ? (
							<>
								{Formatting.epochToDate(v)} -{' '}
								<Link to={`/panel/drivers/${row.removed_by.id}`}>{row.removed_by.name}</Link>
							</>
						) : (
							'-'
						)}
					</span>
				) : (
					<span>
						{Formatting.epochToDate(v)} - <Link to={`/panel/drivers/${row.created_by.id}`}>{row.created_by.name}</Link>
					</span>
				),
		},
		{
			title: 'Akcje',
			key: 'action',
			render: (text, row) => (
				<Space size={5}>
					<Link to={`/panel/clients/${row.id}`}>Szczegóły</Link>
					<span>|</span>
					{row.removed_by ? (
						<a
							style={{ color: loading === row.id ? '#8c8c8c' : '#52C41A' }}
							onClick={() => (loading === row.id ? undefined : restoreClient(row.id))}
						>
							Przywróć
						</a>
					) : (
						<a style={{ color: '#f5222d' }} onClick={() => (loading === row.id ? undefined : deleteClient(row.id))}>
							Usuń
						</a>
					)}
				</Space>
			),
		},
	];

	const actions = [
		<Button
			key={0}
			type="primary"
			size="large"
			icon={<PlusCircleOutlined />}
			onClick={() => history.push('/panel/new-client')}
		>
			Dodaj nowego Klienta
		</Button>,
		<Button
			key={1}
			type="primary"
			size="large"
			icon={visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
			onClick={toggleVisible}
		>
			{visible ? 'Ukryj' : 'Pokaż'} usuniętych klientów
		</Button>,
	];

	return (
		<Page title="Klienci" actions={actions}>
			<div className="clients flex flex-dir flex-1">
				<div className="inputs flex">
					<Input
						placeholder="Wyszukaj"
						size="large"
						suffix={<SearchOutlined style={{ color: '#8C8C8C' }} />}
						value={filters.search}
						onChange={searchChanged}
					/>
					<Button type="primary" size="large" icon={<CloseCircleOutlined />} onClick={clearFilters}>
						Wyczyść
					</Button>
				</div>
				<Table
					columns={columns}
					dataSource={data}
					loading={data === undefined}
					pagination={false}
					scroll={{ x: 'max-content' }}
				/>
			</div>
		</Page>
	);
};

export default Clients;
