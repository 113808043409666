import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AutoComplete, Button, Checkbox, DatePicker, Input, Select, Skeleton, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Page, PageSection } from '../../../../components';
import ClientInfo from './components/client_info';
import useNewDelivery from './reducers/useNewDelivery';
import SearchSelector from '../../../../components/searchSelector';

import './styles/newDelivery.scss';

const { TextArea } = Input;

const NewDelivery = ({ select }) => {
	const history = useHistory();

	const [
		{ related_id, order, clients, client, addresses, newClient, sizes, loading, disabled },
		{
			autocompleteClient,
			clientChanged,
			bdoChanged,
			typeChanged,
			addressChanged,
			contactChanged,
			dateChanged,
			timeChanged,
			claimingChanged,
			claimingDateChanged,
			claimingTimeChanged,
			onPlaceChanged,
			sizeChanged,
			priceChanged,
			priceTypeChanged,
			paymentTypeChanged,
			commentsChanged,
			createOrder,
		},
	] = useNewDelivery();

	const actions = [
		<Button
			key="0"
			type="primary"
			size="large"
			icon={<PlusCircleOutlined />}
			onClick={createOrder}
			disabled={disabled}
			loading={loading}
		>
			Dodaj zamówienie
		</Button>,
	];

	const wasteTypes = ['Mieszany', 'Gruz', 'Drewno', 'Styropian', 'Papa'];
	const loaded = useMemo(() => clients && sizes, [clients, sizes]);

	return (
		<Page title="Zamówienia / Nowe" actions={loaded && actions}>
			{loaded ? (
				<PageSection title="Klient" select={select}>
					<div className="new-delivery">
						<Space size={25}>
							<div className="flex flex-dir">
								<label>Klient</label>
								<AutoComplete
									size="large"
									options={clients.map((client) => ({ value: client.name }))}
									filterOption={(inputValue, option) => {
										const client = clients.filter((client) => client.name === option.value)[0];
										if (!client) return false;

										if (`${client.name}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.phone}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.nip}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.bdo}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.email}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
									}}
									onSelect={autocompleteClient}
									onChange={clientChanged}
									value={client}
								/>
							</div>
							<div className="flex flex-dir flex-ac">
								<label>&nbsp;</label>
								<Button
									size="large"
									type="primary"
									icon={<PlusCircleOutlined />}
									onClick={() => history.push(`/panel/new-client`, newClient)}
								>
									Dodaj nowego klienta
								</Button>
							</div>
						</Space>

						{order.client && <ClientInfo client={order.client} />}

						<PageSection title="Informacje">
							<Space size={25}>
								<div className="flex flex-dir">
									<label>BDO</label>
									<Select size="large" value={order.bdo} onChange={bdoChanged}>
										<Select.Option key="0" value={0}>
											BDO
										</Select.Option>
										<Select.Option key="1" value={1}>
											Wytwarzający
										</Select.Option>
									</Select>
								</div>

								<div className="flex flex-dir">
									<label>Rodzaj odpadów</label>
									<AutoComplete
										size="large"
										options={wasteTypes.map((type) => ({ value: type }))}
										filterOption={(inputValue, option) => option.value.toUpperCase().includes(inputValue.toUpperCase())}
										value={order.waste_type}
										onSelect={typeChanged}
										onChange={typeChanged}
									/>
								</div>
							</Space>

							<Space size={25}>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<label>Adres</label>
									<SearchSelector
										id="name"
										elements={addresses}
										buildElement={(address) => (typeof address === 'string' ? address : address.name)}
										value={order.address}
										onChange={addressChanged}
										allowCustom
									/>
								</div>
								<div>
									<label>Numer kontaktowy</label>
									<Input size="large" type="text" value={order.contact} onChange={contactChanged} />
								</div>
							</Space>

							{!related_id && (
								<Space size={25} align="center">
									<div className="flex flex-dir">
										<Checkbox checked={order.on_place === 1} onChange={(e) => onPlaceChanged(e.target.checked ? 1 : 0)}>
											<label onClick={() => onPlaceChanged(order.on_place === 0 ? 1 : 0)}>Załadunek na miejscu</label>
										</Checkbox>
									</div>
									<div></div>
								</Space>
							)}
						</PageSection>

						<PageSection title="Zapotrzebowanie">
							<Space size={25}>
								<div className="flex flex-dir">
									<label>Termin podstawienia</label>
									<DatePicker size="large" placeholder="Wybierz datę" value={order.date} onChange={dateChanged} />
								</div>
								<div>
									<label>&nbsp;</label>
									<DatePicker.TimePicker
										size="large"
										format="HH:mm"
										placeholder="Wybierz godzinę"
										value={order.time}
										onChange={timeChanged}
									/>
								</div>
							</Space>

							<Space size={25} align="center">
								<div className="flex flex-dir">
									<label>Wybierz rozmiar</label>
									<Select size="large" value={order.size_id} onChange={sizeChanged}>
										{sizes.map((size) => (
											<Select.Option key={size.id} value={size.id}>
												{size.name}
											</Select.Option>
										))}
									</Select>
								</div>
								<div className="flex flex-dir flex-ac">
									{!order.on_place && (
										<>
											<label>&nbsp;</label>
											<Checkbox
												checked={order.claiming_date === null}
												onChange={(e) => claimingChanged(!e.target.checked)}
											>
												<label onClick={() => claimingChanged(order.claiming_date === null)}>Bez terminu odbioru</label>
											</Checkbox>
										</>
									)}
								</div>
							</Space>

							{order.claiming_date !== null && !order.on_place && (
								<Space size={25}>
									<div className="flex flex-dir">
										<label>Termin odbioru</label>
										<DatePicker
											size="large"
											placeholder="Wybierz datę"
											value={order.claiming_date}
											onChange={claimingDateChanged}
										/>
									</div>
									<div>
										<label>&nbsp;</label>
										<DatePicker.TimePicker
											size="large"
											format="HH:mm"
											placeholder="Wybierz godzinę"
											value={order.claiming_time}
											onChange={claimingTimeChanged}
										/>
									</div>
								</Space>
							)}

							<Space size={25}>
								<div>
									<label>Cena</label>
									<div className="flex">
										<Input size="large" type="text" value={order.price} onChange={priceChanged} />
										<span className="pln flex flex-dir flex-jc flex-ac">PLN</span>
									</div>
								</div>
								<div className="flex flex-dir">
									<label>Netto / Brutto</label>
									<Select size="large" value={order.price_type} onChange={priceTypeChanged}>
										<Select.Option key="0" value={0}>
											Netto
										</Select.Option>
										<Select.Option key="1" value={1}>
											Brutto
										</Select.Option>
									</Select>
								</div>
							</Space>

							<Space size={25}>
								<div className="flex flex-dir">
									<label>Typ zapłaty</label>
									<Select size="large" value={order.payment_type} onChange={paymentTypeChanged}>
										<Select.Option key="0" value={0}>
											Przelew
										</Select.Option>
										<Select.Option key="1" value={1}>
											Gotówka
										</Select.Option>
									</Select>
								</div>
								<div></div>
							</Space>
						</PageSection>

						<PageSection title="Uwagi">
							<TextArea value={order.comments} onChange={commentsChanged} />
						</PageSection>
					</div>
				</PageSection>
			) : (
				<Skeleton active />
			)}
		</Page>
	);
};

export default NewDelivery;
