import React from 'react';
import { Route, useHistory, Switch } from 'react-router-dom';
import { Alert, Button, ConfigProvider, Space } from 'antd';
import {
	IdcardOutlined,
	UserOutlined,
	LogoutOutlined,
	LineChartOutlined,
	FolderOutlined,
	CreditCardOutlined,
	BookOutlined,
	SolutionOutlined,
	SettingOutlined,
	InfoCircleOutlined,
	DollarCircleOutlined,
	CarryOutOutlined,
} from '@ant-design/icons';
import {
	Drivers,
	DriverDetails,
	DriverCard,
	NewDriver,
	Containers,
	ContainerDetails,
	NewContainer,
	NotFound,
	Clients,
	ClientDetails,
	NewClient,
	Expenses,
	NewExpense,
	GetMoney,
	Orders,
	OrderDetails,
	NewOrder,
	Tasks,
	TaskDetails,
	ContainerSelection,
	TaskClosingScreen,
	Sign,
	SavedSign,
	Settings,
	Stats,
	FastActions,
	Finances,
} from '../pages';
import { IconCircle } from '../components';
import { usePanel } from '../reducers';
import { Formatting } from '../utils';
import locale from 'antd/lib/locale/pl_PL';
import moment from 'moment';
import 'moment/locale/pl';

const Main = () => {
	const history = useHistory();
	const [{ stats, balance, level, admin, showToClaim, showUnclosed }, { setShowToClaim, setShowUnclosed }] = usePanel();

	const driverIcons = [
		{
			label: 'Kontenery',
			icon: <FolderOutlined />,
			route: '/panel/containers',
		},
		{
			label: 'Wydatki',
			icon: <CreditCardOutlined />,
			route: '/panel/expenses',
		},
		{
			label: 'Zamówienia',
			icon: <SolutionOutlined />,
			route: '/panel/orders',
		},
	];

	const adminIcons = [
		{
			label: 'Pracownicy',
			icon: <IdcardOutlined />,
			route: '/panel/drivers',
		},
		{
			label: 'Klienci',
			icon: <UserOutlined />,
			route: '/panel/clients',
		},
		{
			label: 'Kontenery',
			icon: <FolderOutlined />,
			route: '/panel/containers',
		},
		{
			label: 'Wydatki',
			icon: <CreditCardOutlined />,
			route: '/panel/expenses',
		},
		{
			label: 'Terminarz',
			icon: <BookOutlined />,
			route: '/panel/tasks',
			notifications: stats?.tasks,
		},
		{
			label: 'Zamówienia',
			icon: <SolutionOutlined />,
			route: '/panel/orders',
		},
	];

	const bottomIcons = admin
		? [
				{
					label: 'Ustawienia',
					icon: <SettingOutlined />,
					route: '/panel/settings',
				},
				{
					label: 'Statystyki',
					icon: <LineChartOutlined />,
					route: '/panel/stats',
					level: 2,
				},
				{
					label: 'Wyloguj',
					icon: <LogoutOutlined />,
					route: '/logout',
				},
		  ]
		: [
				{
					label: 'Ustawienia',
					icon: <SettingOutlined />,
					route: '/panel/settings',
				},
				{
					label: 'Wyloguj',
					icon: <LogoutOutlined />,
					route: '/logout',
				},
		  ];

	const toClaim = stats?.to_claim?.map((el) => (
		<tr className="claim-entry" key={`${el.id}`}>
			<td>{el.container.name}</td>
			<td>{el.size?.name}</td>
			<td>{el.order.address}</td>
			<td>{<InfoCircleOutlined onClick={() => history.push(`/panel/tasks/${el.order.id}/2`)} />}</td>
		</tr>
	));

	const unclosed = stats?.unclosed?.map((el) => (
		<tr className="claim-entry" key={`${el.id}`}>
			<td>{el.address}</td>
			<td>
				{Formatting.epochToDate(
					el.delivery_done ? el.claiming_date : el.delivery_date,
					moment(el.delivery_done ? el.claiming_date : el.delivery_date).hours() === 0
				)}
			</td>
			<td>{<InfoCircleOutlined onClick={() => history.push(`/panel/orders/${el.id}`)} />}</td>
		</tr>
	));

	return (
		<div className="panel flex flex-dir flex-jsb">
			{stats?.to_claim?.length > 0 && (
				<div className="claim">
					<Alert
						className="alert"
						message={[
							'Kontenery czekające na odbiór:',
							<span key="0" className="claim">
								{stats?.to_claim?.length}
							</span>,
						]}
						banner
						action={
							<Space>
								<Button size="large" type="secondary" onClick={() => setShowToClaim((curr) => !curr)}>
									{showToClaim ? 'Ukryj' : 'Pokaż'} kontenery
								</Button>
							</Space>
						}
						closable
					/>
					{showToClaim && (
						<div className="toClaim">
							<table>
								<tbody>
									<tr>
										<th>Numer</th>
										<th>Rozmiar</th>
										<th>Adres</th>
									</tr>
									{toClaim}
								</tbody>
							</table>
						</div>
					)}
				</div>
			)}

			{stats?.unclosed?.length > 0 && (
				<div className="claim">
					<Alert
						className="alert"
						message={[
							'Niezamknięte zamówienia:',
							<span key="0" className="claim">
								{stats?.unclosed?.length}
							</span>,
						]}
						banner
						action={
							<Space>
								<Button size="large" type="secondary" onClick={() => setShowUnclosed((curr) => !curr)}>
									{showToClaim ? 'Ukryj' : 'Pokaż'} zamówienia
								</Button>
							</Space>
						}
						closable
					/>
					{showUnclosed && (
						<div className="toClaim">
							<table>
								<tbody>
									<tr>
										<th>Adres</th>
										<th>Data</th>
									</tr>
									{unclosed}
								</tbody>
							</table>
						</div>
					)}
				</div>
			)}

			{admin ? (
				<>
					{adminIcons.map((el, i) => (
						<div key={el.label} className="flex flex-jc flex-ac">
							<IconCircle
								key={el.label}
								label={el.label}
								onClick={() => history.push(el.route)}
								notifications={el.notifications}
							>
								{el.icon}
							</IconCircle>
						</div>
					))}
					<div key="99" className="fast-actions flex flex-jc flex-ac">
						<IconCircle key="99" label="Kontenery w zamówieniach" onClick={() => history.push('/panel/fast-actions')}>
							<FolderOutlined />
						</IconCircle>
					</div>
					<div></div>
					<div key="100" className="flex flex-jc flex-ac">
						{level > 1 && (
							<IconCircle key="100" label="Finanse" onClick={() => history.push('/panel/finances')}>
								<DollarCircleOutlined />
							</IconCircle>
						)}
					</div>
				</>
			) : (
				<>
					{driverIcons.map((el, i) => (
						<div key={el.label} className="flex flex-jc flex-ac">
							<IconCircle
								key={el.label}
								label={el.label}
								onClick={() => history.push(el.route)}
								notifications={el.notifications}
							>
								{el.icon}
							</IconCircle>
						</div>
					))}
					<div></div>
					<div className="flex flex-jc flex-ac">
						<IconCircle label="Terminarz" onClick={() => history.push('panel/tasks')} notifications={stats?.tasks}>
							<BookOutlined />
						</IconCircle>
					</div>
					<div></div>
				</>
			)}

			{stats && (
				<div className="middle-section flex flex-dir flex-ac">
					<table>
						<tbody>
							{!admin && (
								<tr key={'balance'}>
									<td>Stan konta:</td>
									<td style={{ whiteSpace: 'nowrap' }}>{Formatting.money(balance)}</td>
								</tr>
							)}
							{Object.entries(stats.summary.free).map(([k, v]) => (
								<tr key={k}>
									<td>Kontenery wolne {v.name}:</td>
									<td>{v.count}</td>
								</tr>
							))}
							<tr>
								<td>Kontenery do podstawienia:</td>
								<td>{stats.summary.to_deliver ?? 0}</td>
							</tr>
							<tr>
								<td>Kontenery podstawione:</td>
								<td>{stats.summary.delivered ?? 0}</td>
							</tr>
							<tr>
								<td>Kontenerów do wymiany:</td>
								<td>{stats.summary.to_replace ?? 0}</td>
							</tr>
							<tr>
								<td>Kontenerów czekających na odbiór:</td>
								<td>{stats.summary.to_claim ?? 0}</td>
							</tr>
							<tr>
								<td>Kontenery w transporcie:</td>
								<td>{stats.summary.in_transit ?? 0}</td>
							</tr>
							<tr>
								<td>Kontenery wypożyczone:</td>
								<td>{stats.summary.lended ?? 0}</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}

			{admin ? (
				bottomIcons.map((el, i) => (
					<div key={el.label} className="flex flex-jc flex-ac">
						{level >= (el.level ?? 0) && (
							<IconCircle key={el.label} label={el.label} onClick={() => history.push(el.route)}>
								{el.icon}
							</IconCircle>
						)}
					</div>
				))
			) : (
				<>
					<div className="flex flex-jc flex-ac">
						<IconCircle label="Ustawienia" onClick={() => history.push('panel/settings')}>
							<SettingOutlined />
						</IconCircle>
					</div>
					<div className="flex flex-jc flex-ac">
						<IconCircle
							label="Zamknij kartę"
							onClick={() => history.push(`panel/drivers/${sessionStorage.getItem('id')}/card`)}
						>
							<CarryOutOutlined />
						</IconCircle>
					</div>
					<div className="flex flex-jc flex-ac">
						<IconCircle label="Wyloguj" onClick={() => history.push('/logout')}>
							<LogoutOutlined />
						</IconCircle>
					</div>
				</>
			)}
		</div>
	);
};

const Panel = () => {
	return (
		<ConfigProvider locale={locale}>
			<Switch>
				<Route path="/panel" exact component={Main} />
				<Route path="/panel/drivers" exact component={Drivers} />
				<Route path="/panel/drivers/:id" exact component={DriverDetails} />
				<Route path="/panel/drivers/:id/card" exact component={DriverCard} />
				<Route path="/panel/new-driver" exact component={NewDriver} />
				<Route path="/panel/containers" exact component={Containers} />
				<Route path="/panel/containers/:id" exact component={ContainerDetails} />
				<Route path="/panel/new-container" exact component={NewContainer} />
				<Route path="/panel/clients" exact component={Clients} />
				<Route path="/panel/clients/:id" exact component={ClientDetails} />
				<Route path="/panel/new-client" exact component={NewClient} />
				<Route path="/panel/expenses" exact component={Expenses} />
				<Route path="/panel/expenses/get-money" exact component={GetMoney} />
				<Route path="/panel/expenses/:id" exact component={Expenses} />
				<Route path="/panel/new-expense" exact component={NewExpense} />
				<Route path="/panel/orders" exact component={Orders} />
				<Route path="/panel/orders/:id" exact component={OrderDetails} />
				<Route path="/panel/orders/:id/:type" exact component={OrderDetails} />
				<Route path="/panel/new-order" exact component={NewOrder} />
				<Route path="/panel/tasks" exact component={Tasks} />
				<Route path="/panel/tasks/:id/sign" exact component={SavedSign} />
				<Route path="/panel/tasks/:id/:type" exact component={TaskDetails} />
				<Route path="/panel/tasks/:id/:type/:sizeId" exact component={ContainerSelection} />
				<Route path="/panel/task-closing/:id/:type" exact component={TaskClosingScreen} />
				<Route path="/panel/task-closing-sign" component={TaskClosingScreen} />
				<Route path="/panel/task-closing/sign/:id" exact component={Sign} />
				<Route path="/panel/settings" exact component={Settings} />
				<Route path="/panel/stats" exact component={Stats} />
				<Route path="/panel/fast-actions" exact component={FastActions} />
				<Route path="/panel/finances" exact component={Finances} />
				<Route component={NotFound} />
			</Switch>
		</ConfigProvider>
	);
};

export default Panel;
