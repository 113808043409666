import { message } from 'antd';
import { useEffect, useState } from 'react';
import API from '../API';

const useSettings = () => {
	const [sizes, setSizes] = useState();
	const [newSize, setNewSize] = useState();

	const [newUsername, setNewUsername] = useState();
	const [user, setUser] = useState();

	const [newPassword, setNewPassword] = useState();
	const [repeatPassword, setRepeatPassword] = useState();
	const [oldPassword, setOldPassword] = useState();

	const [loading, setLoading] = useState(null);
	const id = sessionStorage.getItem('id');
	const admin = window.sessionStorage.getItem('level') > 0;

	const fetch = async () => {
		try {
			const sizes = (await API.containers.sizes.getAll()).map((size, i) => ({ ...size, key: i }));
			const user = await API.users.get(id);

			setNewUsername(user.username);
			setUser(user);
			setSizes(sizes);
			setLoading();
		} catch (e) {
			message.error(`${e}`);
			console.trace(e);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const changeLogin = async () => {
		if (loading) return;

		try {
			if (!newUsername || newUsername === user.username) throw 'Podaj nowy login';

			setLoading('username');

			await API.users.patch(id, { username: newUsername });
			await fetch();

			message.success('Zmieniono login');
			setLoading();
		} catch (e) {
			setLoading();
			message.error(`${e}`);
			console.trace(e);
		}
	};

	const changePassword = async () => {
		if (loading) return;

		try {
			if (newPassword !== repeatPassword) throw 'Hasła nie są takie same';
			if (newPassword === oldPassword) throw 'Podaj nowe hasło';

			setLoading('password');

			await API.users.patch(id, { password: newPassword, oldPassword });
			await fetch();

			setNewPassword();
			setRepeatPassword();
			setOldPassword();

			message.success('Zmieniono hasło');
			setLoading();
		} catch (e) {
			setLoading();
			message.error(`${e}`);
			console.trace(e);
		}
	};

	const deleteSize = async (id) => {
		if (loading) return;

		try {
			setLoading(id);

			await API.containers.sizes.delete(id);
			await fetch();

			message.success('Usunięto rozmiar');
			setLoading();
		} catch (e) {
			setLoading();
			message.error(`${e}`);
			console.trace(e);
		}
	};

	const addSize = async () => {
		if (loading) return;

		try {
			setLoading('size');

			await API.containers.sizes.create(newSize);
			await fetch();

			setNewSize();

			message.success('Dodano rozmiar');
			setLoading();
		} catch (e) {
			setLoading();
			message.error(`${e}`);
			console.trace(e);
		}
	};

	return [
		{ sizes, newUsername, newPassword, repeatPassword, oldPassword, newSize, loading, admin, user },
		{
			setNewUsername: (e) => setNewUsername(e.target.value),
			setNewPassword: (e) => setNewPassword(e.target.value),
			setRepeatPassword: (e) => setRepeatPassword(e.target.value),
			setOldPassword: (e) => setOldPassword(e.target.value),
			setNewSize: (e) => setNewSize(e.target.value),
			changeLogin,
			changePassword,
			deleteSize,
			addSize,
		},
	];
};

export default useSettings;
