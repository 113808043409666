import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';
import API from '../../../../../API';

export default function (match) {
	const history = useHistory();
	const { id } = match.params;

	const [order, setOrder] = useState();
	const [sizes, setSizes] = useState();
	const [loading, setLoading] = useState();
	const [dateModal, setDateModal] = useState();
	const [warningModal, setWarningModal] = useState();
	const [price, setPrice] = useState();
	const [priceType, setPriceType] = useState();
	const [paymentType, setPaymentType] = useState();
	const [wasteType, setWasteType] = useState();
	const [address, setAddress] = useState();
	const [sizeId, setSizeId] = useState();

	const fetch = async () => {
		try {
			setOrder(await API.orders.get(id));
			setSizes(await API.containers.sizes.getAll());
		} catch (e) {
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const changePrice = async () => {
		try {
			setLoading('price');

			await API.orders.patch(id, { price: price ?? order.price, price_type: priceType ?? order.priceType });
			await fetch();

			message.success('Zmieniono cenę');
			setPrice();
			setPriceType();
			setLoading();
		} catch (e) {
			setLoading();
			message.error(`${e}`);
		}
	};

	const changeInvoice = async () => {
		try {
			setLoading('invoice');

			await API.orders.patch(id, { invoice: order.invoice });
			await fetch();

			message.success('Zmieniono numer faktury');
			setLoading();
		} catch (e) {
			setLoading();
			message.error(`${e}`);
		}
	};

	const changePaymentType = async () => {
		try {
			setLoading('payment_type');

			await API.orders.patch(id, { payment_type: paymentType });
			await fetch();

			message.success('Zmieniono typ płatności');
			setPaymentType();
			setLoading();
		} catch (e) {
			setLoading();
			message.error(`${e}`);
		}
	};

	const changeDeliveryDate = async (date) => {
		try {
			setLoading('date');

			await API.orders.patch(id, { delivery_date: date.unix() * 1000 });
			await fetch();

			setDateModal();
			setLoading();
			return true;
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const changeClaimingDate = async (date) => {
		try {
			setLoading('date');

			await API.orders.patch(id, { claiming_date: date === null ? null : date.unix() * 1000 });
			await fetch();

			setDateModal();
			setLoading();
			return true;
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const deleteOrder = async () => {
		try {
			setLoading('delete');

			await API.orders.delete(id);

			setWarningModal();
			history.replace('/panel/orders');
			return true;
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const changeType = async () => {
		try {
			setLoading('type');

			await API.orders.patch(id, { waste_type: wasteType });
			await fetch();

			setWasteType();
			setLoading();
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const changeAddress = async () => {
		try {
			setLoading('address');

			await API.orders.patch(id, { address });
			await fetch();

			setAddress();
			setLoading();
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const setAsPaid = async () => {
		try {
			setLoading('paid');

			await API.orders.patch(id, { payment: 1 });
			await fetch();

			setAddress();
			setLoading();
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const changeSize = async () => {
		try {
			setLoading('sizeId');

			await API.orders.patch(id, { size_id: sizeId });
			await fetch();

			setSizeId();
			setLoading();
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	return [
		{
			order,
			sizes,
			loading,
			dateModal,
			warningModal,
			price,
			priceType,
			paymentType,
			wasteType,
			address,
			sizeId,
			level: sessionStorage.getItem('level'),
		},
		{
			priceChanged: (e) => setPrice(e.target.value),
			invoiceChanged: (e) => setOrder((order) => ({ ...order, invoice: e.target.value })),
			priceTypeChanged: setPriceType,
			paymentTypeChanged: setPaymentType,
			changePrice,
			changeInvoice,
			changePaymentType,
			setDateModal,
			changeDeliveryDate,
			changeClaimingDate,
			setWarningModal,
			deleteOrder,
			typeChanged: setWasteType,
			changeType,
			addressChanged: (e) => setAddress(e.target.value),
			changeAddress,
			setAsPaid,
			setSizeId,
			changeSize,
		},
	];
}
