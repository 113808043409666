import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './src/app';

Sentry.init({
  dsn: "https://cd331d802ac5f9e8f9b7ce38d2b03b3f@o4507250139529216.ingest.de.sentry.io/4507555339239504",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production',
});

const mountNode = document.getElementById('app');
ReactDOM.render(<App />, mountNode);
