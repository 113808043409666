import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { Query } from '../../utils';
import API from '../../API';

export default function useClients() {
	const history = useHistory();
	const path = history.location.pathname;
	const visible = Query.decode(history.location.search).visible === '1';

	const [data, setData] = useState();
	const [filtered, setFiltered] = useState();
	const [filters, setFilters] = useState({});
	const [loading, setLoading] = useState();

	const fetch = async () => {
		try {
			const users = await API.users.getAll();
			const data = (await API.clients.getAll())
				.map((client) => ({
					...client,
					key: client.id,
					created_by: users.filter((u) => client.created_by === u.id)[0],
					removed_by: client.removed_by && users.filter((u) => client.removed_by === u.id)[0],
				}))
				.sort((a, b) => new Date(b.created) - new Date(a.created));

			setData(data);
		} catch (e) {
			message.error(`${e}`);
			console.error(e);
		}
	};

	useEffect(() => {
		fetch();
	}, [path]);

	useEffect(() => {
		setFiltered(
			data?.filter((c) => {
				if (filters.search) {
					if (`${c.name}`.toLowerCase().includes(filters.search.toLowerCase())) return true;
					if (`${c.phone}`.toLowerCase().includes(filters.search.toLowerCase())) return true;
					if (`${c.nip}`.toLowerCase().includes(filters.search.toLowerCase())) return true;
					if (`${c.bdo}`.toLowerCase().includes(filters.search.toLowerCase())) return true;
					if (`${c.email}`.toLowerCase().includes(filters.search.toLowerCase())) return true;
				} else return true;

				return false;
			})
		);
	}, [data, filters]);

	const deleteClient = async (id) => {
		try {
			setLoading(id);

			await API.clients.delete(id);
			await fetch();

			setLoading();
			message.success('Usunięto klienta');
		} catch (e) {
			message.error(`${e}`);
			setLoading();
			console.trace(e);
		}
	};

	const restoreClient = async (id) => {
		try {
			setLoading(id);

			await API.clients.patch(id, { removed: null });
			await fetch();

			setLoading();
			message.success('Przywrócono klienta');
		} catch (e) {
			message.error(`${e}`);
			setLoading();
			console.trace(e);
		}
	};

	return [
		{ data: visible ? filtered : filtered?.filter((e) => e.removed === null), filters, visible, loading },
		{
			toggleVisible: () => history.replace(`?visible=${visible ? 0 : 1}`),
			searchChanged: (e) => setFilters({ ...filters, search: e.target.value }),
			clearFilters: () => setFilters({}),
			deleteClient,
			restoreClient,
		},
	];
}
