import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Input, Select, Skeleton, Space, Timeline } from 'antd';
import {
	CheckOutlined,
	HistoryOutlined,
	DownloadOutlined,
	DeleteOutlined,
	UndoOutlined,
	ImportOutlined,
	RedoOutlined,
	ExportOutlined,
	DollarCircleOutlined,
	FireOutlined,
	UnlockOutlined,
	ClockCircleOutlined,
} from '@ant-design/icons';
import { blue } from '@ant-design/colors';
import { InfoModal, Page, PageSection, WarningModal } from '../../components';
import { useDriverDetails } from '../../reducers';
import { Formatting } from '../../utils';

import '../../styles/pages/drivers/driverDetails.scss';

const { Option } = Select;

const DriverDetails = ({ match }) => {
	const [deleteModal, setDeleteModal] = useState(false);
	const [passwordModal, setPasswordModal] = useState(false);
	const [infoModal, setInfoModal] = useState(false);
	const history = useHistory();

	const [
		{ user, removed, saveDisabled, saving, resetting, loading, password, timeline, deleteDisabled },
		{
			userNameChanged,
			userSurnameChanged,
			userLevelChanged,
			saveChanges,
			resetPassword,
			deleteUser,
			restoreUser,
			typeChanged,
		},
	] = useDriverDetails(match);

	const actions = removed
		? [
				<Button key={0} type="primary" size="large" icon={<UndoOutlined />} loading={loading} onClick={restoreUser}>
					Przywróć pracownika
				</Button>,
				<Button
					key={1}
					type="primary"
					size="large"
					icon={<ClockCircleOutlined />}
					onClick={() => history.push(`/panel/drivers/${user?.id}/card`)}
				>
					Pokaż kartę pracownika
				</Button>,
		  ]
		: [
				<Button
					key={2}
					type="danger"
					size="large"
					icon={<DeleteOutlined />}
					onClick={() => setDeleteModal(true)}
					disabled={deleteDisabled}
				>
					Usuń pracownika
				</Button>,
				<Button
					key={3}
					type="primary"
					size="large"
					icon={<ClockCircleOutlined />}
					onClick={() => history.push(`/panel/drivers/${user?.id}/card`)}
				>
					Pokaż kartę pracownika
				</Button>,
		  ];

	return (
		<>
			{user != undefined ? (
				<Page title={`Pracownicy / ${user.name} ${user.surname}`} actions={actions}>
					<div className="driver-details flex">
						<PageSection title="Informacje">
							<Space size={25} className="inputs">
								<div>
									<label>Imię</label>
									<Input
										type="text"
										size="large"
										defaultValue={user.name}
										onChange={userNameChanged}
										disabled={removed}
									/>
								</div>
								<div>
									<label>Nazwisko</label>
									<Input
										type="text"
										size="large"
										defaultValue={user.surname}
										onChange={userSurnameChanged}
										disabled={removed}
									/>
								</div>
							</Space>

							<Space size={25} className="inputs">
								<Select
									placeholder="Typ konta"
									size="large"
									labelInValue
									defaultValue={{ value: user.level }}
									onChange={userLevelChanged}
									disabled={removed}
								>
									<Option value={0}>Użytkownik</Option>
									<Option value={1}>Administrator</Option>
									<Option value={2}>Superadministrator</Option>
								</Select>
								<div>
									<label>Stanowisko</label>
									<Input type="text" size="large" defaultValue={user.type} onChange={typeChanged} disabled={removed} />
								</div>
							</Space>
							<Space size={25} className="inputs">
								<Button
									type="primary"
									size="large"
									icon={<CheckOutlined />}
									disabled={saveDisabled}
									onClick={saveChanges}
									loading={saving}
								>
									Zapisz zmiany
								</Button>
								<div></div>
							</Space>

							<Space size={25}>
								<div className="flex">
									<div className="flex flex-dir">
										<label>Stan konta:</label>
										<p className="user-data">{Formatting.money(user.balance)}</p>
									</div>
									{/* <Button type="primary" size="large" icon={<HistoryOutlined />}>
										Historia
									</Button> */}
								</div>
								<div className="flex">
									<div className="flex flex-dir">
										<label>Zalogowany:</label>
										<p className="user-data"> {user.logged === null ? '-' : Formatting.epochToDate(user.logged)}</p>
									</div>
									<Button
										type="primary"
										size="large"
										icon={<UnlockOutlined />}
										onClick={() => setPasswordModal(true)}
										disabled={deleteDisabled || removed}
									>
										Resetuj hasło
									</Button>
								</div>
							</Space>

							<Space size={25}>
								<table>
									<tbody>
										<tr>
											<td>
												Utworzony:<span className="user-data">{Formatting.epochToDate(user.created)}</span>
											</td>
										</tr>
										<tr>
											<td>
												Przez:
												<span className="user-data">
													<Link to={`/panel/drivers/${user.created_by.id}`}>
														{user.created_by.name} {user.created_by.surname}
													</Link>
												</span>
											</td>
										</tr>
									</tbody>
								</table>

								{removed && (
									<table>
										<tbody>
											<tr>
												<td>
													Usunięty: <span className="user-data">{Formatting.epochToDate(user.removed)}</span>{' '}
												</td>
											</tr>
											<tr>
												<td>
													Przez:
													<span className="user-data">
														<Link to={`/panel/drivers/${user.removed_by.id}`}>
															{user.removed_by.name} {user.removed_by.surname}
														</Link>
													</span>
												</td>
											</tr>
										</tbody>
									</table>
								)}
							</Space>
						</PageSection>
					</div>

					<WarningModal
						visible={deleteModal}
						onCancel={() => setDeleteModal(false)}
						onOk={() => deleteUser(() => setDeleteModal(false))}
						loading={loading}
					>
						<h1>Czy na pewno chcesz usunąć pracownika?</h1>
						<h3>Spowoduje to odebranie mu dostępu do portalu</h3>
					</WarningModal>

					<WarningModal
						visible={passwordModal}
						onCancel={() => setPasswordModal(false)}
						onOk={() =>
							resetPassword(() => {
								setPasswordModal(false);
								setInfoModal(true);
							})
						}
						loading={resetting}
					>
						<h1>Czy na pewno zresetować hasło?</h1>
						<h3>pracownika będzie musiał zalogować się używacjąc nowego hasła</h3>
					</WarningModal>

					<InfoModal visible={infoModal} onCancel={() => {}} onOk={() => setInfoModal(false)}>
						<h1>{password}</h1>
						<h3>Podaj to hasło pracownika, pozwoli mu ono na ustawienie nowego.</h3>
					</InfoModal>
				</Page>
			) : (
				<Page>
					<Skeleton active />
				</Page>
			)}
		</>
	);
};

export default DriverDetails;
