import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import API from '../../API';

export default function useContainerDeteails(match) {
	const history = useHistory();

	const [container, setContainer] = useState();
	const [sizes, setSizes] = useState();
	const [changes, setChanges] = useState({});
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [lend, setLend] = useState({});
	const [lending, setLending] = useState(false);
	const [adding, setAdding] = useState(false);

	const [timeline, setTimeline] = useState();

	const { id } = match.params;
	const admin = window.sessionStorage.getItem('level') > 0;

	useEffect(() => {
		const fetch = async () => {
			try {
				setChanges();

				const result = await API.containers.get(id);
				const sizes = await API.containers.sizes.getAll();

				setSizes(sizes);
				setTimeline(await API.containers.history(id, 30));
				setContainer({
					...result,
					size: sizes.filter((s) => s.id === result.size)[0],
					changed_by: admin && (await API.users.get(result.added_by)),
					added_by: admin && (await API.users.get(result.added_by)),
				});
			} catch (e) {
				console.trace(e);
			}
		};
		fetch();
	}, [match]);

	const checkChanges = () => {
		const diff = { ...changes };

		Object.entries(diff).forEach(([key, value]) => {
			if (value === container[key]) delete diff[key];
		});

		return Object.keys(diff).length === 0;
	};

	const saveChanges = async () => {
		try {
			setSaving(true);
			await API.containers.patch(id, { name: changes.name, size: changes.size, type: changes.type });
			setSaving(false);

			message.success('Zapisano zmiany');
			history.replace(`${id}`);
		} catch (e) {
			message.error(`${e}`);
			setSaving(false);
			console.trace(e);
		}
	};

	const deleteContainer = async (close) => {
		try {
			setLoading(true);
			await API.containers.delete(id);

			close();
			setLoading(false);
			message.success('Usunięto kontener');
			history.replace(`${id}`);
		} catch (e) {
			setLoading(false);
			message.error(`${e}`);
			console.trace(e);
		}
	};

	const restoreContainer = async () => {
		try {
			setLoading(true);

			await API.containers.patch(id, { state: 0 });

			setLoading(false);
			message.success('Przywrócono kontener');
			history.replace(`${id}`);
		} catch (e) {
			message.error(`${e}`);
			setLoading(false);
			console.trace(e);
		}
	};

	const lendContainer = async () => {
		if (lend.postal && !lend.postal.match(/^[0-9]{2}\-[0-9]{3}$/)) return message.error('Kod pocztowy niepoprawny');

		try {
			setLending(true);

			const lendDiff = { ...lend };

			Object.entries(lendDiff).forEach(([key, value]) => {
				if (!value) delete lendDiff[key];
			});

			await API.containers.patch(id, {
				state: 5,
				lend: lendDiff,
			});
			setLending(false);
			message.success('Wypożyczono kontener');
			setLend({});
			history.replace(`${id}`);
		} catch (e) {
			message.error(`${e}`);
			setLending(false);
			console.trace(e);
		}
	};

	const unlendContainer = async () => {
		try {
			setLending(true);

			await API.containers.patch(id, { state: 0 });
			setLending(false);
			message.success('Wrócono kontener');
			history.replace(`${id}`);
		} catch (e) {
			message.error(`${e}`);
			setLending(false);
			console.trace(e);
		}
	};

	const addContainerSize = async (size) => {
		try {
			setAdding(true);

			await API.containers.sizes.create(size);

			setAdding(false);
			message.success('Dodano rozmiar');
			history.replace(`${id}`);
		} catch (e) {
			message.error(`${e}`);
			setAdding(false);
			console.trace(e);
		}
	};

	return [
		{
			container,
			sizes,
			timeline,
			saveDisabled: checkChanges(),
			removed: false,
			loading,
			saving,
			lending,
			adding,
			canLend: lend.name,
			deleteDisabled: container?.state !== 0,
		},
		{
			numberChanged: (e) => setChanges({ ...changes, name: e.target.value }),
			typeChanged: (e) => setChanges({ ...changes, type: e.target.value }),
			sizeChanged: (value) => setChanges({ ...changes, size: value }),
			nameChanged: (e) => setLend({ ...lend, name: e.target.value }),
			streetChanged: (e) => setLend({ ...lend, street: e.target.value }),
			postalChanged: (e) => setLend({ ...lend, postal: e.target.value }),
			cityChanged: (e) => setLend({ ...lend, city: e.target.value }),
			saveChanges,
			deleteContainer,
			restoreContainer,
			lendContainer,
			unlendContainer,
			addContainerSize,
		},
	];
}
