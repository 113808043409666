import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTaskClosing } from '../../reducers';
import TaskClosingBody from './taskClosingBody';
import Sign from './sign';
import SignContext from './signContext';

import '../../styles/pages/tasks/taskClosing.scss';

const TaskClosingScreen = () => {
	return (
		<SignContext.Provider value={useTaskClosing()}>
			<Switch>
				<Route path="/panel/task-closing/:id/:type" exact component={TaskClosingBody} />
				<Route path="/panel/task-closing-sign" exact component={Sign} />
			</Switch>
		</SignContext.Provider>
	);
};

export default TaskClosingScreen;
