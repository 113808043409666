import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PanelStatsDataProvider } from '../dataProviders';
import API from '../API';

export default function useLogin() {
	const history = useHistory();

	const [username, setUsername] = useState(window.localStorage.getItem('username'));
	const [password, setPassword] = useState('');
	const [inputType, setInputType] = useState('password');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		if (history.location.pathname !== '/logout') return;

		API.logout();
	}, [history.location.pathname]);

	const message = history.location.pathname === '/logout' ? 'Wylogowano' : '';

	const passwordChange = () => {
		setLoading(false);

		history.push('/passchange', password);
	};

	const enter = async () => {
		const stats = await PanelStatsDataProvider.getStats();
		// await import('../pages/panel');

		setLoading(false);

		history.push('/panel', { stats });
	};

	const validate = () => {
		setError('');

		if (!username) setError('Podaj login');
		else if (!password) setError('Podaj hasło');
		else return true;
	};

	const handleLogin = async (e) => {
		e.preventDefault();

		if (!validate()) return;

		try {
			setLoading(true);

			const result = await API.login(username, password);
			sessionStorage.setItem('level', result.level);
			sessionStorage.setItem('id', result.id);
			localStorage.setItem('username', username);

			Sentry.setUser({
				id: result.id,
				username,
			});

			if (result.passwordChange === true) return passwordChange();
			else return await enter();
		} catch (e) {
			setLoading(false);

			console.trace(e);
			setError(`${e}`);
		}
	};

	return [
		{ username, password, inputType, loading, message: error || message, error },
		{
			usernameChanged: (e) => setUsername(e.target.value),
			passwordChanged: (e) => setPassword(e.target.value),
			touchStart: (e) => {
				e.stopPropagation();

				setInputType('text');
			},
			touchMove: (e) => {
				e.stopPropagation();

				setInputType('password');
			},
			touchEnd: (e) => {
				e.preventDefault();
				e.stopPropagation();

				setInputType('password');
			},
			login: handleLogin,
		},
	];
}
