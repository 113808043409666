import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CloseCircleOutlined, ExportOutlined, ImportOutlined, RedoOutlined } from '@ant-design/icons';
import { blue } from '@ant-design/colors';
import { PanelStatsDataProvider } from '../dataProviders';
import API from '../API';

export default function usePanel() {
	const history = useHistory();
	const [stats, setStats] = useState(history?.location?.state?.stats);
	const [showToClaim, setShowToClaim] = useState(false);
	const [showUnclosed, setShowUnclosed] = useState(false);
	const [balance, setBalance] = useState(0);

	const fetch = async () => {
		setStats(await PanelStatsDataProvider.getStats());

		const userId = sessionStorage.getItem('id');
		if (!userId) return;

		const user = await API.users.get(userId);
		setBalance(user.balance);
	};

	useEffect(() => {
		if (history.location.pathname !== '/panel') return;

		fetch();

		const interval = setInterval(fetch, 30 * 1000);

		return () => clearInterval(interval);
	}, [history.location]);

	const getTaskIcon = (task) => {
		if (!task) return <CloseCircleOutlined style={{ color: blue[5] }} />;

		switch (task.type) {
			// Delivery
			case 0:
				return <ImportOutlined style={{ color: blue[5] }} />;

			// Replacement
			case 1:
				return <RedoOutlined style={{ color: blue[5] }} />;

			// Claiming
			case 2:
				return <ExportOutlined style={{ color: blue[5] }} />;
		}
	};

	const getTaskDescription = (task) => {
		switch (task.type) {
			// Delivery
			case 0:
				return `Podstawienie ${task.order.street}`;

			// Replacement
			case 1:
				return `Wymiana ${task.order.street}`;

			// Claiming
			case 2:
				return `Odbiór ${task.order.street}`;
		}
	};

	return [
		{
			stats,
			balance,
			admin: sessionStorage.getItem('level') > 0,
			level: sessionStorage.getItem('level'),
			showToClaim,
			showUnclosed,
		},
		{ getTaskIcon, getTaskDescription, setShowToClaim, setShowUnclosed },
	];
}
