import { Query } from './utils';
import moment from 'moment';

const send = async (method, endpoint, data, query) => {
	const response = await fetch(`${process.env.API}/${endpoint}${query ? '?' + Query.encode(query) : ''}`, {
		method: method,
		headers: {
			'Content-Type': data ? 'application/json' : 'plain/text',
		},
		body: data ? JSON.stringify(data) : undefined,
		credentials: 'include',
	});

	if (endpoint !== 'logout' && response.status === 403) {
		window.location = '/logout';

		throw 'Sesja wygasła';
	}

	if (response.headers.get('content-type')?.match(/application\/json/g)) {
		if (response.status !== 200) throw await response.json();
		return await response.json();
	} else {
		if (response.status !== 200) throw await response.text();
		return await response.text();
	}
};

export default {
	login: async (username, password) => {
		try {
			return await send('POST', 'login', { username, password });
		} catch (e) {
			switch (e.error) {
				case 1:
					throw 'Niepoprawne dane logowania';
				default:
					throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		}
	},

	logout: async () => {
		try {
			return await send('GET', 'logout');
		} catch (e) {
			switch (e.error) {
				default:
					throw `Nieznany błąd ${e.message || e.error || e}`;
			}
		}
	},

	users: {
		get: async (id, unsettled) => {
			try {
				return await send('GET', `users/${id}`, undefined, unsettled ? { unsettled } : undefined);
			} catch (e) {
				switch (e.error) {
					case 3:
						throw 3;
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		getAll: async (unsettled) => {
			try {
				return await send('GET', `users`, undefined, unsettled ? { unsettled } : undefined);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		create: async (user_data) => {
			try {
				return await send('PUT', `users`, user_data);
			} catch (e) {
				switch (e.error) {
					case 1:
						throw 'Nazwa użytkownika jest zajęta';
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		patch: async (id, data) => {
			try {
				return await send('PATCH', `users/${id}`, data);
			} catch (e) {
				switch (e.error) {
					case 1:
						throw 'Nazwa użytkownika zjęta';
					case 2:
						throw 'Hasło zbyt krótkie';
					case 3:
						throw 'Stare hasło jest nieprawidłowe';
					case 4:
						throw 'Nowe hasło nie może być takie samo jak stare';
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		delete: async (id) => {
			try {
				return await send('DELETE', `users/${id}`);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		history: async (id, limit) => {
			try {
				return await send('GET', `users/${id}/history`, undefined, { limit });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		cards: {
			getAll: async ({ after, before, user_id }) => {
				try {
					return await send('GET', `users/cards`, undefined, { after, before, user_id });
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			put: async ({ mileage_start, mileage_end, time, plate, date }) => {
				try {
					return await send('PUT', `users/cards`, { mileage_start, mileage_end, time, plate, date });
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},
		},
	},

	containers: {
		sizes: {
			getAll: async () => {
				try {
					return await send('GET', 'containers/sizes');
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			create: async (name) => {
				try {
					return await send('PUT', 'containers/sizes', { name });
				} catch (e) {
					switch (e.error) {
						case 1:
							throw 'Taki rozmiar kontenera już ustnieje';
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			change: async (id, name) => {
				try {
					return await send('PATCH', `containers/sizes/${id}`, { name });
				} catch (e) {
					switch (e.error) {
						case 1:
							throw 'Taki rozmiar kontenera już ustnieje';
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			delete: async (id) => {
				try {
					return await send('DELETE', `containers/sizes/${id}`);
				} catch (e) {
					switch (e.error) {
						case 1:
							throw 'Nie ma takiego rozmiaru';
						case 2:
							throw 'Rozmiar jest przypisany do kontenera';
						case 3:
							throw 'Rozmiar jest przypisany do zamówienia';
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},
		},

		get: async (id) => {
			try {
				return await send('GET', `containers/${id}`);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		getAll: async (states) => {
			try {
				return await send('GET', 'containers', undefined, states ? { states } : undefined);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		create: async (user_data) => {
			try {
				return await send('PUT', `containers`, user_data);
			} catch (e) {
				switch (e.error) {
					case 4:
						throw 'Numer kontenera już zajęty';
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		patch: async (id, data) => {
			try {
				return await send('PATCH', `containers/${id}`, data);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		delete: async (id) => {
			try {
				return await send('DELETE', `containers/${id}`);
			} catch (e) {
				switch (e.error) {
					case 3:
						throw 'Kontener jest przypisany do zadania';
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		history: async (id, limit) => {
			try {
				return await send('GET', `containers/${id}/history`, undefined, { limit });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
	},

	clients: {
		getAll: async () => {
			try {
				return await send('GET', `clients`);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		get: async (id) => {
			try {
				return await send('GET', `clients/${id}`);
			} catch (e) {
				switch (e.error) {
					case 3:
						throw 3;
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		patch: async (id, data) => {
			try {
				return await send('PATCH', `clients/${id}`, data);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		create: async (client_data) => {
			try {
				return await send('PUT', `clients`, client_data);
			} catch (e) {
				switch (e.error) {
					case 1:
						throw 'Nazwa klienta jest zajęta';
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		delete: async (id) => {
			try {
				return await send('DELETE', `clients/${id}`);
			} catch (e) {
				switch (e.error) {
					case 2:
						throw 'Klient ma co najmniej jedno otwarte zlecenie';
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		history: async (id) => {
			try {
				return await send('GET', `clients/${id}/history`);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
	},

	expenses: {
		getAll: async ({ user_id, before, after, company }) => {
			try {
				return await send('GET', `expenses`, undefined, { user_id, before, after, company });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		create: async (expense_data) => {
			try {
				return await send('PUT', `expenses`, expense_data);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		settle: async (id) => {
			try {
				return await send('PATCH', `expenses/${id}`, { settled: 1 });
			} catch (e) {
				switch (e.error) {
					case 2:
						throw `Wydatek jest już rozliczony`;
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		expenseTypes: {
			getAll: async () => {
				try {
					return await send('GET', `expenseTypes`);
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			put: async (name) => {
				try {
					return await send('PUT', `expenseTypes`, { name });
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},
		},

		settlements: {
			getAll: async ({ user_id, before, after }) => {
				try {
					return await send('GET', `settlements`, undefined, { user_id, before, after });
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},
			create: async (settlement) => {
				try {
					return await send('PUT', `settlements`, settlement);
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},
		},
	},

	orders: {
		getAll: async ({ client_id, after, before, closed, advances }) => {
			try {
				return await send('GET', `orders`, undefined, { client_id, after, before, closed, advances });
			} catch (e) {
				switch (e.error) {
					case 3:
						throw 3;
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		get: async (id) => {
			try {
				return await send('GET', `orders/${id}`);
			} catch (e) {
				switch (e.error) {
					case 3:
						throw 3;
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		create: async (order_data) => {
			try {
				return await send('PUT', 'orders', order_data);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		patch: async (id, data) => {
			try {
				return await send('PATCH', `orders/${id}`, data);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		delete: async (id) => {
			try {
				return await send('DELETE', `orders/${id}`);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		owns: {
			getAll: async ({ after, before, client_id, to }) => {
				try {
					return await send('GET', 'owns', undefined, { before, after, client_id, to });
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			get: async (id) => {
				try {
					return await send('GET', `owns/${id}`);
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			put: async ({ client_id, address, to, date }) => {
				try {
					return await send('PUT', 'owns', { client_id, address, to, date });
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			patch: async (id, { weight, date }) => {
				try {
					return await send('PATCH', `owns/${id}`, { weight, date });
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			contractors: {
				getAll: async () => {
					try {
						return await send('GET', `owns/contractors`);
					} catch (e) {
						switch (e.error) {
							default:
								throw `Nieznany błąd ${e.message || e.error || e}`;
						}
					}
				},
			},
		},

		incomes: {
			getAll: async ({ after, before, client_id }) => {
				try {
					return await send('GET', 'incomes', undefined, { before, after, client_id });
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			get: async (id) => {
				try {
					return await send('GET', `incomes/${id}`);
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			put: async ({ client_id, name, value, value_type, invoice, date }) => {
				try {
					return await send('PUT', 'incomes', { client_id, name, value, value_type, invoice, date });
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},

			patch: async (id, { name, value, value_type, invoice, date }) => {
				try {
					return await send('PATCH', `incomes/${id}`, { name, value, value_type, invoice, date });
				} catch (e) {
					switch (e.error) {
						default:
							throw `Nieznany błąd ${e.message || e.error || e}`;
					}
				}
			},
		},
	},

	tasks: {
		getAll: async ({ after, before, user_id, advances }) => {
			try {
				return await send('GET', `tasks`, undefined, { after, before, user_id, advances });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
		get: async (id) => {
			try {
				return await send('GET', `tasks/${id}`);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
		patch: async (id, data) => {
			try {
				return await send('PATCH', `tasks/${id}`, data);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
		put: async ({ size_id, order_id, date, claiming }) => {
			try {
				return await send('PUT', `tasks`, { size_id, order_id, date, claiming });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
		delete: async (id) => {
			try {
				return await send('DELETE', `tasks/${id}`);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
		toClaim: async (id, date) => {
			try {
				return await send('POST', `tasks/${id}/claim`, { date });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
		toReplace: async (id, date, claiming) => {
			try {
				return await send('POST', `tasks/${id}/replace`, { date, claiming });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
	},

	stats: {
		panel: async () => {
			try {
				return await send('GET', 'stats/panel', undefined, {
					day_start: moment().hours(0).minutes(0).seconds(0).subtract(1, 'second').unix() * 1000,
					day_end: moment().hours(23).minutes(59).seconds(59).unix() * 1000,
				});
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		summary: async (from, to) => {
			try {
				return await send('GET', 'stats/summary', undefined, { from, to });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		incomes: async ({ after, before }) => {
			try {
				return await send('GET', 'stats/incomes', undefined, { after, before });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
	},

	addresses: {
		getAll: async ({ clientId }) => {
			try {
				return await send('GET', 'addresses', undefined, { client_id: clientId });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		add: async ({ name, clientId }) => {
			try {
				return await send('PUT', 'addresses', { name, client_id: clientId });
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},

		delete: async (id) => {
			try {
				return await send('DELETE', `addresses/${id}`);
			} catch (e) {
				switch (e.error) {
					default:
						throw `Nieznany błąd ${e.message || e.error || e}`;
				}
			}
		},
	},
};
