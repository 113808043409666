import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { message } from 'antd';
import API from '../../../../../API';
import moment from 'moment';

export default function useNewOther() {
	const history = useHistory();

	const [clients, setClients] = useState();
	const [loading, setLoading] = useState();
	const [other, setOther] = useState({ date: moment(), value_type: 0 });

	const client = useMemo(() => clients?.filter((c) => c.name === other.client)[0], [clients, other.client]);

	const fetch = async () => {
		try {
			setClients(await API.clients.getAll());
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const addOther = async () => {
		try {
			setLoading(1);

			other.date
				.hour(other.time?.hour ?? 0)
				.minute(other.time?.minute ?? 0)
				.second(0);

			const { name, value, value_type, invoice, date } = other;
			const o = await API.orders.incomes.put({
				client_id: client.id,
				name,
				value,
				value_type,
				invoice,
				date: date.unix() * 1000,
			});

			setLoading();
			message.success('Dodano zamówienie');
			history.replace(`/panel/orders/${o.id}/2`);
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	const disabled = useMemo(() => {
		if (!client) return true;
		if (!other.name || !other.name.trim()) return true;
		if (!other.date) return true;
		if (isNaN(other.value)) return true;
		if (isNaN(other.value_type)) return true;

		return false;
	}, [client, other.name, other.date, other.value, other.value_type]);

	return [
		{
			clients,
			other,
			loading,
			disabled,
		},
		{
			clientChanged: (client) => setOther((other) => ({ ...other, client })),
			nameChanged: (e) => setOther((other) => ({ ...other, name: e.target.value })),
			dateChanged: (date) => setOther((own) => ({ ...own, date })),
			timeChanged: (time) => setOther((own) => ({ ...own, time })),
			invoiceChanged: (e) => setOther((other) => ({ ...other, invoice: e.target.value })),
			valueChanged: (e) => setOther((other) => ({ ...other, value: e.target.value })),
			valueTypeChanged: (value_type) => setOther((other) => ({ ...other, value_type })),
			addOther,
		},
	];
}
