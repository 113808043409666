import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { AutoComplete, Button, DatePicker, Select, Skeleton, Space, Table } from 'antd';
import { CloseCircleOutlined, EyeInvisibleOutlined, EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { gold, green, grey, purple, red } from '@ant-design/colors';
import { Page, WarningModal } from '../components';
import { Formatting } from '../utils';
import { useFinances } from '../reducers';
import { FinancesPrint } from '../prints';
import styled from 'styled-components';

const OrdersTable = styled.div`
	flex: 1;
	height: 1px;

	> .ant-table-wrapper {
		height: 100%;

		.ant-table-row {
			&.red {
				> td:first-child {
					border-left: 8px solid ${red[5]};
				}
			}

			&.yellow {
				> td:first-child {
					border-left: 8px solid ${gold[5]};
				}
			}

			&.green {
				> td:first-child {
					border-left: 8px solid ${green[5]};
				}
			}

			&.purple {
				> td:first-child {
					border-left: 8px solid ${purple[5]};
				}
			}

			&.grey {
				> td:first-child {
					border-left: 8px solid ${grey[5]};
				}
			}
		}
	}
`;

const Finances = () => {
	const [warning, setWarning] = useState();
	const [
		{ clients, orders, unclosed, from, to, client, paid, type },
		{ toggleUnclosed, fromChanged, toChanged, clientChanged, paidChanged, typeChanged, clearFilters },
	] = useFinances();

	const columns = [
		{
			title: 'Klient',
			dataIndex: 'client',
			key: 'name',
			render: (client) => client && <Link to={`/panel/clients/${client.id}`}>{client.name}</Link>,
		},
		{
			title: 'Cena',
			dataIndex: 'price',
			key: 'price',
			render: (price, row) => (
				<>
					{Formatting.money(price)} ({row.price_type ? 'br' : 'nt'})
				</>
			),
		},
		{
			title: 'Faktura',
			dataIndex: 'invoice',
			key: 'invoice',
			render: (invoice) => invoice ?? '-',
		},
		{
			title: 'Typ płatności',
			dataIndex: 'payment_type',
			key: 'payment_type',
			render: (payment_type) => (payment_type ? 'Gotówka' : 'Przelew'),
		},
		{
			title: 'Data podstawienia',
			dataIndex: 'delivery_done',
			key: 'delivery_done',
			render: (date) => Formatting.epochToDate(date, true),
		},
		{
			title: 'Data odbioru',
			dataIndex: 'claiming_done',
			key: 'claiming_done',
			render: (date) => Formatting.epochToDate(date, true),
		},
		{
			title: 'Akcje',
			dataIndex: 'actions',
			key: 'actions',
			render: (_, row) => <Link to={`/panel/orders/${row.id}`}>Szczegóły</Link>,
		},
	];

	const getRowClassName = (row) => {
		if (row.invoice) return 'grey';

		if (row.payment) return 'green';

		if (row.claiming_done) return 'red';
	};

	const actions = [
		<Button
			key="1"
			size="large"
			type="primary"
			icon={closed ? <EyeInvisibleOutlined /> : <EyeOutlined />}
			onClick={toggleUnclosed}
		>
			{unclosed ? 'Ukryj' : 'Pokaż'} otwarte zamówienia
		</Button>,
		<Button
			key="2"
			size="large"
			type="primary"
			icon={<PrinterOutlined />}
			onClick={() => (unclosed ? print() : setWarning(1))}
		>
			Drukuj
		</Button>,
	];

	const printRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});
	const print = () => {
		handlePrint();
		setWarning();
	};

	return (
		<Page title="Zamówienia" actions={actions}>
			{clients ? (
				<div className="orders flex flex-dir">
					<div className="inputs flex flex-dir">
						<AutoComplete
							size="large"
							placeholder="Klient"
							options={clients.map((d) => ({ value: d.name }))}
							filterOption={(inputValue, option) => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1}
							value={client}
							onSelect={clientChanged}
							onChange={clientChanged}
						/>
						<Space size={25}>
							<DatePicker size="large" value={from} onChange={fromChanged} allowClear={false} />
							<DatePicker size="large" value={to} onChange={toChanged} allowClear={false} />
						</Space>
						<Space size={25}>
							<Select size="large" placeholder="Status" value={paid} onChange={paidChanged}>
								<Select.Option value={1}>Zapłacone</Select.Option>
								<Select.Option value={0}>Niezapłacone</Select.Option>
							</Select>
							<Select size="large" placeholder="Typ płatności" value={type} onChange={typeChanged}>
								<Select.Option value={0}>Przelew</Select.Option>
								<Select.Option value={1}>Gotówka</Select.Option>
							</Select>
						</Space>
						<Button size="large" type="primary" icon={<CloseCircleOutlined />} onClick={clearFilters}>
							Wyczyść
						</Button>
					</div>

					<OrdersTable>
						<Table
							columns={columns}
							rowClassName={getRowClassName}
							dataSource={orders}
							pagination={false}
							loading={!orders}
							scroll={{ x: 'max-content' }}
						/>
					</OrdersTable>

					<FinancesPrint ref={printRef} orders={orders} unclosed={unclosed} from={from} to={to} />

					<WarningModal visible={warning} onCancel={() => setWarning()} onOk={print}>
						<h1>Nie wszystkie zamówienia zostały wybrane.</h1>
						<h3>Otwarte zamówienia zostaną pominięte w wydruku.</h3>
						<h3>Wydrukować mimo to?</h3>
					</WarningModal>
				</div>
			) : (
				<Skeleton active />
			)}
		</Page>
	);
};

export default Finances;
