import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { blue } from '@ant-design/colors';
import { Formatting } from '../../../../../utils';
import styled from 'styled-components';
import moment from 'moment';

const DataTable = styled.table`
	width: 100%;

	tr {
		width: 100%;
	}

	td {
		width: 50%;
		padding-bottom: 30px;

		> p {
			padding-bottom: 5px;

			.user-data {
				font-size: 24px;
				font-weight: 400;
				color: ${blue[6]};
			}
		}

		&:last-child {
			padding-left: 12.5px;
		}
	}
`;

const ClientInfo = ({ client, name, own }) => {
	const history = useHistory();

	return (
		<DataTable>
			{own ? (
				<>
					<tbody>
						{name && (
							<tr>
								<td>
									<p>Klient</p>
									<p>
										<span className="user-data">
											<Link to={`/panel/clients/${own.client?.id}`}>{own.client?.name}</Link>
										</span>
									</p>
								</td>
								<td>
									<p>Do</p>
									<p>
										<span className="user-data">{own.to}</span>
									</p>
								</td>
							</tr>
						)}
						<tr>
							<td>
								<p>Adres</p>
								<p>
									<span className="user-data">{own.address}</span>
								</p>
							</td>
							<td>
								<p>Data</p>
								<p>
									<span className="user-data">{Formatting.epochToDate(own.date, moment(own.date).hours() === 0)}</span>
								</p>
							</td>
						</tr>
						{own.closed && (
							<>
								<tr>
									<td>
										<p>Waga</p>
										<p>
											<span className="user-data">{Formatting.insertSpaces(own.weight)} kg</span>
										</p>
									</td>
									<td></td>
								</tr>
								<tr>
									<td>
										<p>Zamknięte</p>
										<p>
											<span className="user-data">{Formatting.epochToDate(own.closed)}</span>
										</p>
									</td>
									<td>
										<p>Przez</p>
										<p>
											<span className="user-data">
												<Link to={`/panel/drivers/${own.closed_by?.id}`}>
													{own.closed_by?.name} {own.closed_by?.surname}
												</Link>
											</span>
										</p>
									</td>
								</tr>
							</>
						)}
					</tbody>
				</>
			) : (
				<>
					<tbody>
						{name && (
							<tr>
								<td>
									<p>Klient</p>
									<p>
										<span className="user-data">{client.name}</span>
									</p>
								</td>
								<td></td>
							</tr>
						)}
						<tr>
							<td>
								<p>Typ</p>
								<p>
									<span className="user-data">{client.type ? 'Osobna prywatna' : 'Firma'}</span>
								</p>
							</td>
							<td>
								<p>Numer BDO</p>
								<p>
									<span className="user-data">{client.bdo ?? '-'}</span>
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>Numer telefonu</p>
								<p>
									<span className="user-data">{client.phone}</span>
								</p>
							</td>
							<td>
								<p>Email</p>
								<p>
									<span className="user-data">{client.email ?? '-'}</span>
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>Numer Nip</p>
								<p>
									<span className="user-data">{client.nip ?? '-'}</span>
								</p>
							</td>
							<td>
								<Button
									type="primary"
									size="large"
									icon={<InfoCircleOutlined />}
									onClick={() => history.push(`/panel/clients/${client.id}`)}
								>
									Szczegóły klienta
								</Button>
							</td>
						</tr>
					</tbody>
				</>
			)}
		</DataTable>
	);
};

export default ClientInfo;
