import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import API from '../API';

export default function useFastActions() {
	const [containers, setContainers] = useState();
	const [loading, setLoading] = useState();
	const [search, setSearch] = useState();
	const [dateModal, setDateModal] = useState();

	const fetch = async () => {
		try {
			const sizes = await API.containers.sizes.getAll();

			const data = (await API.containers.getAll(1))
				.filter((c) => c.state.state !== 0 && c.state.state !== 6 && c.state.state !== 5)
				.map((container, i) => ({
					...container,
					key: i,
					id: container.id,
					size: sizes.filter((e) => e.id === container.size)[0],
				}))
				.sort((a, b) => new Date(b.changed) - new Date(a.changed));

			setContainers(data);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const filtered = useMemo(() => {
		if (!search) return containers;

		return containers.filter((c) => {
			if (`${c.name}`.toLowerCase().includes(search.toLowerCase())) return true;

			if (`${c.order?.client?.name}`.toLowerCase().includes(search.toLowerCase())) return true;

			if (`${c.order?.address}`.toLowerCase().includes(search.toLowerCase())) return true;

			return false;
		});
	}, [containers, search]);

	const changeDate = async (row, date) => {
		try {
			setLoading('date');

			if (row.order.related_id > row.order.id) {
				// Replacement

				await API.orders.patch(row.order.id, { delivery_date: date.unix() * 1000 });
				await API.orders.patch(row.order.related_id, { claiming_date: date.unix() * 1000 });
			} else {
				// Claiming

				await API.orders.patch(row.order.id, { claiming_date: date === null ? null : date.unix() * 1000 });
			}

			await fetch();

			setDateModal();
			setLoading();
			return true;
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
			setLoading();
		}
	};

	return [
		{ containers: filtered, loading, search, dateModal },
		{
			searchChanged: (e) => setSearch(e.target.value),
			clearSearch: () => setSearch(),
			setDateModal,
			changeDate,
		},
	];
}
