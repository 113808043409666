import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, DatePicker, Input, Select, Space, Table } from 'antd';
import {
	CloseCircleOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
	PlusCircleOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { gold, green, purple, red } from '@ant-design/colors';
import { Page } from '../../components';
import { useOrders } from '../../reducers';
import { Formatting } from '../../utils';
import styled from 'styled-components';
import moment from 'moment';

import '../../styles/pages/orders/orders.scss';

const OrdersTable = styled.div`
	flex: 1;
	height: 1px;

	> .ant-table-wrapper {
		height: 100%;

		.ant-table-row {
			&.red {
				> td:first-child {
					border-left: 8px solid ${red[5]};
				}
			}

			&.yellow {
				> td:first-child {
					border-left: 8px solid ${gold[5]};
				}
			}

			&.green {
				> td:first-child {
					border-left: 8px solid ${green[5]};
				}
			}

			&.purple {
				> td:first-child {
					border-left: 8px solid ${purple[5]};
				}
			}
		}
	}
`;

const Orders = () => {
	const history = useHistory();

	const [
		{ orders, closed, filters, from, to, admin },
		{ toggleClosed, clearFilters, searchChanged, setFrom, setTo },
	] = useOrders();

	const columns = [
		{
			title: 'Klient',
			dataIndex: 'client',
			key: 'name',
			render: (client) =>
				client && (admin ? <Link to={`/panel/clients/${client.id}`}>{client.name}</Link> : client.name),
		},
		{
			title: 'Telefon',
			dataIndex: 'client',
			key: 'phone',
			render: (client) => client?.phone,
		},
		{
			title: 'Adres',
			dataIndex: 'address',
			key: 'address',
		},
		{
			title: 'Data',
			dataIndex: 'delivery_date',
			key: 'date',
			render: (date) => Formatting.epochToDate(date, moment(date).hours() === 0),
		},
		{
			title: 'Odbiór',
			dataIndex: 'claiming_date',
			key: 'claiming',
			render: (date) => (date === null ? '-' : Formatting.epochToDate(date, moment(date).hours() === 0)),
		},
		{
			title: 'Typ odpadów',
			dataIndex: 'waste_type',
			key: 'waste_type',
		},
		{
			title: 'Akcje',
			dataIndex: 'actions',
			key: 'actions',
			render: (_, row) => <Link to={`/panel/orders/${row.id}`}>Szczegóły</Link>,
		},
	];

	const getRowClassName = (row, i) => {
		if (row.invoice) return 'green';

		if (row.payment) return 'yellow';

		if (row.claiming_done) return ' red';

		if (row.related_id) return 'purple';
	};

	const actions = [
		<Button
			key="0"
			size="large"
			type="primary"
			icon={<PlusCircleOutlined />}
			onClick={() => history.push('/panel/new-order')}
		>
			Dodaj zamówienie
		</Button>,
		<Button
			key="1"
			size="large"
			type="primary"
			icon={closed ? <EyeInvisibleOutlined /> : <EyeOutlined />}
			onClick={toggleClosed}
		>
			{closed ? 'Ukryj' : 'Pokaż'} zamknięte zamówienia
		</Button>,
	];

	return (
		<Page title="Zamówienia" actions={actions}>
			<div className="orders flex flex-dir">
				<div className="inputs flex flex-dir">
					<Input
						size="large"
						type="text"
						placeholder="Wyszukaj"
						suffix={<SearchOutlined />}
						value={filters.search}
						onChange={searchChanged}
					/>
					<Space size={25}>
						<DatePicker size="large" value={from} onChange={setFrom} allowClear={false} />
						<DatePicker size="large" value={to} onChange={setTo} allowClear={false} />
					</Space>
					<Button size="large" type="primary" icon={<CloseCircleOutlined />} onClick={clearFilters}>
						Wyczyść
					</Button>
				</div>

				<OrdersTable>
					<Table
						columns={columns}
						rowClassName={getRowClassName}
						dataSource={orders}
						pagination={false}
						scroll={{ x: 'max-content' }}
					/>
				</OrdersTable>
			</div>
		</Page>
	);
};

export default Orders;
