import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { Query } from '../../utils';
import API from '../../API';

export default function useDrivers() {
	const history = useHistory();
	const path = history.location.pathname;
	const visible = Query.decode(history.location.search).visible === '1';

	const [data, setData] = useState();
	const [filtered, setFiltered] = useState();
	const [filters, setFilters] = useState({});
	const [loading, setLoading] = useState();

	useEffect(() => {
		const fetch = async () => {
			try {
				const data = (await API.users.getAll(true))
					.map((user, i, users) => ({
						...user,
						key: user.id,
						created_by: users.filter((u) => user.created_by === u.id)[0],
						removed_by: user.removed_by && users.filter((u) => user.removed_by === u.id)[0],
					}))
					.sort((a, b) => new Date(b.removed || b.created) - new Date(a.removed || a.created));

				setData(data);
			} catch (e) {
				message.error(`${e}`);
				console.error(e);
			}
		};

		fetch();
	}, [path]);

	useEffect(() => {
		setFiltered(
			data?.filter((u) => {
				if (filters.search) {
					if (!`${u.name} ${u.surname}`.toLowerCase().includes(filters.search.toLowerCase())) return false;
				}

				if (filters.level != undefined && u.level !== filters.level) return false;

				return true;
			})
		);
	}, [data, filters]);

	const removeUser = async (id) => {
		try {
			setLoading(id);

			await API.users.delete(id);
			const user = await API.users.get(id, 1);
			user.key = id;
			user.created_by = await API.users.get(user.created_by);
			user.removed_by = await API.users.get(user.removed_by);

			setLoading();
			setData((data) =>
				[...data.filter((e) => e.id !== id), user].sort(
					(a, b) => new Date(b.removed || b.created) - new Date(a.removed || a.created)
				)
			);
			message.success('Usunięto użytkownika');
		} catch (e) {
			message.error(`${e}`);
			setLoading();
			console.trace(e);
		}
	};

	const restoreUser = async (id) => {
		try {
			setLoading(id);

			const user = await API.users.patch(id, { removed: null });
			user.key = id;
			user.created_by = await API.users.get(user.created_by);

			setLoading();
			setData((data) =>
				[...data.filter((e) => e.id !== id), user].sort(
					(a, b) => new Date(b.removed || b.created) - new Date(a.removed || a.created)
				)
			);
			message.success('Przywrócono użytkownika');
		} catch (e) {
			message.error(`${e}`);
			setLoading();
			console.trace(e);
		}
	};

	return [
		{ data: visible ? filtered : filtered?.filter((e) => e.removed === null), visible, filters, loading },
		{
			toggleVisible: () => history.replace(`?visible=${visible ? 0 : 1}`),
			removeUser,
			restoreUser,
			searchChanged: (e) => setFilters({ ...filters, search: e.target.value }),
			levelChanged: (v) => setFilters({ ...filters, level: v }),
			clearFilters: () => setFilters({}),
		},
	];
}
