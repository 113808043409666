import React from 'react';
import { Input, Space, Button } from 'antd';
import { EyeOutlined, LockOutlined, SearchOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';
import useLogin from '../reducers/useLogin';

import '../styles/pages/login.scss';
import Blob from '../svg/blob.svg';
import Blob2 from '../svg/blob2.svg';
import StarBlob from '../svg/star-blob.svg';

const Login = () => {
	const [
		{ username, password, inputType, loading, message, error },
		{ usernameChanged, passwordChanged, touchStart, touchMove, touchEnd, login },
	] = useLogin();

	return (
		<>
			<div className="login-wrapper flex flex-dir flex-jc">
				<Blob />

				<form onSubmit={login} className="login flex flex-dir flex-ac">
					<h1>Zaloguj się</h1>

					<p style={{ color: error ? red[5] : undefined }}>{message}</p>

					<Space size={25} direction={'vertical'}>
						<Input
							size="large"
							placeholder="Nazwa użytkownika"
							prefix={<SearchOutlined style={{ color: '#bfbfbf' }} />}
							value={username}
							onChange={usernameChanged}
						/>

						<Input
							size="large"
							type={inputType}
							placeholder="Hasło"
							prefix={<LockOutlined style={{ color: '#bfbfbf' }} />}
							suffix={
								<EyeOutlined
									onClick={(e) => e.preventDefault()}
									onMouseDown={touchStart}
									onMouseMove={touchMove}
									onMouseUp={touchEnd}
									onTouchStart={touchStart}
									onTouchMove={touchMove}
									onTouchEnd={touchEnd}
									style={{ color: '#8c8c8c' }}
								/>
							}
							value={password}
							onChange={passwordChanged}
						/>
					</Space>

					<input type="submit" style={{ display: 'none' }} />

					<Button type="primary" size="large" loading={loading} onClick={login}>
						Zaloguj się
					</Button>
				</form>
				<div className="version flex flex-jc flex-ac">v 1.4.5</div>
			</div>

			<StarBlob className="starblob" />
			<Blob2 className="blob2" />
		</>
	);
};

export default Login;
