import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AutoComplete, Button, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { Page, PageSection, WarningModal } from '../../components';
import API from '../../API';

import '../../styles/pages/tasks/containerSelection.scss';

const ContainerSelection = ({ match }) => {
	const history = useHistory();

	const [containers, setContainers] = useState();
	const [size, setSize] = useState();
	const [confirmModal, setConfirmModal] = useState();
	const [container, setContainer] = useState();
	const [loading, setLoading] = useState();

	const { id, type, sizeId } = match.params;

	const fetch = async () => {
		try {
			const containers = await API.containers.getAll(true);
			const sizes = await API.containers.sizes.getAll(true);

			setContainers(containers.filter((c) => c.size == sizeId && c.state.state == 0));
			setSize(sizes.filter((s) => s.id == sizeId)[0]);
		} catch (e) {
			console.trace(e);
			message.error(`${e}`);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	const options = useMemo(() => {
		if (!containers) return;

		return containers.map((c) => ({ ...c, value: c.name }));
	}, [containers]);

	const takeTask = async () => {
		try {
			setLoading(true);

			if (type === '0') {
				await API.orders.patch(id, { container_id: container.id, delivery_taken: 1 });
			}

			if (type === '1') {
				const order = await API.orders.get(id);

				await API.orders.patch(id, { container_id: container.id, delivery_taken: 1 });
				await API.orders.patch(order.related_id, { claiming_taken: 1 });
			}

			history.go(-1);
			setLoading();
			setConfirmModal();
		} catch (e) {
			setLoading();
			console.trace(e);
			message.error(`${e}`);
		}
	};

	const autocompleteType = (value) => setContainer(containers.filter((c) => c.name == value)[0]);

	const actions = [
		<Button
			key="0"
			type="primary"
			size="large"
			icon={<CheckOutlined />}
			disabled={!container}
			onClick={() => setConfirmModal(true)}
		>
			Potwierdź zgłoszenie do realizacji
		</Button>,
	];

	return (
		<Page title="Terminarz / Wybór kontenera" actions={actions}>
			<div className="container-selection flex">
				<PageSection title="Wybierz kontener" width={854}>
					<div className="flex flex-dir">
						<label>Numer kontenera</label>
						<AutoComplete
							size="large"
							options={options}
							filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
							onSelect={autocompleteType}
						/>
					</div>

					<table>
						<tbody>
							<tr>
								<td>
									<p>Rozmiar</p>
									<p>
										<span className="user-data">{size && size.name}</span>
									</p>
								</td>
								<td>
									<p>Rodzaj</p>
									<p>
										<span className="user-data">{container?.type ?? '-'}</span>
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</PageSection>
				<PageSection width={892}></PageSection>
			</div>

			<WarningModal
				visible={confirmModal}
				onCancel={() => setConfirmModal(false)}
				onOk={takeTask}
				okColor="#1890FF"
				loading={loading}
			>
				<h1>Czy na pewno chcesz zgłosić się do realizacji</h1>
				<h3>Zadanie zostanie przypisane oraz zarezerwowane dla Ciebie</h3>
			</WarningModal>
		</Page>
	);
};

export default ContainerSelection;
