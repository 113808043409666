import React from 'react';
import { Formatting } from '../utils';
import styled from 'styled-components';
import moment from 'moment';

const PrintTable = styled.table`
	width: 100%;
	page-break-inside: auto;

	tr {
		page-break-inside: avoid;
		page-break-after: auto;

		&.data:nth-child(2n) {
			background: #eee;
		}

		&.sectionHeader > th {
			font-size: 12px;
			font-weight: 500;
		}

		> td,
		> th {
			padding: 4px 8px;
			text-align: center;
			border: 1px solid #000;
		}

		> th {
			font-size: 14px;
		}

		> td {
			font-size: 10px;
		}
	}
`;

const CardPrint = ({ user, events, card, summary, date }) => {
	if (!user || !events || !card || !summary || !date) return <div></div>;

	const { advances, payments, expenses, settlements } = summary;

	return (
		<div className="print">
			<PrintTable>
				<tbody>
					<tr>
						<td>{card.plate}</td>
						<th colSpan="4">
							Karta Drogowa - {user.name} {user.surname}
						</th>
						<td>{Formatting.epochToDate(date, true)}</td>
					</tr>
					<tr>
						<th colSpan="6">Podstawienia</th>
					</tr>
					{events.filter((e) => e._type >= 0 && e._type <= 1).length > 0 && (
						<tr className="sectionHeader">
							<th>L.P.</th>
							<th>Godzina</th>
							<th>Adres</th>
							<th>Płatność</th>
							<th>Kwota</th>
							<th>Zaliczka</th>
						</tr>
					)}
					{events
						.filter((e) => e._type === 0)
						.map((e, i) => (
							<tr className="data">
								<td>{i + 1}</td>
								<td>
									{moment(e.delivery_taken).format('HH:mm')} - {moment(e.delivery_done).format('HH:mm')}
								</td>
								<td>{e.address}</td>
								<td>
									{e.payment_type ? `Gotówka - ${e.payment === e.delivery_done ? 'pobrana' : 'niepobrana'}` : 'Przelew'}
								</td>
								<td>
									{Formatting.money(e.price)} {e.price_type ? 'Brutto' : 'Netto'}
								</td>
								<td>{e.advance ? Formatting.money(e.advance) : 'Brak'}</td>
							</tr>
						))}
					<tr>
						<th colSpan="6">Odbiory</th>
					</tr>
					{events
						.filter((e) => e._type === 1)
						.map((e, i) => (
							<tr className="data">
								<td>{i + 1}</td>
								<td>
									{moment(e.claiming_taken).format('HH:mm')} - {moment(e.claiming_done).format('HH:mm')}
								</td>
								<td>{e.address}</td>
								<td>
									{e.payment_type ? `Gotówka - ${e.payment === e.claiming_done ? 'pobrana' : 'niepobrana'}` : 'Przelew'}
								</td>
								<td>
									{Formatting.money(e.price)} {e.price_type ? 'Brutto' : 'Netto'}
								</td>
								<td>{e.advance ? Formatting.money(e.advance) : 'Brak'}</td>
							</tr>
						))}
					<tr>
						<th colSpan="6">Wydatki</th>
					</tr>
					{events.filter((e) => e._type === 2).length > 0 && (
						<tr className="sectionHeader">
							<th>L.P.</th>
							<th>Godzina</th>
							<th>Typ i nazwa</th>
							<th>Kwota</th>
							<th>Podatek</th>
							<th>Przebieg</th>
						</tr>
					)}
					{events
						.filter((e) => e._type === 2)
						.map((e, i) => (
							<tr className="data">
								<td>{i + 1}</td>
								<td>{moment(e.created).format('HH:mm')}</td>
								<td>
									{e.type.name} - {e.name}
								</td>
								<td>
									{Formatting.money(e.value)} {e.value_type ? 'Brutto' : 'Netto'}
								</td>
								<td>{e.tax}%</td>
								<td>{e.mileage ? Formatting.insertSpaces(e.mileage) : '-'}</td>
							</tr>
						))}
					<tr>
						<th colSpan="6">Wydania gotówki</th>
					</tr>
					{events.filter((e) => e._type === 3).length > 0 && (
						<tr className="sectionHeader">
							<th>L.P.</th>
							<th>Godzina</th>
							<th>Kwota</th>
							<th colSpan="3">Odebrał</th>
						</tr>
					)}
					{events
						.filter((e) => e._type === 3)
						.map((e, i) => (
							<tr className="data">
								<td>{i + 1}</td>
								<td>{moment(e.created).format('HH:mm')}</td>
								<td>{Formatting.money(e.value)}</td>
								<td colSpan="3">
									{e.created_by.name} {e.created_by.surname}
								</td>
							</tr>
						))}
					<tr>
						<th colSpan="6">Podsumowanie</th>
					</tr>
					<tr>
						<td colSpan="3">Pobrane od klientów: {Formatting.money(advances + payments)}</td>
						<td colSpan="3">Wydane: {Formatting.money(expenses + settlements)}</td>
					</tr>
					<tr>
						<td>Zaliczki: {Formatting.money(advances)}</td>
						<td colSpan="2">Zapłaty: {Formatting.money(payments)}</td>
						<td>Wydatki: {Formatting.money(expenses)}</td>
						<td colSpan="2">Pobrania gotówki: {Formatting.money(settlements)}</td>
					</tr>
					<tr>
						<th colSpan="6">Balans: {Formatting.money(advances + payments - expenses - settlements)}</th>
					</tr>
					{card.id && (
						<>
							<tr>
								<th colSpan="6">Karta</th>
							</tr>
							<tr>
								<td colSpan="2">Licznik początek: {Formatting.insertSpaces(card.mileage_start)}</td>
								<td colSpan="2">Licznik koniec: {Formatting.insertSpaces(card.mileage_end)}</td>
								<td colSpan="2">Suma km: {Formatting.insertSpaces(card.mileage_end - card.mileage_start)}</td>
							</tr>
							<tr>
								<td colSpan="3">Czas jazdy: {card.time}</td>
								<td colSpan="3">Karta zamknięta: {card.plate}</td>
							</tr>
						</>
					)}
				</tbody>
			</PrintTable>
		</div>
	);
};

export default class extends React.PureComponent {
	render() {
		return <CardPrint {...this.props} />;
	}
}
