import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Select, Space, Timeline } from 'antd';
import {
	CheckOutlined,
	DeleteOutlined,
	ExportOutlined,
	FlagOutlined,
	HistoryOutlined,
	ImportOutlined,
	PlusCircleOutlined,
	UndoOutlined,
} from '@ant-design/icons';
import { blue } from '@ant-design/colors';
import { Page, PageSection, WarningModal } from '../../components';
import { Formatting } from '../../utils';
import { useContainerDetails } from '../../reducers';

import '../../styles/pages/containers/containerDetails.scss';
import InfoModal from '../../components/modals/infoModal';

const { Option } = Select;

const ContainerDetails = ({ match }) => {
	const [deleteModal, setDeleteModal] = useState(false);
	const [infoModal, setInfoModal] = useState(false);
	const [size, setSize] = useState();

	const [
		{ container, sizes, timeline, saveDisabled, loading, saving, lending, canLend, deleteDisabled },
		{
			numberChanged,
			sizeChanged,
			typeChanged,
			deleteContainer,
			restoreContainer,
			saveChanges,
			nameChanged,
			streetChanged,
			postalChanged,
			cityChanged,
			lendContainer,
			unlendContainer,
			addContainerSize,
		},
	] = useContainerDetails(match);

	const actions =
		container?.state === 6
			? [
					<Button
						key={0}
						type="primary"
						size="large"
						icon={<UndoOutlined />}
						loading={loading}
						onClick={restoreContainer}
					>
						Przywróć kontener
					</Button>,
			  ]
			: [
					<Button
						key={1}
						type="primary"
						size="large"
						icon={<CheckOutlined />}
						loading={saving}
						onClick={saveChanges}
						disabled={saveDisabled}
					>
						Zapisz zmiany
					</Button>,
					<Button
						key={2}
						type="danger"
						size="large"
						icon={<DeleteOutlined />}
						onClick={() => setDeleteModal(true)}
						disabled={deleteDisabled}
					>
						Usuń kontener
					</Button>,
			  ];

	const getTimelineEvent = (event, i, arr) => {
		const data = {};

		data.icon = i === 0 ? <FlagOutlined style={{ color: blue[5] }} /> : <HistoryOutlined style={{ color: blue[5] }} />;
		data.date = event.changed;

		switch (event.state) {
			// Free
			case 0:
				if (i !== arr.length - 1 && arr[i + 1].state === 6) {
					data.title = (
						<>
							Przywrócony przez <Link to={`/panel/drivers/${event.changed_by.id}`}>{event.changed_by.name}</Link> - Baza
						</>
					);
				} else if (event.date === container.created) {
					data.title = (
						<>
							Stworzony przez <Link to={`/panel/drivers/${event.changed_by.id}`}>{event.changed_by.name}</Link> - Baza
						</>
					);
				} else {
					data.title = 'Baza';
				}
				break;

			// Delivered
			case 1:
				data.title = `W zamówieniu ${event.name} ${event.street}`;
				data.link = <Link to={`/panel/orders/${event.order_id}`}>Szczegóły</Link>;
				break;

			// Lended
			case 5:
				data.title = `Wypożyczony do ${event.name}${event.street ? ` na ${event.street}` : ''}`;
				break;

			// Deleted
			case 6:
				data.title = (
					<>
						Usuniety przez <Link to={`/panel/drivers/${event.changed_by.id}`}>{event.changed_by.name}</Link>
					</>
				);
				break;

			default:
				data.title = 'Nieznane wydarzenie';
				break;
		}

		return (
			<Timeline.Item key={`${i}`} dot={data.icon}>
				<span>{Formatting.epochToDate(data.date)}</span> - {data.title}. {data.link}
			</Timeline.Item>
		);
	};

	return (
		<>
			{container != undefined ? (
				<Page actions={actions} title={`Kontenery / ${container.name}`}>
					<div className="container-details flex">
						<PageSection title="Informacje" width={878}>
							<div className="flex">
								<Space size={25}>
									<div>
										<label>Numer</label>
										<Input
											defaultValue={container.name}
											type="text"
											size="large"
											disabled={container.state == 6}
											onChange={numberChanged}
										/>
									</div>
									<div>
										<label>Rozmiar</label>
										<Select
											size="large"
											defaultValue={container.size.id}
											disabled={container.state === 6}
											onChange={sizeChanged}
										>
											{sizes?.map((size) => (
												<Option key={size.id} value={size.id}>
													{size.name}
												</Option>
											))}
										</Select>
									</div>
								</Space>
								<Button
									className="circle flex flex-ac"
									type="primary"
									size="large"
									shape="circle"
									icon={<PlusCircleOutlined />}
									onClick={() => setInfoModal(true)}
								></Button>
							</div>

							<div className="flex">
								<Space size={25}>
									<div>
										<label>Rodzaj</label>
										<Input
											defaultValue={container.type}
											type="text"
											size="large"
											disabled={container.state == 6}
											onChange={typeChanged}
										/>
									</div>
									<div></div>
								</Space>
							</div>

							<div className="flex">
								<Space>
									<table>
										<tbody>
											<tr>
												<td>Utworzony:</td>
												<td>
													<span className="user-data">{Formatting.epochToDate(container.added)}</span>
												</td>
											</tr>
											<tr>
												<td>Przez:</td>
												<td>
													<Link to={`/panel/drivers/${container.added_by.id}`}>
														{container.added_by.name} {container.added_by.surname}
													</Link>
												</td>
											</tr>
										</tbody>
									</table>
									{container.state === 6 && (
										<table>
											<tbody>
												<tr>
													<td>Usunięty:</td>
													<td>
														<span className="user-data">{Formatting.epochToDate(container.changed)}</span>
													</td>
												</tr>
												<tr>
													<td>Przez:</td>
													<td>
														<Link to={`/panel/drivers/${container.changed_by.id}`}>
															{container.changed_by.name} {container.changed_by.surname}
														</Link>
													</td>
												</tr>
											</tbody>
										</table>
									)}
								</Space>
							</div>

							{container.state !== 6 &&
								(container.state === 5 ? (
									<>
										<PageSection title="Wypożyczanie" width={878}>
											<div className="flex flex-aend">
												<table>
													<tbody>
														<tr>
															<td>
																<p>Nazwa odbiorcy</p>
																<p>
																	<span className="lend">{container.lend.name}</span>
																</p>
															</td>
															<td>
																<p>Ulica i numer</p>
																<p>
																	<span className="lend">{container.lend.street ?? '-'}</span>
																</p>
															</td>
														</tr>
														<tr>
															<td>
																<p>Kod pocztowy</p>
																<p>
																	<span className="lend">{container.lend.postal ?? '-'}</span>
																</p>
															</td>
															<td>
																<p>Miasto</p>
																<p>
																	<span className="lend">{container.lend.city ?? '-'}</span>
																</p>
															</td>
														</tr>
													</tbody>
												</table>
												<Button
													type="primary"
													size="large"
													icon={<ImportOutlined />}
													onClick={unlendContainer}
													loading={lending}
												>
													Wróć z wypożyczenia
												</Button>
											</div>
										</PageSection>
									</>
								) : (
									<PageSection title="Wypożyczanie" width={878}>
										<Space size={25}>
											<div>
												<label>Nazwa odbiorcy</label>
												<Input type="text" size="large" onChange={nameChanged} />
											</div>
											<div>
												<label>Ulica i numer</label>
												<Input type="text" size="large" onChange={streetChanged} />
											</div>
										</Space>
										<div className="flex">
											<Space size={25}>
												<div>
													<label>Kod pocztowy</label>
													<Input type="text" size="large" onChange={postalChanged} />
												</div>
												<div>
													<label>Miasto</label>
													<Input type="text" size="large" onChange={cityChanged} />
												</div>
											</Space>
											<Button
												type="primary"
												size="large"
												icon={<ExportOutlined />}
												onClick={lendContainer}
												loading={lending}
												disabled={!canLend}
											>
												Wypożycz kontener
											</Button>
										</div>
									</PageSection>
								))}
						</PageSection>

						<PageSection title="Historia" width={878}>
							<Timeline>{timeline?.map(getTimelineEvent)}</Timeline>
						</PageSection>
					</div>
					<WarningModal
						visible={deleteModal}
						onCancel={() => setDeleteModal(false)}
						onOk={() => deleteContainer(() => setDeleteModal(false))}
						loading={loading}
					>
						<h1>Czy na pewno chcesz usunąć kontener</h1>
						<h3>Będzie go można później przywrócić</h3>
					</WarningModal>
					<InfoModal
						width={560}
						visible={infoModal}
						closable={false}
						cancelText="Anuluj"
						okText="Dodaj"
						onCancel={() => setInfoModal(false)}
						onOk={() => {
							addContainerSize(size);
							setInfoModal(false);
						}}
						icon="plus"
					>
						<h1>Podaj nowy rozmiar</h1>
						<Input
							type="text"
							size="large"
							placeholder="Nazwa"
							value={size}
							onChange={(e) => setSize(e.target.value)}
						/>
					</InfoModal>
				</Page>
			) : null}
		</>
	);
};

export default ContainerDetails;
