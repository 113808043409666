import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import API from '../../API';

export default function useTaskDetails(match) {
	const [order, setOrder] = useState();
	const [loading, setLoading] = useState();
	const [drivers, setDrivers] = useState();
	const [assignee, setAssignee] = useState();

	const { id, type } = match.params;

	const admin = window.sessionStorage.getItem('level') > 0;

	const fetch = async () => {
		try {
			const order = await API.orders.get(id);

			if (order.related_id) {
				order.related = await API.orders.get(order.related_id);
			}

			switch (type) {
				case '0':
				case '1':
					order.date = order.delivery_date;
					order.taken = order.delivery_taken;
					order.taken_by = order.delivery_by;
					order.done = order.delivery_done;
					break;

				case '2':
					order.date = order.claiming_date;
					order.taken = order.claiming_taken;
					order.taken_by = order.claiming_by;
					order.done = order.claiming_done;
					break;
			}

			admin && setDrivers((await API.users.getAll()).filter((d) => !d.removed));
			setOrder(order);
		} catch (e) {
			console.trace(e);
		}
	};

	useEffect(() => {
		id && !isNaN(type) && fetch();
	}, [id, type]);

	const takeTask = async () => {
		if (loading) return;

		try {
			setLoading('taking');

			// TODO(Hexagonale): Take replacement
			await API.orders.patch(match.params.id, { claiming_taken: 1 });
			await fetch();

			message.success('Zgłoszono do realizacji');
			setLoading();
		} catch (e) {
			setLoading();
			console.trace(e);
			message.error(`${e}`);
		}
	};

	const assignTask = async () => {
		if (loading) return;

		try {
			setLoading('assign');

			if (type === '0') {
				// Delivery
				await API.orders.patch(id, { delivery_by: assignee });
			} else if (type === '1') {
				// Replacement
				await API.orders.patch(order.related_id, { delivery_by: assignee });
				await API.orders.patch(id, { claiming_by: assignee });
			} else if (type === '2') {
				// Claiming
				await API.orders.patch(id, { claiming_by: assignee });
			}

			await fetch();

			message.success('Przypisano zadanie');
			setLoading();
		} catch (e) {
			setLoading();
			console.trace(e);
			message.error(`${e}`);
		}
	};

	const unassignTask = async () => {
		if (loading) return;

		try {
			setLoading('unassign');

			if (type === '0') {
				// Delivery
				await API.orders.patch(match.params.id, { delivery_taken: 0 });
			} else if (type === '1') {
				// Replacement
				await API.orders.patch(order.related_id, { claiming_taken: 0 });
				await API.orders.patch(id, { delivery_taken: 0 });
			} else if (type === '2') {
				// Claiming
				await API.orders.patch(match.params.id, { claiming_taken: 0 });
			}
			await fetch();

			message.success('Zgłoszono do realizacji');
			setLoading();
		} catch (e) {
			setLoading();
			console.trace(e);
			message.error(`${e}`);
		}
	};

	return [
		{ order, type, admin, loading, drivers, assignee },
		{ takeTask, assigneeChanged: setAssignee, assignTask, unassignTask },
	];
}
