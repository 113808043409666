import React from 'react';
import { Formatting } from '../utils';
import styled from 'styled-components';
import moment from 'moment';

const PrintTable = styled.table`
	width: 100%;
	page-break-inside: auto;
	font-size: ${({ print }) => (print ? '14px' : '18px')};

	tr {
		page-break-inside: avoid;
		page-break-after: auto;

		&.data:nth-child(2n) {
			background: #eee;
		}

		&.sectionHeader > th {
			font-size: 0.85em;
			font-weight: 500;
		}

		> td,
		> th {
			padding: 4px 8px;
			text-align: center;
			border: 1px solid #000;
		}

		> th {
			font-size: 1em;
		}

		> td {
			font-size: 0.7em;

			&.expense-sum {
				font-weight: 600;
			}
		}
	}
`;

const Title = styled.h2`
	&&& {
		text-align: center;
		margin-bottom: 16px;
	}
`;

const StatsPrint = ({ expenseTypes, stats, after, before, print }) => {
	if (!after || !before || !expenseTypes || !stats) return <div></div>;

	return (
		<div className={`print ${print ? '' : 'visible'}`}>
			<Title>
				Statystyki dochodów: {Formatting.epochToDate(after, true)} - {Formatting.epochToDate(before, true)}.
				<br />
				Stan z dnia {Formatting.epochToDate(moment(), true)}
			</Title>

			<PrintTable print={print}>
				<tbody>
					<tr>
						<th colSpan="6">Przychody z zamówień:</th>
					</tr>
					<tr>
						<td colSpan="2">Gotówka: {Formatting.money(stats.orders.cash)}</td>
						<td colSpan="2">Przelew: {Formatting.money(stats.orders.transfer)}</td>
						<td colSpan="2">Suma: {Formatting.money(stats.orders.cash + stats.orders.transfer)}</td>
					</tr>
					<tr>
						<td colSpan="3">Netto: {Formatting.money(stats.orders.net)}</td>
						<td colSpan="3">Brutto: {Formatting.money(stats.orders.gross)}</td>
					</tr>
					<tr>
						<td colSpan="6">Przychód z zamówień innych: {Formatting.money(stats.incomes.sum)}</td>
					</tr>
					<tr>
						<th colSpan="6">Wydatki:</th>
					</tr>
					{Object.entries(stats.expenses.sum).map(([k, v]) => {
						const typeName = expenseTypes.filter((e) => e.id == k)[0]?.name;
						return (
							<>
								<tr key={`${k}-0`}>
									<th colSpan="3">{typeName}:</th>
									<td colSpan="3">{v.expenses.length}</td>
								</tr>
								<tr key={`${k}-1`}>
									<td colSpan="2" className="expense-sum">
										Netto: {Formatting.money(v.net)}
									</td>
									<td colSpan="2" className="expense-sum">
										Brutto: {Formatting.money(v.gross)}
									</td>
									<td colSpan="2" className="expense-sum">
										Podatek: {Formatting.money(v.gross - v.net)}
									</td>
								</tr>
								{v.expenses.map((expense) => (
									<tr key={`${k}-${expense.id}`}>
										<td colSpan="2">{expense.name ?? '-'}</td>
										<td colSpan="2">
											{Formatting.money(expense.value)} ({expense.value_type ? 'Brutto' : 'Netto'} {expense.tax}%)
										</td>
										<td colSpan="2">{Formatting.epochToDate(expense.created)}</td>
									</tr>
								))}
							</>
						);
					})}
					<tr>
						<th colSpan="6">Podsumowanie:</th>
					</tr>
					<tr>
						<th colSpan="6">
							Łączny przychód: {Formatting.money(stats.orders.cash + stats.orders.transfer + stats.incomes.sum)}
						</th>
					</tr>
					<tr>
						<td colSpan="2">Wydatki netto: {Formatting.money(stats.expenses.net)}</td>
						<td colSpan="2">Wydatki brutto: {Formatting.money(stats.expenses.gross)}</td>
						<td colSpan="2">Podatek: {Formatting.money(stats.expenses.gross - stats.expenses.net)}</td>
					</tr>
					<tr>
						<th colSpan="6">Rodzaje kontenerów:</th>
					</tr>
					{Object.entries(stats.containers)
						.sort((a, b) => b[1] - a[1])
						.map(([name, count]) => (
							<tr>
								<td colSpan="3">{name.charAt(0).toUpperCase() + name.slice(1)}</td>
								<td colSpan="3">{count}</td>
							</tr>
						))}
				</tbody>
			</PrintTable>
		</div>
	);
};

export default class extends React.PureComponent {
	render() {
		return <StatsPrint {...this.props} />;
	}
}
