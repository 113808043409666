import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { isEmail, isNip } from '../../utils';
import API from '../../API';

export default function () {
	const history = useHistory();

	const [client, setClient] = useState({});
	const [loading, setLoading] = useState(false);
	const disabled = !client.name?.trim() || client.type == undefined || !client.phone?.trim();

	const state = history.location.state;
	const intent = state !== undefined;

	const createClient = async () => {
		if (disabled) return;
		if (!client.phone.match(/^(\+[0-9]{2} ?)?([0-9] ?){9}$/)) return message.error('Telefon jest niepoprawny');
		if (client.email && !isEmail(client.email)) return message.error('Email jest niepoprawny');
		// if (client.nip && !isNip(client.nip)) return message.error('NIP jest niepoprawny');
		if (client.bdo?.length > 14) return message.error('BDO jest za długi');

		try {
			setLoading(true);

			const c = await API.clients.create(client);

			setLoading(false);
			message.success('Stworzono klienta');
			if (intent) {
				history.goBack();
				state.client = c.id;
			} else {
				history.replace(`clients/${c.id}`);
			}
		} catch (e) {
			setLoading(false);
			message.error(`${e}`);

			console.trace(e);
		}
	};

	return [
		{
			client,
			disabled,
			loading,
			intent,
		},
		{
			nameChanged: (e) => setClient((client) => ({ ...client, name: e.target.value })),
			phoneChanged: (e) => setClient((client) => ({ ...client, phone: e.target.value })),
			typeChanged: (value) => setClient((client) => ({ ...client, type: value.value })),
			emailChanged: (e) => setClient((client) => ({ ...client, email: e.target.value })),
			nipChanged: (e) => setClient((client) => ({ ...client, nip: e.target.value })),
			bdoChanged: (e) => setClient((client) => ({ ...client, bdo: e.target.value })),
			streetChanged: (e) => setClient((client) => ({ ...client, street: e.target.value })),
			cityChanged: (e) => setClient((client) => ({ ...client, city: e.target.value })),
			postalChanged: (e) => setClient((client) => ({ ...client, postal: e.target.value })),
			descriptionChanged: (e) => setClient((client) => ({ ...client, description: e.target.value })),
			createClient,
		},
	];
}
