import React from 'react';
import { Formatting } from '../utils';
import styled from 'styled-components';
import moment from 'moment';

const Print = styled.div``;

const PrintTable = styled.table`
	width: 100%;
	page-break-inside: auto;

	tr {
		page-break-inside: avoid;
		page-break-after: auto;

		&:nth-child(2n) {
			background: #eee;
		}

		> td,
		> th {
			padding: 4px 8px;
			text-align: center;
		}

		> th {
			font-size: 14px;

			> div {
				display: flex;
				flex-direction: column;

				> h5 {
					font-weight: 600;
					margin-bottom: 4px;
				}

				> span {
					font-weight: 400;
					font-size: 8px;
				}
			}
		}

		> td {
			font-size: 10px;

			> div {
				display: flex;
				flex-direction: column;

				> h6 {
					font-weight: 600;
					font-size: 12px;
					margin-bottom: 2px;
				}
			}
		}
	}
`;

const PrintTitle = styled.h2`
	width: 100%;
	margin-top: 16px;
	margin-bottom: 8px;
	text-align: center;
	font-size: 18px;
`;

const PrintSubtitle = styled.h3`
	width: 100%;
	margin-top: 16px;
	margin-bottom: 32px;
	text-align: center;
	font-size: 14px;
`;

const DayTitle = styled(PrintSubtitle)`
	margin-top: 48px;
`;

const Summary = styled.h4`
	text-align: center;
	margin: ${({ first }) => (first ? '24px' : '0')} 0 0 0;
	font-weight: 400;
`;

const SummaryTitle = styled.h3`
	text-align: center;
	margin: 32px 0 0 0;
	font-weight: 500;
`;

const ExpensesPrint = ({ expenses, month }) => {
	if (!expenses) return <div></div>;

	const getName = (expense) => {
		switch (expense._type) {
			// Expenses
			case 0:
				return expense.name;

			// Settlements
			case 1:
				return 'Pobranie gotówki';

			// Advances
			case 2:
				return `Zaliczka - ${expense.address}`;

			// Owns
			case 3:
				return `Zamówienie własne - ${expense.to}`;
		}
	};

	const getValue = (expense) => {
		if (expense._type === 3) {
			return <span>{Formatting.insertSpaces(expense.weight ?? '')} kg</span>;
		}

		return Formatting.money(expense.value);
	};

	return (
		<Print className="print">
			<PrintTitle>Wydruk wydatków na miesiąc {moment(month).startOf('month').format('MMMM yyyy')}</PrintTitle>
			<PrintSubtitle>Stan z dnia {Formatting.epochToDate(Date.now())}</PrintSubtitle>
			<PrintTable>
				<tr>
					<th>Data</th>
					<th>Nazwa</th>
					<th>Kwota</th>
					<th>Dodane przez</th>
				</tr>
				{expenses.map((e) => (
					<tr>
						<td>{Formatting.epochToDate(e.created)}</td>
						<td>{getName(e)}</td>
						<td>{getValue(e)}</td>
						<td>
							{e.created_by?.name} {e.created_by?.surname}
						</td>
					</tr>
				))}
			</PrintTable>
		</Print>
	);
};

export default class extends React.PureComponent {
	render() {
		return <ExpensesPrint {...this.props} />;
	}
}
