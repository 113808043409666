import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Input, Skeleton, Space, Table } from 'antd';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { green, grey, red } from '@ant-design/colors';
import { DateModal, Page } from '../components';
import { useFastActions } from '../reducers';
import { Formatting } from '../utils';
import moment from 'moment';

const FastActions = () => {
	const history = useHistory();

	const [
		{ loading, containers, search, dateModal },
		{ searchChanged, clearSearch, setDateModal, changeDate },
	] = useFastActions();

	const getContainerStateName = (state, row) => {
		switch (state) {
			case 0:
				return 'Wolny';
			case 1:
				if (row?.order?.claiming_date && new Date(row.order.claiming_date) < new Date()) {
					const diff = moment().startOf('day').diff(moment(row.order.claiming_date).startOf('day'), 'days');

					if (row.order.related_id > row.order.id) {
						if (diff === 0) return `Do wymiany od dzisiaj`;
						if (diff === 1) return `Do wymiany od wczoraj`;
						return `Do wymiany (od ${diff} dni)`;
					}

					if (diff === 0) return `Do odbioru od dzisiaj`;
					if (diff === 1) return `Do odbioru od wczoraj`;
					return `Do odbioru (od ${diff} dni)`;
				}

				const diff = moment().startOf('day').diff(moment(row.order.delivery_done).startOf('day'), 'days');

				if (diff === 0) return `Podstawiony dzisiaj`;
				if (diff === 1) return `Podstawiony wczoraj`;
				return `Podstawiony (${diff} dni)`;
			case 4:
				return 'W transporcie';
			case 5:
				return 'Wypożyczony';
			case 6:
				return 'Usunięty';
			default:
				return 'Nieznany';
		}
	};

	const columns = [
		{
			title: 'Akcje',
			dataIndex: 'state',
			key: 'actions',
			render: (state, row) => {
				if (state.state === 0 || state.state === 5 || state.state === 6) return;

				return (
					<Space size={5}>
						{!row.order.claiming_taken && (
							<>
								<a
									className="noselect"
									style={{ color: loading === row ? grey[5] : row.order.related_id > row.order.id ? green[5] : red[5] }}
									onClick={() =>
										setDateModal({
											callback: async (date) => await changeDate(row, date),
											deadline: row.order.related_id > row.order.id,
										})
									}
								>
									{row.order.related_id > row.order.id ? 'Data wymiany' : 'Odbiór'}
								</a>
								{row.order.related_id <= row.order.id && (
									<>
										<span> | </span>
										<a
											className="noselect"
											style={{ color: loading === row ? grey[5] : green[5] }}
											onClick={() => history.push(`/panel/new-order?type=0&related_id=${row.order.id}`)}
										>
											Wymiana
										</a>
									</>
								)}
							</>
						)}
					</Space>
				);
			},
		},
		{
			title: 'Klient',
			dataIndex: 'order',
			key: 'client',
			render: (order) =>
				order?.client?.name ? <Link to={`/panel/clients/${order.client.id}`}>{order.client.name}</Link> : '-',
		},
		{
			title: 'Stan',
			dataIndex: 'state',
			key: 'state',
			render: (state, row) => getContainerStateName(state.state, row),
		},
		{
			title: 'Numer',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Rozmiar',
			dataIndex: 'size',
			key: 'size',
			render: (s) => s.name,
		},
		{
			title: 'Data odbioru',
			dataIndex: 'order',
			key: 'claiming',
			render: (order) =>
				order?.claiming_date
					? Formatting.epochToDate(order.claiming_date, moment(order.claiming_date).hours() === 0)
					: '-',
		},
		{
			title: 'Aktualna lokalizacja',
			dataIndex: 'order',
			key: 'location',
			render: (order) => order?.address && <Link to={`/panel/orders/${order.id}`}>{order.address}</Link>,
		},
	];

	return (
		<Page title="Kontenery w zamówieniach">
			{containers ? (
				<>
					<div className="containers flex flex-dir flex-1">
						<div className="inputs flex">
							<Input
								placeholder="Wyszukaj"
								size="large"
								suffix={<SearchOutlined style={{ color: '#8C8C8C' }} />}
								value={search}
								onChange={searchChanged}
							/>
							<Button type="primary" size="large" icon={<CloseCircleOutlined />} onClick={clearSearch}>
								Wyczyść
							</Button>
						</div>
						<Table columns={columns} dataSource={containers} pagination={false} scroll={{ x: 'max-content' }} />
					</div>
					<DateModal
						visible={dateModal}
						onCancel={() => !loading && setDateModal()}
						onOk={dateModal?.callback}
						loading={loading}
						deadline={dateModal?.deadline}
					/>
				</>
			) : (
				<Skeleton active />
			)}
		</Page>
	);
};

export default FastActions;
