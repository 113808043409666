import React from 'react';
import '../styles/components/iconCircle.scss';
import styled, { css } from 'styled-components';

const Icon = styled.div`
	width: 150px;
	height: 150px;
	border-radius: 100%;
	background-color: white;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
	position: relative;

	&:hover {
		cursor: pointer;
	}

	span {
		color: #003a8c;
	}

	svg {
		font-size: 80px;
	}

	& + p {
		text-align: center;
		margin: 0;
		margin-top: 10px;
		font-size: 20px;
		font-weight: 500;
		color: $gray-8;

		&:hover {
			cursor: pointer;
		}
	}

	&::after {
		${(props) =>
			props.notifications != undefined
				? css`
						content: '${props.notifications}';
				  `
				: null}
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		width: 47px;
		height: 47px;
		background-color: #f5222d;
		font-size: 24px;
		font-weight: 700;
		color: white;
		border-radius: 100%;
	}
`;

const IconCircle = ({ children, label, onClick, notifications }) => {
	return (
		<div className="flex flex-dir flex-jc flex-ac">
			<Icon className="flex flex-jc flex-ac" onClick={onClick} notifications={notifications}>
				{children}
			</Icon>
			<p>{label}</p>
		</div>
	);
};

export default IconCircle;
