import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ExportOutlined, FileDoneOutlined, ImportOutlined, RedoOutlined } from '@ant-design/icons';
import { blue, cyan, green, grey, gold, purple, red } from '@ant-design/colors';
import { Formatting } from '../utils';
import styled from 'styled-components';
import moment from 'moment';

const handleColorType = (task, noGrey) => {
	if (!noGrey && task.done) {
		return grey[3];
	}

	if (task.type === 4) {
		return cyan[5];
	}

	if (!noGrey && task.taken) {
		return blue[5];
	}

	if (task.order.on_place) {
		return purple[5];
	}

	switch (task.type) {
		case 0:
			return green[5];
		case 1:
			return gold[5];
		case 2:
			return red[5];
	}
};

const getTaskIcon = (task) => {
	switch (task.type) {
		// Delivery
		case 0:
			return <ImportOutlined />;

		// Replacement
		case 1:
			return <RedoOutlined />;

		// Claiming
		case 2:
			return <ExportOutlined />;
	}
};

const Wrapper = styled.div`
	align-items: center;
	width: 100%;
	margin-bottom: 3px;
	padding: 10px 15px 10px 4px;
	border-radius: 5px;
	background-color: ${({ task }) => handleColorType(task)};
	border-left: 24px solid ${({ task }) => handleColorType(task, 1)};

	&:last-child {
		margin-bottom: 0;
	}

	> p {
		color: white;
		font-size: 24px;
		margin-right: 8px;
	}

	> span {
		margin-right: 10px;

		svg {
			font-size: 35px;
			color: white;
		}
	}

	> div {
		flex: 1;

		p {
			color: white;
			white-space: nowrap;
			overflow: hidden;
			margin-bottom: 2px;
		}

		p:first-child {
			font-weight: 500;
			font-size: 20px;
			text-overflow: ellipsis;
		}

		p.phone {
			font-size: 20px;
		}

		p:last-child {
			font-weight: 400;
			font-size: 18px;
			margin-bottom: 0;
		}
	}
`;

const TaskCell = ({ i, task }) => {
	const history = useHistory();
	const date = useMemo(() => moment(task.date), [task.date]);

	if (task.type === 4)
		return (
			<Wrapper className="flex" task={task} onClick={() => history.push(`/panel/orders/${task.own.id}/1`)}>
				<p>{i + 1}</p>
				<FileDoneOutlined />
				<div>
					<p>{task.own.address}</p>
					<p>{date.hours() === 0 ? 'Cały dzień' : date.format('HH:mm')}</p>
				</div>
				<div>
					<p>
						{task.own.client.name} | {task.own.to}
					</p>
					{task.own.closed && (
						<p>
							{Formatting.epochToDate(task.own.closed)} | {Formatting.insertSpaces(task.own.weight)} kg
						</p>
					)}
				</div>
			</Wrapper>
		);

	return (
		<Wrapper className="flex" task={task} onClick={() => history.push(`/panel/tasks/${task.order.id}/${task.type}`)}>
			<p>{i + 1}</p>
			{getTaskIcon(task)}
			<div>
				<p>{task.order.address}</p>
				<p>
					{date.hours() === 0 ? 'Cały dzień' : date.format('HH:mm')} | {task.order.bdo ? 'Wytwarzający' : 'BDO'}
				</p>
				<p>
					{task.taken_by?.name} {task.taken_by?.surname}
				</p>
				<p>
					{task.order.payment_type ? 'Gotówka' : 'Przelew'} |{' '}
					{task.order.payment ? 'Opłacone' : task.order.advance ? 'Zaliczka' : 'Nieopłacone'}
				</p>
			</div>
			<div>
				<p>{task.order.client.name}</p>
				<p className="phone">{task.order.client.phone}</p>
				<p>{task.order.size.name}</p>
				{/* <p>
					{Formatting.money(task.order.price)} ({task.order.price_type ? 'Brutto' : 'Netto'})
				</p> */}
			</div>
		</Wrapper>
	);
};

export default TaskCell;
