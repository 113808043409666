import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Input, Space, Skeleton, Checkbox, Table } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Page, PageSection } from '../../components';
import { Formatting } from '../../utils';
import SignContext from './signContext';
import moment from 'moment';

const TaskClosingBody = ({ match }) => {
	const history = useHistory();
	const [
		{
			orders,
			order,
			ordersPaid,
			paid,
			wz,
			sign,
			signName,
			signSurname,
			signDocument,
			paymentDone,
			advance,
			finishDisabled,
		},
		{
			setId,
			setType,

			setPaid,
			setOrderAsPaid,
			setOrderAsUnpaid,
			paymentDoneChanged,
			advanceChanged,
			clear,

			setWz,
			signNameChanged,
			signSurnameChanged,
			signDocumentChanged,

			finishTask,
		},
	] = useContext(SignContext);

	const { id, type } = match.params;

	useEffect(() => {
		setId(id);
		setType(type);
	}, [id, type]);

	const columns = [
		{
			title: 'Opłacone',
			dataIndex: 'id',
			key: 'id',
			render: (id) => {
				const checked = ordersPaid.indexOf(id) !== -1;

				return <Checkbox checked={checked} onChange={() => (checked ? setOrderAsUnpaid(id) : setOrderAsPaid(id))} />;
			},
		},
		{
			title: 'Cena',
			dataIndex: 'price',
			key: 'price',
			render: (price, row) => (
				<>
					{Formatting.money(price)} ({row.price_type ? 'brutto' : 'netto'})
				</>
			),
		},
		{
			title: 'Adres',
			dataIndex: 'address',
			key: 'address',
		},
		{
			title: 'Data',
			dataIndex: 'delivery_date',
			key: 'date',
			render: (date) => Formatting.epochToDate(date, moment(date).hours() === 0),
		},
		{
			title: 'Szczegóły',
			dataIndex: 'id',
			key: 'action',
			render: (id) => <Link to={`/panel/orders/${id}`}>Szczegóły</Link>,
		},
	];

	const actions = [
		<Button
			key="0"
			type="primary"
			size="large"
			icon={<CheckCircleOutlined />}
			onClick={finishTask}
			disabled={finishDisabled}
		>
			Zrealizuj zadanie
		</Button>,
		wz && finishDisabled && <span key="1">Uzupełnij dane do WZ</span>,
	];

	return (
		<Page title="Terminarz / Realizacja zadania" actions={actions}>
			{order && orders ? (
				<div className="task-closing flex flex-dir">
					<PageSection title="Finanse">
						{orders.length !== 0 && (
							<>
								<h2>Nieopłacone zamówienia klienta:</h2>
								<Table columns={columns} dataSource={orders} pagination={false} scroll={{ x: 'max-content' }} />
							</>
						)}
						{(type !== '0' || order.on_place) && !order.payment ? (
							<div className="flex flex-dir">
								<table className="finances">
									<tbody>
										<tr>
											<td>
												<p>
													Cena (<span className="price-type">{order.price_type ? 'Brutto' : 'Netto'}</span>)
												</p>
												<p>
													<span className="user-data">{Formatting.money(order.price)}</span>
												</p>
												{order.price_type === 0 && <p>({Formatting.money(order.price * 1.08)} - Brutto)</p>}
											</td>
											{type === '1' ? (
												<td className="checkbox">
													<p>&nbsp;</p>
													<Checkbox checked={paid} onChange={(e) => setPaid(e.target.checked)}>
														Poprzednie zamówienie opłacone
													</Checkbox>
												</td>
											) : order.payment ? (
												<td>Zamówienie zostało już opłacone.</td>
											) : (
												<td className="checkbox">
													<p>&nbsp;</p>
													<Checkbox checked={paid} onChange={(e) => setPaid(e.target.checked)}>
														Zapłacone
													</Checkbox>
												</td>
											)}
										</tr>
									</tbody>
								</table>
							</div>
						) : (
							<div className="flex flex-dir">
								<table className="finances">
									<tbody>
										<tr>
											<td>
												<p>
													Cena (<span className="price-type">{order.price_type ? 'Brutto' : 'Netto'}</span>)
												</p>
												<p>
													<span className="user-data">{Formatting.money(order.price)}</span>
												</p>
												{order.price_type === 0 && <p>({Formatting.money(order.price * 1.08)} - Brutto)</p>}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						)}
						{order.related?.payment && (
							<>
								<div style={{ height: '30px' }}></div>
								<h3>Poprzednie zamówienie zostało opłacone.</h3>
							</>
						)}
						{type !== '2' && order.payment_type !== 0 && !order.on_place && (
							<>
								<div style={{ height: '45px' }}></div>
								<Space size={45}>
									<div className="flex flex-dir">
										<Checkbox checked={paymentDone} onChange={paymentDoneChanged}>
											Zamówienie opłacone w całości
										</Checkbox>
									</div>
								</Space>
								{!paymentDone && (
									<Space size={45}>
										<div className="flex flex-dir">
											<label>Zaliczka</label>
											<div className="flex">
												<Input type="text" size="large" value={advance} onChange={advanceChanged} />
												<span className="flex flex-jc flex-ac PLN">PLN</span>
											</div>
										</div>
										<Button
											type="primary"
											size="large"
											icon={<CloseCircleOutlined />}
											onClick={clear}
											disabled={!advance}
										>
											Wyczyść
										</Button>
									</Space>
								)}
							</>
						)}
					</PageSection>
					<Checkbox className="wz" checked={wz} onChange={(e) => setWz(e.target.checked)}>
						WZ
					</Checkbox>
					{wz && (
						<PageSection title="Dane do WZ">
							<Space size={25}>
								<div>
									<label>Imię</label>
									<Input type="text" size="large" value={signName} onChange={signNameChanged} />
								</div>
								<div>
									<label>Nazwisko</label>
									<Input type="text" size="large" value={signSurname} onChange={signSurnameChanged} />
								</div>
							</Space>
							<Space size={25}>
								<div>
									<label>Nr dowodu</label>
									<Input type="text" size="large" value={signDocument} onChange={signDocumentChanged} />
								</div>
								<div>
									<Button
										key="1"
										type="primary"
										size="large"
										icon={<EditOutlined />}
										onClick={() => history.push(`/panel/task-closing-sign`, sign)}
									>
										{sign ? 'Edytuj' : 'Pobierz'} podpis do WZ
									</Button>
								</div>
							</Space>
						</PageSection>
					)}
				</div>
			) : (
				<Skeleton active />
			)}
		</Page>
	);
};

export default TaskClosingBody;
