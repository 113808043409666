import React from 'react';
import { AutoComplete, Button, DatePicker, Input, Select, Skeleton, Space } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Page, PageSection } from '../../../../components';
import useNewOther from './reducers/useNewOther';

import '../delivery/styles/newOwn.scss';

const NewOther = ({ select }) => {
	const [
		{ clients, other, loading, disabled },
		{ clientChanged, nameChanged, dateChanged, timeChanged, invoiceChanged, valueChanged, valueTypeChanged, addOther },
	] = useNewOther();

	const actions = [
		<Button
			key="0"
			type="primary"
			size="large"
			icon={<PlusCircleOutlined />}
			onClick={addOther}
			disabled={disabled}
			loading={loading}
		>
			Dodaj zamówienie
		</Button>,
	];

	return (
		<Page title="Zamówienia / Nowe" actions={actions}>
			{clients ? (
				<PageSection title="Informacje" select={select}>
					<div className="new-own flex flex-dir">
						<Space size={25}>
							<div className="flex flex-dir">
								<label>Klient</label>
								<AutoComplete
									size="large"
									placeholder="Klient"
									options={clients.map((d) => ({ value: d.name }))}
									filterOption={(inputValue, option) => {
										const client = clients.filter((client) => client.name === option.value)[0];
										if (!client) return false;

										if (`${client.name}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.phone}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.nip}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.bdo}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
										if (`${client.email}`.toLowerCase().includes(inputValue.toLowerCase())) return true;
									}}
									value={other.client}
									onSelect={clientChanged}
									onChange={clientChanged}
								/>
							</div>
							<div className="flex flex-dir">
								<label>Nazwa</label>
								<Input type="text" size="large" value={other.name} onChange={nameChanged} />
							</div>
						</Space>
						<Space size={25}>
							<div className="flex flex-dir">
								<DatePicker size="large" placeholder="Wybierz datę" value={other.date} onChange={dateChanged} />
							</div>
							<div className="flex flex-dir">
								<DatePicker.TimePicker
									size="large"
									format="HH:mm"
									placeholder="Wybierz godzinę"
									value={other.time}
									onChange={timeChanged}
								/>
							</div>
						</Space>
						<Space size={25}>
							<Space size={10} className="flex">
								<div className="flex flex-dir">
									<label>Cena</label>
									<div className="flex flex-ac">
										<Input size="large" value={other.value} onChange={valueChanged} />
										<span className="pln">PLN</span>
									</div>
								</div>
								<div className="flex flex-dir">
									<label>Netto / Brutto</label>
									<Select size="large" value={other.value_type} onChange={valueTypeChanged}>
										<Option value={0}>Netto</Option>
										<Option value={1}>Brutto</Option>
									</Select>
								</div>
							</Space>
							<div className="flex flex-dir">
								<label>Faktura</label>
								<Input size="large" value={other.invocie} onSelect={invoiceChanged} onChange={invoiceChanged} />
							</div>
						</Space>
					</div>
				</PageSection>
			) : (
				<Skeleton active />
			)}
		</Page>
	);
};

export default NewOther;
