import React from 'react';
import { Button, Input, Space, Checkbox } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Page, PageSection } from '../../components';
import { useNewDriver } from '../../reducers';

import '../../styles/pages/drivers/newDriver.scss';

const NewDriver = () => {
	const [
		{ user, disabled, loading },
		{ usernameChanged, nameChanged, surnameChanged, adminChecked, superadminChecked, createUser, typeChanged },
	] = useNewDriver();

	const actions = [
		<Button
			key="1"
			type="primary"
			size="large"
			icon={<PlusCircleOutlined />}
			disabled={disabled}
			loading={loading}
			onClick={createUser}
		>
			Dodaj pracownika
		</Button>,
	];

	return (
		<Page title="Pracownicy / Nowy" actions={actions}>
			<div className="new-driver flex">
				<PageSection title="Informacje" width={878}>
					<Space size={19}>
						<div className="flex flex-dir">
							<label>Nazwa użytkownika</label>
							<Input type="text" size="large" value={user.username} onChange={usernameChanged} />
						</div>
						<div className="flex flex-dir">
							<label></label>
							<p className="info">Możesz ją zmienić w każdym momencie</p>
						</div>
					</Space>

					<Space size={19}>
						<div className="flex flex-dir">
							<label>Imię</label>
							<Input type="text" size="large" value={user.name} onChange={nameChanged} />
						</div>
						<div className="flex flex-dir">
							<label>Nazwisko</label>
							<Input type="text" size="large" value={user.surname} onChange={surnameChanged} />
						</div>
					</Space>

					<Space size={19}>
						<div className="flex flex-dir">
							<label>Stanowisko</label>
							<Input type="text" size="large" value={user.type} onChange={typeChanged} />
						</div>
						<div></div>
					</Space>

					<Space size={19}>
						<Checkbox checked={user.level >= 1} onChange={adminChecked}>
							Pracownik jest administratorem
						</Checkbox>
						<Checkbox checked={user.level === 2} onChange={superadminChecked} disabled={user.level < 1}>
							Pracownik może zmieniać uprawnienia
						</Checkbox>
					</Space>

					<p className="password">
						Tymczasowe hasło pracownika: <span>{user.password}</span>
					</p>

					<p className="info">Pracownik zostanie poproszony o zmianę hasła przy pierwszym logowaniu</p>
				</PageSection>
			</div>
		</Page>
	);
};

export default NewDriver;
