import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { Query } from '../../utils';
import API from '../../API';
import moment from 'moment';

export default function () {
	const history = useHistory();

	const { company } = Query.decode(history.location.search);

	const [expense, setExpense] = useState({ date: moment(), company });
	const [loading, setLoading] = useState(false);
	const [types, setTypes] = useState();
	const [type, setType] = useState();

	const type_id = useMemo(() => {
		if (!isNaN(expense.type_id)) {
			return expense.type_id;
		}

		return types?.find((t) => t.name === type)?.id;
	}, [expense, types, type]);

	const isFuelExpense = type_id == 5;

	const disabled = useMemo(() => {
		if (!expense.type_id && !type) {
			return true;
		}

		if (!expense.date) {
			return true;
		}

		if (!expense.value?.trim()) {
			return true;
		}

		if (isNaN(expense.value_type)) {
			return true;
		}

		if (isNaN(expense.tax)) {
			return true;
		}

		if (isFuelExpense) {
			if (!expense.car?.trim()) {
				return true;
			}

			if (!expense.mileage?.trim()) {
				return true;
			}

			if (isNaN(expense.mileage)) {
				return true;
			}

			if (!expense.fuel_liters) {
				return true;
			}

			if (isNaN(expense.fuel_liters)) {
				return true;
			}
		}

		return false;
	}, [expense, type]);

	const fetch = async () => {
		setTypes(await API.expenses.expenseTypes.getAll());
	};

	useEffect(() => {
		fetch();
	}, []);

	const createExpense = async () => {
		if (disabled) return;

		try {
			setLoading(true);

			const e = { ...expense };

			let t = types.filter((t) => t.name === type)[0];
			if (!t) {
				t = await API.expenses.expenseTypes.put(type);
			}

			e.type_id = t.id;
			e.date = e.date.unix() * 1000;

			await API.expenses.create(e);

			setLoading(false);
			message.success('Stworzono wydatek');
			history.goBack();
		} catch (e) {
			setLoading(false);
			message.error(`${e}`);

			console.trace(e);
		}
	};

	return [
		{
			expense,
			isFuelExpense,
			disabled,
			loading,
			types,
			type,
			admin: window.sessionStorage.getItem('level') > 0,
			superAdmin: window.sessionStorage.getItem('level') > 1,
		},
		{
			nameChanged: (e) => setExpense((expense) => ({ ...expense, name: e.target.value })),
			typeChanged: (value) => setExpense((expense) => ({ ...expense, type: value })),
			typeChanged: (value) => setType(value),
			carChanged: (e) => setExpense((expense) => ({ ...expense, car: e.target.value })),
			mileageChanged: (e) => setExpense((expense) => ({ ...expense, mileage: e.target.value })),
			fuelLitersChanged: (e) => setExpense((expense) => ({ ...expense, fuel_liters: e.target.value })),
			valueChanged: (e) => setExpense((expense) => ({ ...expense, value: e.target.value })),
			dateChanged: (date) => setExpense((expense) => ({ ...expense, date })),
			valueTypeChanged: (value_type) => setExpense((expense) => ({ ...expense, value_type })),
			taxChanged: (tax) => setExpense((expense) => ({ ...expense, tax })),
			invoiceChanged: (e) => setExpense((expense) => ({ ...expense, invoice: e.target.value })),
			companyChanged: (e) => setExpense((expense) => ({ ...expense, company: e.target.checked })),
			createExpense,
		},
	];
}
